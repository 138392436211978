import styled from 'styled-components';

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FileInput = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #FFF;

  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;

  svg {
    font-size: 30px;
    color: #03FBFD;
  }

  & input {
    display: block;
    position: absolute;
    inset: -100px;
    opacity: 0;
  }
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;

  svg {
    cursor: pointer;
    font-size: 20px;
    color: ${(p) => p.theme.palette.danger100};;
  }
`;
