/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PreparedRequestOptions,
  RequestResponse,
} from './types';
import { RequestError } from './RequestError';

export const request = async <T = any>(
  options: PreparedRequestOptions,
): Promise<RequestResponse<T>> => {
  const {
    url,
    ...requestParams
  } = options;

  const response = await fetch(url, requestParams);

  let data: any | null = null;

  if (response.headers && response.headers.has('content-type')) {
    const contentType = response.headers.get('content-type');

    if (contentType?.startsWith('application/json')) {
      data = await response.json();
    } else if (response.ok || !contentType?.startsWith('text/html')) {
      data = await response.text();
    }
  }

  if (!response.ok) {
    throw new RequestError(
      response.status,
      response.headers,
      data,
    );
  }

  const result: RequestResponse<T> = {
    statusCode: response.status,
    headers: response.headers,
    data,
  };

  return result;
};
