import { FC } from 'react';
import { Category } from '../../types';
import courseDefaultImage from '../../../shared/assets/images/course-default.png';
import { ItemImage, Name, Root } from './styled';

export interface CategoryCardProps {
  item: Category;
}

export const CategoryCard: FC<CategoryCardProps> = (props) => {
  const { item } = props;
  return (
    <Root background={item.css_config?.gradient || item.css_config?.color}>
      <ItemImage src={item.image || courseDefaultImage} />
      <Name>{item.name}</Name>
    </Root>
  );
};
