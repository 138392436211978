import { styled } from 'styled-components';

export const Root = styled.div`
  padding: ${(p) => p.theme.spacing.get(1, 7, 4)};
  border-radius: 3px;
  background: rgba(3, 251, 253, 0.14);
  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(2, 4, 4)};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(3)};
  margin-bottom: ${(p) => p.theme.spacing.get(4)};

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: ${(p) => p.theme.spacing.get(4)};
  }
`;

export const Avatar = styled.div<{ src?: string | null}>`
  width: ${(p) => p.theme.spacing.get(10)};
  aspect-ratio: 1;
  border-radius: 100%;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: ${(p) => p.theme.spacing.get(15)};
  }
`;

export const Username = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 44.4px;
  letter-spacing: -0.24px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 27px;
    line-height: 62.4px;
    letter-spacing: -0.405px;
  }
`;

export const Content = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 12.5px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
`;
