import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Container, FadeIn, InfoPageLayout } from '../../../shared';
import { useLoadTickets } from '../../hooks';
import {
  Collections,
  Content,
  EmptyText,
  EmptyTitle,
  Info,
  InfoMessage,
  InfoTitle,
  Title,
} from './styled';
import { TicketsCollection } from './TicketsCollection';
import { ProfileLayout as SupplierLayout } from '../../../supplier/layouts';
import { ProfileLayout as AccountLayout } from '../../../profile/layouts';

type TTypeLayout = 'supplier' | 'account'

interface LayoutProps {
  children: ReactNode;
  type?: TTypeLayout;
}

interface SupportScreenProps {
  layout?: TTypeLayout;
}

const Layout: FC<LayoutProps> = (props) => {
  const { children, type } = props;
  if (type === 'supplier') {
    return <SupplierLayout bodyBg="fill">{children}</SupplierLayout>;
  }
  if (type === 'account') {
    return <AccountLayout bodyBg="fill">{children}</AccountLayout>;
  }
  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradientCenter">
      {children}
    </InfoPageLayout>
  );
};

export const SupportScreen: FC<SupportScreenProps> = (props) => {
  const { layout } = props;

  const { result: openTickets, isLoading: openIsLoading } = useLoadTickets({
    filters: {
      status: 'open',
    },
  });
  const { result: closedTickets, isLoading: closedIsLoading } = useLoadTickets({
    filters: {
      status: 'closed',
    },
  });

  const isLoading = openIsLoading || closedIsLoading;
  const isEmpty = !isLoading && !openTickets?.data?.length && !closedTickets?.data?.length;

  return (
    <Layout type={layout}>
      <Container>
        <Title>Tickets</Title>
        {!isLoading && (
          <FadeIn>
            {isEmpty ? (
              <div>
                <EmptyTitle>YOU DO NOT HAVE ANY TICKETS YET </EmptyTitle>
                <EmptyTitle>
                  <Link to="/faq">here</Link>
                  {' '}
                  you can find the list of
                  frequently asked questions
                </EmptyTitle>
                <EmptyText>
                  If you want to create a new ticket, please, get in touch by
                  {' '}
                  <Link to="new">clicking here</Link>
                  . Please, note that you
                  have to log-in or register to ask us a question. Alternately,
                  you can write us using our website rroaded.co.uk. Please, note
                  that the answering time via the marketplace is shorter so we
                  do advise you to register with us.
                </EmptyText>
              </div>
            ) : (
              <Content>
                <Collections>
                  <TicketsCollection
                    items={openTickets?.data || []}
                    status="open"
                  />
                  <TicketsCollection
                    items={closedTickets?.data || []}
                    status="closed"
                  />
                </Collections>
                <Info>
                  <InfoTitle>
                    <Link to="/faq">here</Link>
                    {' '}
                    you can find the list of
                    frequently asked questions
                  </InfoTitle>
                  <InfoMessage>
                    If you want to create a new ticket, please, get in touch by
                    {' '}
                    <Link to="new">clicking here</Link>
                    .
                  </InfoMessage>
                </Info>
              </Content>
            )}
          </FadeIn>
        )}
      </Container>
    </Layout>
  );
};
