import { RequestOptions, apiClient } from '../../api';
import { StripeAccountInfo } from '../types';

export const loadStripeAccount = async (
  meta: Partial<RequestOptions> = {},
): Promise<StripeAccountInfo> => {
  const response = await apiClient.request({
    path: '/api/v1/payment/stripe/account/',
    withAuth: true,
    ...meta,
  });

  return response.data;
};
