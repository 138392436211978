import { FC, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthPartnerLayout } from '../../layouts';
import {
  Content, ContentCard, LinkItem, LinksWrapper,
} from './styled';
import { useAuthContext, useUserContext } from '../../containers';

export const SelectPartnerTypeScreen: FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { currentUser } = useUserContext();
  const checkedUserRef = useRef(false);

  useEffect(() => {
    if (checkedUserRef.current) {
      return;
    }
    if (currentUser) {
      if (currentUser?.is_supplier) {
        navigate('/account', { replace: true });
      } else {
        logout();
      }
    }
    checkedUserRef.current = true;
  }, [navigate, logout]);

  return (
    <AuthPartnerLayout>
      <Content>
        <ContentCard>
          Please clarify
        </ContentCard>
      </Content>
      <LinksWrapper>
        <LinkItem>
          <Link to="auth/company">
            I’m from a company/
            <br />
            organisation
          </Link>
        </LinkItem>
        <LinkItem>
          <Link to="auth/individual">
            I’m a lonely traveler
            <br />
            and work for myself
          </Link>
        </LinkItem>
      </LinksWrapper>
    </AuthPartnerLayout>
  );
};
