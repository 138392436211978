import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { BaseFormInputProps } from '../types';
import {
  SelectInputProps,
  Selectinput,
} from '../../components';
import { splitFormControllerProps } from '../utils';

export interface FormSelectInputProps extends BaseFormInputProps, Omit<SelectInputProps, 'onChange' | 'onFocus' | 'onBlur' | 'defaultValue' | 'name' | 'value'> {}

export const FormSelectInput: FC<FormSelectInputProps> = (props) => {
  const [controllerProps, inputProps] = splitFormControllerProps<Omit<SelectInputProps, 'onChange' | 'value'>>(props);
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => {
        let error: boolean | string | undefined = fieldState.invalid;
        if (error && fieldState.error?.message) {
          error = fieldState.error.message;
        }
        return (
          <Selectinput {...inputProps} {...field} error={error} />
        );
      }}
    />
  );
};
