import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11Zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm5-10h-4V6h-2v7h6v-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTime;
