/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  ChevronLeftIcon,
  FormTextInput,
  useBreakpoint,
} from '../../../../ui';
import {
  BackLink,
  FieldHint,
  FooterLink,
  FooterText,
  FormBody,
  FormFooter,
  InfoStepFormWrapper,
  InputWrapper,
  Label,
  Row,
  StepWrapper,
} from '../styled';
import { StepProps } from '../types';
import { YesNoInput } from '../YesNoInput';
import { ShapeCard } from '../../../../shared';

const schema = yup
  .object({
    agree: yup.boolean().isTrue().required(),
    supplier: yup.object({
      teaching_info: yup.string().required('').max(500, '500 symbols max'),
      need_help: yup.boolean().optional().nullable(),
    }),
  })
  .required();

export const Step2Info: FC<StepProps> = (props) => {
  const {
    type, data, loading, onSubmit, onBack,
  } = props;
  // const countries = useCountriesSelectOptions();
  const isDesktop = useBreakpoint('up', 'md');

  const showAgree = true; // type === 'individual';

  const { control, handleSubmit } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: {
      ...(data as any),
      agree: !showAgree,
    },
    resolver: yupResolver(schema),
  });

  const check = useCallback((formData) => {
    const { agree, ...rest } = formData;
    onSubmit(rest);
  }, []);

  return (
    <StepWrapper as="form" onSubmit={handleSubmit(check)} noValidate>
      <ShapeCard>
        <InfoStepFormWrapper>
          <BackLink onClick={onBack}>
            <ChevronLeftIcon />
            Back
          </BackLink>
          <FormBody>
            <Row>
              <Label>SUPPLIER DESCRIPTION. WHAT ARE YOU TEACHING ABOUT?</Label>
              <InputWrapper>
                <FormTextInput
                  color="primary"
                  size={isDesktop ? 'md' : 'sm'}
                  name="supplier.teaching_info"
                  fullWidth
                  multiline
                  control={control}
                  className="teaching_info"
                />
                <FieldHint>
                  please, tell us areas you are teaching about. EG IT, Design,
                  etc. (500 symbols max)
                </FieldHint>
              </InputWrapper>
            </Row>

            <Row>
              <Label>
                do you need help with cover, logo, illustrations, etc. design?
              </Label>
              <InputWrapper>
                <Controller
                  name="supplier.need_help"
                  control={control}
                  render={({ field }) => (
                    <YesNoInput value={field.value} onChange={field.onChange} />
                  )}
                />
              </InputWrapper>
            </Row>
          </FormBody>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={loading}
          >
            {type === 'company' ? 'Save and go next' : 'Submit'}
          </Button>
        </InfoStepFormWrapper>
      </ShapeCard>
      {showAgree && (
        <FormFooter>
          <Controller
            name="agree"
            control={control}
            render={({ field, fieldState }) => (
              <FooterText error={fieldState.invalid}>
                <span>
                  By creating a business account you agree to RightRoad Edtech
                  Limited Conditions of Content Use & Sale.
                  {' '}
                  <br />
                  {' '}
                  Please, see
                  and agree with our
                  {' '}
                  <FooterLink
                    href="https://courses.rroaded.co.uk/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </FooterLink>
                  , our Cookies Policy, our
                  {' '}
                  <FooterLink
                    href="https://courses.rroaded.co.uk/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </FooterLink>
                  {' '}
                  and
                  {' '}
                  <FooterLink
                    href="https://courses.rroaded.co.uk/for-partners"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Agreement for Partners
                  </FooterLink>
                  .
                </span>
                <label className="checkbox">
                  <div className="checkbox-text">Check</div>
                  <Checkbox checked={field.value} onChange={field.onChange} />
                </label>
              </FooterText>
            )}
          />
        </FormFooter>
      )}
    </StepWrapper>
  );
};
