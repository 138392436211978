import { RequestOptions, apiClient } from '../../api';
import { User } from '../types';

export const updateUser = async (
  data: Partial<User>,
  meta: Partial<RequestOptions> = {},
): Promise<User> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const body: any = { ...data };
  if (body.avatar && typeof body.avatar === 'object') {
    body.avatar = body.avatar.id;
  }
  const response = await apiClient.request({
    path: '/api/v1/accounts/profile/',
    method: 'PATCH',
    body,
    withAuth: true,
    ...meta,
  });

  return response.data;
};
