import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStudyContext } from '../../containers';

export const StudyStructScreen: FC = () => {
  const navigate = useNavigate();
  const { userCourse } = useStudyContext();

  useEffect(() => {
    if (!userCourse) {
      return;
    }

    if (userCourse.user_steps.length > 0) {
      const lastStep = userCourse.user_steps[userCourse.user_steps.length - 1];
      navigate(`./${lastStep.id}`, { replace: true });
      return;
    }
    navigate('/', { replace: true });
  }, [userCourse]);

  return null;
};
