import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  ChevronLeftIcon,
  FormTextInput,
  useBreakpoint,
} from '../../../../ui';
import {
  BackLink,
  FormBody,
  FormWrapper,
  InputWrapper,
  Label,
  Row,
} from '../styled';
import { StepProps } from '../types';
import { useUpdateSupplierContact } from '../../../hooks';
import { ShapeCard } from '../../../../shared';

const EMAIL_REG_EXP = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
const PHONE_REG_EXP = /^\+[1-9]\d{10,14}$/;

const schema = yup
  .object({
    title: yup.string().optional().nullable(),
    first_name: yup.string().optional().nullable(),
    last_name: yup.string().optional().nullable(),
    position: yup.string().optional().nullable(),
    phone: yup
      .string()
      .optional()
      .matches(PHONE_REG_EXP, 'Phone number is not valid'),
    email: yup.string().optional().matches(EMAIL_REG_EXP, 'Email is not valid'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const Step3Company: FC<StepProps> = (props) => {
  const { onSubmit, onBack } = props;
  const { isLoading } = useUpdateSupplierContact();
  const isDesktop = useBreakpoint('up', 'md');
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: '+',
    },
  });

  const check = useCallback((formData: FormData) => {
    schema.validate(onSubmit(formData));
  }, []);

  const handleChangeEmail = (e) => setValue('email', e.toLowerCase());

  return (
    <ShapeCard title="contact person’s detales">
      <FormWrapper as="form" onSubmit={handleSubmit(check)} noValidate>
        <BackLink onClick={onBack}>
          <ChevronLeftIcon />
          Back
        </BackLink>
        <FormBody>
          <Row>
            <Label>Title</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="title"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>First Name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="first_name"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Last name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="last_name"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Position</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="position"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Phone</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="phone"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Email</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="email"
                onChangeText={handleChangeEmail}
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </FormBody>
      </FormWrapper>
    </ShapeCard>
  );
};
