import {
  apiClient,
} from '../../api';
import { UserCourseStep } from '../types';

export const loadUserCourseStep = async (
  userCourseId: number | string,
  stepId: number | string,
): Promise<UserCourseStep> => {
  const response = await apiClient.request({
    path: `/api/v1/user-course/${userCourseId}/step/${stepId}/`,
    method: 'GET',
  });

  return response.data;
};
