import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Ticket, TicketStatus } from '../../types';
import {
  CollectionBody,
  CollectionContent,
  CollectionEmpty,
  CollectionHeader,
  CollectionItem,
  CollectionTitle,
  CollectionWrapper,
} from './styled';

interface TicketsCollectionProps {
  items: Array<Ticket>;
  status: TicketStatus;
}

export const TicketsCollection: FC<TicketsCollectionProps> = (props) => {
  const { status, items } = props;

  return (
    <CollectionWrapper>
      <CollectionBody>
        <CollectionHeader>
          <CollectionTitle>{status}</CollectionTitle>
        </CollectionHeader>
        <CollectionContent>
          {items.length === 0 && (
            <>
              <CollectionEmpty>
                You do not have any
                {' '}
                {status === 'closed' ? 'solved' : 'active'}
                {' '}
                tickets
              </CollectionEmpty>
              {status === 'open' && (
                <CollectionEmpty>
                  If you want to create a new ticket, please, get in touch by
                  {' '}
                  <Link to="new">clicking here</Link>
                  .
                </CollectionEmpty>
              )}
            </>
          )}
          {items.map((item, index) => (
            <CollectionItem key={item.id} as={Link} to={`./${item.id}`}>
              {index + 1}
              .
              {' '}
              {item.subject}
            </CollectionItem>
          ))}
        </CollectionContent>
      </CollectionBody>
    </CollectionWrapper>
  );
};
