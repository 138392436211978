import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Course } from '../../types';
import { HeartFilledIcon, ShapeButton, useBreakpoint } from '../../../ui';
import courseDefaultImage from '../../../shared/assets/images/course-default.png';
import {
  Actions, Content, Description, ItemImage, Name, Root,
} from './styled';
import { FavouriteActionWrapper } from '../FavouriteActionWrapper';
import { useAuthContext, useUserContext } from '../../../auth';
import { useStartCourse } from '../../hooks';

export interface CourseBannerProps {
  item: Course;
}

export const CourseBanner: FC<CourseBannerProps> = (props) => {
  const { item } = props;

  const { currentUser } = useUserContext();
  const { authorized } = useAuthContext();
  const navigate = useNavigate();

  const { result: startResult, request: start } = useStartCourse();

  const started = item?.is_started || Boolean(startResult) || false;

  const isDesktop = useBreakpoint('up', 'md');

  const startHandler = useCallback(() => {
    if (!authorized) {
      navigate('/auth/signin');
      return;
    }
    if (
      currentUser?.is_supplier
      && (!currentUser?.supplier_info?.is_verified
        || (currentUser?.supplier_info?.is_stripe_enabled
          && !currentUser?.supplier_info?.is_stripe_verified))
    ) {
      navigate('/account/verify');
      return;
    }
    if (item?.id) {
      if (item?.is_paid) {
        navigate(`/courses/${item.id}`);
        return;
      }
      if (started) {
        navigate(`/courses/${item.id}/study`);
        return;
      }
      start({
        id: item.id,
      }).then(() => {
        navigate(`/courses/${item.id}/study`);
      });
    }
  }, [item, started, navigate, authorized, currentUser]);

  return (
    <Root>
      <Content>
        <Name>{item.name}</Name>
        <Description>{item.description}</Description>
        <Actions>
          <ShapeButton
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={startHandler}
            glow
            color="gradient"
            size={isDesktop ? 'lg' : 'xs'}
          >
            {item.price_gbp
            && item.price_gbp !== '0.00'
            && item.price_with_commission
              ? `Play £${item.price_with_commission}`
              : 'Play now'}
          </ShapeButton>
          <FavouriteActionWrapper item={item}>
            {(toggle) => (
              <ShapeButton
                size={isDesktop ? 'lg' : 'xs'}
                onClick={toggle}
                icon={<HeartFilledIcon isSaved={item?.is_favorite} />}
              >
                {item.is_favorite ? 'Saved' : 'Save'}
              </ShapeButton>
            )}
          </FavouriteActionWrapper>
        </Actions>
      </Content>
      <ItemImage src={item.image || courseDefaultImage} />
    </Root>
  );
};
