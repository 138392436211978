import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthLayout } from '../../layouts';
import { useSendResetPasswordLink } from '../../hooks';
import { Button, FormTextInput } from '../../../ui';
import { Content, ContentTop, Title } from './styled';

const schema = yup
  .object({
    email: yup.string().required('Required'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const ForgotPasswordScreen: FC = () => {
  const { isLoading, request } = useSendResetPasswordLink();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data);
    },
    [request],
  );

  const handleChangeEmail = (e) => setValue('email', e.toLowerCase());

  return (
    <AuthLayout>
      <Content as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <ContentTop>
          <Title>Reset password</Title>
          <FormTextInput
            name="email"
            label="Email"
            placeholder="Enter email"
            type="email"
            onChangeText={handleChangeEmail}
            fullWidth
            control={control}
          />
        </ContentTop>
        <Button size="lg" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </Content>
    </AuthLayout>
  );
};
