import {
  FC, useCallback, useEffect, useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import { Root } from './styled';
import {
  Button,
  EyeIcon,
  EyeNoIcon,
  FormTextInput,
  useBreakpoint,
} from '../../../ui';
import { useChangePassword } from '../../hooks';
import {
  InputWrapper,
  Label,
  Row,
} from '../SupplierSettingsAddressScreen/styled';

const schema = yup
  .object({
    old_password: yup.string().required(''),
    password: yup
      .string()
      .min(8, 'Min 8 symbols')
      .matches(
        /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#${}~`\][()|><^/\\.,-_+=:;'"!%*?&])/,
        'At least one uppercase letter, one lowercase letter, one number and one special character',
      )
      .required(''),
    confirm_password: yup.string().required('').oneOf([yup.ref('password')], 'Your passwords do not match.'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const SupplierSettingsPasswordScreen: FC = () => {
  const isDesktop = useBreakpoint('up', 'md');
  const { result, isLoading, request } = useChangePassword();
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((x) => !x);
  }, []);

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data);
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      toast('Password successfully changed', { type: 'success' });
      resetForm();
    }
  }, [result, resetForm]);

  const ToggleIcon = showPassword ? EyeNoIcon : EyeIcon;

  return (
    <ProfileSettingsLayout>
      <ShapeCard>
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Label>Old password</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="old_password"
                type={showPassword ? 'string' : 'password'}
                fullWidth
                control={control}
                endIcon={<ToggleIcon onClick={toggleShowPassword} />}
              />
            </InputWrapper>
          </Row>

          <Row>
            <Label>New password</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="password"
                type={showPassword ? 'string' : 'password'}
                fullWidth
                control={control}
                endIcon={<ToggleIcon onClick={toggleShowPassword} />}
                hint={(
                  <pre>
                    - min 8 symbols
                    <br />
                    - one uppercase letter
                    <br />
                    - one lowercase letter
                    <br />
                    - one number
                    <br />
                    - one special character
                    <br />
                    - should not look like an email
                  </pre>
                )}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Confirm password</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="confirm_password"
                type={showPassword ? 'string' : 'password'}
                fullWidth
                control={control}
                endIcon={<ToggleIcon onClick={toggleShowPassword} />}
              />
            </InputWrapper>
          </Row>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save changes
          </Button>
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
