import styled, { css } from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  ${(p) => p.theme.breakpoints.down('mobile')} {
    flex-direction: row;
  }
  ${(p) => p.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const Item = styled.div<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  min-height: 38px;
  padding: 0 4px;
  border-radius: 3px;
  border: 1px solid #03FBFD;
  
  color: #FFF;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-transform: uppercase;
  
  background: transparent;

  transition: background-color 0.3s ease-in-out;;

  cursor: pointer;

  ${(p) => p.theme.breakpoints.down('md')} {
    min-width: 100px;
    min-height: 30px;
  }
  
  ${({ checked }) => checked && css`
    background: rgba(3, 251, 253, 0.22);
  `}
 
`;
