import styled from 'styled-components';

export const HeaderRow = styled.div`
  margin-bottom: 20px;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.div`
  margin-bottom: 36px;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 130% */
  letter-spacing: -0.72px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFF 18.58%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 32px;
  }
`;

export const EmptyTitle = styled.div`
  color: #FFF;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  margin-bottom: 16px;

  & + & {
    margin-top: 36px;
  }

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 18px;
  }
`;

export const EmptyText = styled.div`
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 12px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Info = styled.div`
  width: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 40%;
  }
`;

export const InfoTitle = styled.div`
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  margin-bottom: 8px;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const InfoMessage = styled.div`
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Collections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 55%;
  }
`;

export const CollectionWrapper = styled.div`
  border-radius: 3px;
  border: 3px solid #03FBFD;
  padding: 20px 20px 30px;
`;

export const CollectionBody = styled.div`
  border-radius: 3px;
  border: 1px solid #03FBFD;
`;

export const CollectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #03FBFD;
`;

export const CollectionTitle = styled.div`
  padding: 0 25px;
  border-right: 1px solid #03FBFD;
  color: #FFF;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 156% */
  letter-spacing: -0.6px;
  text-transform: uppercase;
`;

export const CollectionContent = styled.div`
  padding: 20px 30px;
`;

export const CollectionItem = styled.div`
  color: #FFF;

  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  cursor: pointer;

  text-decoration: none;
`;

export const CollectionEmpty = styled.div`
  color: #FFF;

  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  
  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
`;
