import { css, styled } from 'styled-components';
import { Link } from 'react-router-dom';

export const Root = styled.div`
  width: 100%;
`;

export const MainScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.get(4)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${(p) => p.theme.spacing.get(10)};
  overflow-x: hidden;
  

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: ${(p) => p.theme.spacing.get(25)};
  }
`;

export const SubContainer = styled.div<{ paddings?: boolean }>`
  /* ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(0, 8)};
  } */
  ${({ paddings, theme }) => paddings && css`
    padding: ${theme.spacing.get(0, 8)};
  `}
`;

export const FeaturesWrapper = styled.div`
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
`;

export const SliderWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(4)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const TabsWrapper = styled.div`
  display: none;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const Filters = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(10)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const ItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.get(10)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const Items = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill);
  grid-row-gap: ${(p) => p.theme.spacing.get(4)};
  grid-column-gap: ${(p) => p.theme.spacing.get(4)};
  padding-bottom: 40px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: ${(p) => p.theme.spacing.get(7)};
    grid-column-gap: ${(p) => p.theme.spacing.get(6)};
  }
`;

export const Item = styled(Link)`
  text-decoration: none;
  position: relative;

  /* ${(p) => p.theme.breakpoints.up('md')} {
    width: 400px;
    height: 500px;
  } */
`;

export const Callout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(p) => p.theme.spacing.get(6)};

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing.get(10, 6)};
  }
`;

export const FooterWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.get(10)};
`;

export const List = styled.ul`
  margin: 0;
  padding: 0; 
  list-style: none;
`;

export const ListItem = styled.li`
  margin: 10px 0 0;
  padding: 0;
`;
