/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect } from 'react';
import { useStudyPreviewContext } from '../../containers';
import {
  ArrowSvg,
  OpenBtn,
  SectionBlock,
  SectionIndex,
  SectionMenuWrapper,
  SectionName,
  SectionStep,
  SectionStepBlock,
  SectionStepsBlock,
  SectionContainer,
  LinkStepPreview,
} from './styled';
import { CourseSection } from '../../../catalog';

interface SectionMenuProps {
  courseId?: number | string;
  sections: Array<CourseSection> | undefined;
  activeSection: CourseSection | null;
  activeSectionStep: number | null;
}

export const SectionPreviewMenu: FC<SectionMenuProps> = (props) => {
  const {
    courseId, sections, activeSectionStep, activeSection,
  } = props;
  const { stepsOpen, handleOpen, handleOpenActiveSection } = useStudyPreviewContext();

  useEffect(() => {
    if (activeSection?.id) {
      handleOpenActiveSection(activeSection?.id);
    }
  }, [activeSection?.id]);

  return (
    <SectionMenuWrapper>
      {sections?.map((item, index) => {
        return (
          <SectionContainer key={item.id}>
            <SectionBlock isActive={activeSection?.id === item.id}>
              <SectionIndex isActive={activeSection?.id === item.id}>
                {index + 1}
              </SectionIndex>
              <SectionStepBlock onClick={() => handleOpen(item.id)}>
                <SectionName>{item.name}</SectionName>
                <OpenBtn variant="outlined">
                  <ArrowSvg isOpen={stepsOpen === item.id} />
                </OpenBtn>
              </SectionStepBlock>
            </SectionBlock>
            {stepsOpen === item.id
              && (item.steps?.length > 0 ? (
                <SectionStepsBlock isOpen={stepsOpen === item.id}>
                  {item.steps.map((step) => {
                    const link = `/courses/${courseId}/preview/${step.id}`;

                    if (step.type === 'results') {
                      return null;
                    }
                    return (
                      <LinkStepPreview to={link} key={step.id}>
                        <SectionStep isActive={activeSectionStep === step.id}>
                          {step.name}
                        </SectionStep>
                      </LinkStepPreview>
                    );
                  })}
                </SectionStepsBlock>
              ) : null)}
          </SectionContainer>
        );
      })}
    </SectionMenuWrapper>
  );
};
