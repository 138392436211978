import { FC } from 'react';
import { styled } from 'styled-components';
import { Line } from './Line';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: 25px;
  width: 100%;
  overflow: hidden;
  color: #03FBFD;
  filter: drop-shadow(0px 0px 7px #57FEFF);

  svg {
    height: 100%;
    width: auto;
    flex-shrink: 0;
  }
`;

export const PageDivider: FC = () => {
  return (
    <Root>
      <Line />
    </Root>
  );
};
