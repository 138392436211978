import { css, styled } from 'styled-components';
import { ButtonSize, ButtonVariant } from './types';

export interface RootProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
}

export const Root = styled.button<RootProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  text-transform: uppercase;
  text-decoration: none;
  opacity: 1;
  color: #fff;
  background: transparent;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  ${({ size = 'sm' }) => {
    if (size === 'md') {
      return css`
        width: 137px;
        height: 32px;
        font-size: 12px;
      `;
    }
    if (size === 'lg') {
      return css`
        width: 192px;
        height: 44px;
        font-size: 16px;
      `;
    }
    return css`
      width: 105px;
      height: 24px;
      font-size: 11px;
    `;
  }}
  
  ${({ variant = 'contained' }) => {
    if (variant === 'outlined') {
      return css`
        border-color: #9747FF;
        color: #9747FF;
      `;
    }
    return css`
      /* background-color: #9747FF; */
      border: none;
      background: linear-gradient(180deg, #6F00FF 0%, #FFA665 100%);
      color: #FFFFFF;
    `;
  }}

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`;
