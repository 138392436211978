import {
  FC, MouseEvent, ReactNode, useCallback, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Course } from '../../types';
import { toggleFavorite } from '../../network';
import { useAuthContext } from '../../../auth';

export interface FavouriteActionWrapperProps {
  item: Course;
  children: (toggle: (event?: MouseEvent<HTMLElement>) => void) => ReactNode;
}

export const FavouriteActionWrapper: FC<FavouriteActionWrapperProps> = (props) => {
  const { item, children } = props;
  const navigate = useNavigate();
  const { authorized } = useAuthContext();
  const [, setVersion] = useState(0);

  const toggle = useCallback((event?: MouseEvent<HTMLElement>) => {
    if (event) {
      try {
        event.stopPropagation();
        event.preventDefault();
      } catch (e) {
        // pass
      }
    }
    if (!authorized) {
      navigate('/auth');
      return;
    }
    item.is_favorite = !item.is_favorite;
    setVersion((x) => x + 1);
    toggleFavorite({
      id: item.id,
      is_favorite: item.is_favorite,
    });
  }, [item, authorized]);

  return (
    <>
      {children(toggle)}
    </>
  );
};
