import {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import {
  CountryLabel, InputWrapper, Label, Root, Row,
} from './styled';
import { Button, FormTextInput, useBreakpoint } from '../../../ui';
import {
  useCountriesSelectOptions,
  useUpdateSupplier,
  useUserContext,
} from '../../../auth';

const schema = yup
  .object({
    city: yup.string().required('Required'),
    line1: yup.string().required('Required'),
    line2: yup.string().required('Required'),
    flat_number: yup.string().optional().nullable(),
    zipcode: yup.string().required('Required'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const SupplierSettingsAddressScreen: FC = () => {
  const { currentUser, refresh } = useUserContext();
  const { result, isLoading, request } = useUpdateSupplier();
  const countries = useCountriesSelectOptions();
  const isDesktop = useBreakpoint('up', 'md');
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data);
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      refresh();
      toast('Information successfully updated', { type: 'success' });
    }
  }, [result]);

  useEffect(() => {
    if (currentUser?.supplier_info) {
      setValue('city', currentUser.supplier_info.city || '');
      setValue('line2', currentUser.supplier_info.line2 || '');
      setValue('line1', currentUser.supplier_info.line1 || '');
      setValue('zipcode', currentUser.supplier_info.zipcode || '');
    }
  }, [currentUser]);

  const { label } = useMemo(() => {
    const currentCountry = countries.find(
      (item) => item.value === currentUser?.supplier_info?.country,
    );
    if (currentCountry) {
      return currentCountry;
    }
    return { label: '', value: '' };
  }, [countries, currentUser?.supplier_info?.country]);

  return (
    <ProfileSettingsLayout>
      <ShapeCard>
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Label>Postcode / zipcode</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="zipcode"
                hint="enter the postcode and the form will fill automatically"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>house name/ number</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="line2"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>street name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="line1"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>city/town</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="city"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>country</Label>
            <InputWrapper>
              <CountryLabel>{label}</CountryLabel>
            </InputWrapper>
          </Row>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save changes
          </Button>
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
