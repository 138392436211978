/* eslint-disable */

import { config } from '../../../../config';

let inited = false;

export const initFB = (onReady) => {
  if (inited) {
    try {
      window.fbAsyncInit();
      if (onReady) {
        onReady(true);
      }
    } catch (e) {}
    return;
  }
  inited = true;

  // This is called with the results from from FB.getLoginStatus().
  function statusChangeCallback(response) {
    if (
      response?.authResponse?.accessToken &&
      window.handleFacebookAuthResponse
    ) {
      window.handleFacebookAuthResponse(response);
    }
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      testAPI();
    } else {
      // The person is not logged into your app or we are unable to tell.
      // document.getElementById('status').innerHTML =
      //   'Please log ' + 'into this app.';
    }
  }

  // This function is called when someone finishes with the Login
  // Button.  See the onlogin handler attached to it in the sample
  // code below.
  window.checkLoginState = () => {
    FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  };

  window.fbAsyncInit = function () {
    FB.init({
      appId: config.facebookAppId,
      cookie: true, // enable cookies to allow the server to access
      // the session
      xfbml: true, // parse social plugins on this page
      version: 'v18.0', // Specify the Graph API version to use
    });

    // FB.getLoginStatus(function (response) {
    //   statusChangeCallback(response);
    // });

    if (onReady) {
      onReady(true);
    }
  };

  // Load the SDK asynchronously
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    // fjs.parentNode.insertBefore(js, fjs);
    document.body.append(js);
  })(document, 'script', 'facebook-jssdk');

  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  function testAPI() {
    // console.log('Welcome!  Fetching your information.... ');
    // FB.api('/me', function (response) {
    //   console.log('Successful login for: ' + response.name);
    //   // document.getElementById('status').innerHTML =
    //   //   'Thanks for logging in, ' + response.name + '!';
    // });
  }
};
