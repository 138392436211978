import { RequestOptions, apiClient } from '../../api';

export interface StartCourseData {
  id: number;
}

export const startCourse = async (
  data: StartCourseData,
  meta: Partial<RequestOptions> = {},
): Promise<object> => {
  const {
    id,
  } = data;

  const response = await apiClient.request({
    path: `/api/v1/course/${id}/start/`,
    method: 'POST',
    withAuth: true,
    ...meta,
  });

  return response.data || {};
};
