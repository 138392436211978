import {
  ChangeEvent, FC,
} from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import {
  CloseIcon, LinkIcon,
} from '../../../ui';
import {
  FileInput,
  FileItem,
  FileWrapper,
} from './styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface TicketAttachmentInputProps extends Omit<ControllerProps<any>, 'render'> {}

export const TicketAttachmentInput: FC<TicketAttachmentInputProps> = (props) => {
  return (
    <Controller
      {...props}
      render={({ field }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          if (event.currentTarget.files && event.currentTarget.files?.length > 0) {
            field.onChange([
              ...(field.value || []),
              ...Array.from(event.currentTarget.files),
            ]);
          }
        };

        const deleteFile = (file: File) => {
          field.onChange(field.value?.filter((x) => x !== file) || []);
        };

        return (
          <FileWrapper>
            <FileInput>
              <LinkIcon />
              atach files
              <input type="file" multiple onChange={onChange} />
            </FileInput>
            {field.value?.map((x: File) => (
              <FileItem key={x.name}>
                <CloseIcon onClick={() => deleteFile(x)} />
                {x.name}
              </FileItem>
            ))}
          </FileWrapper>
        );
      }}
    />
  );
};
