import { styled } from 'styled-components';
import bg from '../../../shared/assets/images/background-grid.jpg';
import cardBgMobile from '../../../shared/assets/images/form-card-mobile.png';
import cardBgDesktop from '../../../shared/assets/images/form-card-desk.png';
import bottomLinesMobile from '../../../shared/assets/images/bottom-lines-mobile.png';
import bottomLinesDesktop from '../../../shared/assets/images/bottom-lines-desk.png';
import { Container } from '../../../shared';

export const Root = styled.div`
  width: 100%;
`;

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainScreen = styled(Container)`
  position: relative;
  width: 100%;
  max-width: none;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('${bg}');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 383px;
  height: 569px;
  padding: 60px 58px 86px 48px;
  background-image: url(${cardBgMobile});
  background-size: cover;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 659px;
    height: 445px;
    padding: 52px 72px 60px 80px;
    background-image: url(${cardBgDesktop});
  }
`;

export const BottomLines = styled.div`
  position: absolute;
  z-index: 0;
  inset: auto 0 20px;
  height: 40px;
  background-image: url(${bottomLinesMobile});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 60px;
    background-image: url(${bottomLinesDesktop});
  }
`;

export const FooterWrapper = styled.div`
`;
