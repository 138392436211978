import {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  ChevronLeftIcon,
  EyeIcon,
  EyeNoIcon,
  FormTextInput,
  useBreakpoint,
} from '../../../ui';
import {
  BackButton,
  Content,
  ContentTop,
  FieldsWrapper,
  InputWrapper,
  Label,
  Row,
  Title,
} from './styled';
import { StepProps } from './types';
import { SignupData } from '../../network';
import { useSignup } from '../../hooks';
import { RequestError } from '../../../api';

const schema = yup
  .object({
    email: yup.string().email('Invalid email').required('Required'),
    password: yup
      .string()
      .min(8, '')
      .matches(
        /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#${}~`\][()|><^/\\.,-_+=:;'"!%*?&])/,
        '',
      )
      .notOneOf([yup.ref('email')], '')
      .required(''),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Required'),
  })
  .required();

export const SignupStep2: FC<StepProps> = (props) => {
  const { data, onSubmit, onBack } = props;
  const isDesktop = useBreakpoint('up', 'md');
  const submitedDataRef = useRef<Partial<SignupData> | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const {
    result, isLoading, error, request,
  } = useSignup();

  const toggleShowPassword = useCallback(() => {
    setShowPassword((x) => !x);
  }, []);

  const {
    control, handleSubmit, setError, setValue,
  } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: data as any,
    resolver: yupResolver(schema),
  });

  const check = useCallback(
    (formData: Partial<SignupData>) => {
      submitedDataRef.current = formData;
      // Так как для успешной авторизации требуются еще другие поля,
      // Мы можем использовать этот запрос для валидации полей
      request(
        {
          email: formData.email,
          password: formData.password,
        } as SignupData,
        {
          silent: true,
        },
      );
    },
    [request],
  );

  useEffect(() => {
    if (
      error
      && error instanceof RequestError
      && error.statusCode === 400
      && error.data
    ) {
      let ok = true;
      if (error.data?.email && error.data?.email.length > 0) {
        ok = false;
        setError('email', {
          message: error.data.email[0],
        });
      }
      if (error.data?.password && error.data?.password.length > 0) {
        ok = false;
        setError('password', {
          message: error.data.password[0],
        });
      }
      if (!ok) {
        submitedDataRef.current = null;
        return;
      }
    }
    if (submitedDataRef.current && (error || result)) {
      onSubmit(submitedDataRef.current);
    }
  }, [result, error]);

  const ToggleIcon = showPassword ? EyeNoIcon : EyeIcon;

  const handleChangeEmail = (e) => setValue('email', e.toLowerCase());

  return (
    <Content as="form" onSubmit={handleSubmit(check)} noValidate>
      <ContentTop>
        <Title>
          <BackButton onClick={onBack}>
            <ChevronLeftIcon />
          </BackButton>
        </Title>
        {/* <Description>Your real name will NOT be visible for other users</Description> */}
      </ContentTop>
      <FieldsWrapper>
        <Row>
          <Label>Email</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="email"
              onChangeText={handleChangeEmail}
              fullWidth
              control={control}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Label>Password</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="password"
              type={showPassword ? 'string' : 'password'}
              endIcon={<ToggleIcon onClick={toggleShowPassword} />}
              fullWidth
              control={control}
              hint={(
                <div>
                  - min 8 symbols
                  <br />
                  - one uppercase letter
                  <br />
                  - one lowercase letter
                  <br />
                  - one number
                  <br />
                  - one special character
                  <br />
                  - should not look like an email
                </div>
              )}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Label>Confirm password</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="confirm_password"
              type={showPassword ? 'string' : 'password'}
              endIcon={<ToggleIcon onClick={toggleShowPassword} />}
              fullWidth
              control={control}
            />
          </InputWrapper>
        </Row>
      </FieldsWrapper>
      <Button size={isDesktop ? 'lg' : 'md'} type="submit" disabled={isLoading}>
        Save and go next
      </Button>
    </Content>
  );
};
