import * as React from 'react';
import type { SVGProps } from 'react';

const SvgJunior = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path fill="currentColor" d="M1 21v-2h6v2H1Z" />
      <path
        fill="#C2C2C2"
        d="M9 21v-2h6v2H9ZM9 17v-2h6v2H9ZM9 13v-2h6v2H9ZM17 21v-2h6v2h-6ZM17 17v-2h6v2h-6ZM17 13v-2h6v2h-6ZM17 9V7h6v2h-6ZM17 5V3h6v2h-6Z"
      />
    </g>
  </svg>
);
export default SvgJunior;
