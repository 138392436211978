import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Course } from '../types';

export interface LoadCoursesFilters {
  categories?: number | string | Array<number | string>;
  grade?: string;
  is_promoted?: boolean;
  q?: string;
  duration__gte?: string | number;
  duration__lte?: string | number;
  price?:string|number
}

export interface LoadCoursesData extends RequestListParams<LoadCoursesFilters> {}

export const loadCourses = async (
  data: LoadCoursesData = {},
): Promise<RequestPagingResponse<Course>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/course/',
    method: 'GET',
    params,
  });

  return apiResponsePagingMapper<Course>(response.data);
};
