import { css, styled } from 'styled-components';

export const Root = styled.div<{ dark?: boolean }>`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};

  & .rselect__input {
    padding-left: 8px !important;
  }

  & .rselect__control--is-focused,
  & .rselect__control--is-focused:hover {
    outline-color: #03FBFD;
    outline-width: 2px;
    border-color: #03FBFD;
    box-shadow: none;
  }

  & .rselect__option--is-focused {
    background-color: #03FBFD;
    color: #000;
  }

  & .rselect__option--is-selected {
    background-color: #03FBFD;
    color: #000;
  }

  & .rselect__option:not(:last-child) {
    border-bottom: 0.5px solid #03FBFD;
  }

  ${({ dark }) => dark && css`
    border-radius: 3px;
    background: #000;
    border-top: 1px solid #03FBFD;

    & input::placeholder {
      color: #03FBFD;
    }

    & * {
      box-shadow: none !important;
    }
  `}
`;
