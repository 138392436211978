import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStudyPreviewContext } from '../../containers';

export const StudyStructPreviewScreen: FC = () => {
  const navigate = useNavigate();
  const { course } = useStudyPreviewContext();

  useEffect(() => {
    if (!course) {
      return;
    }

    if (
      course?.sections
      && course?.sections?.length > 0
      && course?.sections[0]?.steps?.length > 0
      && course?.sections[0]?.steps[0]?.id
    ) {
      const firstStep = course?.sections[0].steps[0].id;
      navigate(`./${firstStep}`, { replace: true });
      return;
    }
    navigate('/', { replace: true });
  }, [course]);

  return null;
};
