import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 51px;
  ${(p) => p.theme.breakpoints.down('sm')} {
  flex-direction: column;
    gap:20px;
  }
`;

export const HeaderScreen = styled.div`
  max-width: 945px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 50px;
  margin: 27px 0 118px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    margin: 20px 0;
    max-width: none;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const HeaderTitle = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 83px;
  font-weight: 400;
  line-height: 62.4px;
  letter-spacing: -0.015em;
  text-align: left;
  background: -webkit-linear-gradient(270deg, #FFFFFF 40.13%, rgba(255, 255, 255, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 41px;
  }
`;

export const HeaderSubtitle = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;
