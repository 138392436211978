import styled from 'styled-components';
import { Container } from '../../../shared';
import bottomLinesMobile from '../../../shared/assets/images/bottom-lines-mobile.png';
import bottomLinesDesktop from '../../../shared/assets/images/bottom-lines-desk.png';

export const Navigation = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const TabsWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('lg')} {
    margin-top: -60px;
  }
`;

export const BottomLines = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  height: 40px;
  background-image: url(${bottomLinesMobile});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 60px;
    margin-top: 60px;
    background-image: url(${bottomLinesDesktop});
  }
`;

export const WarningItem = styled.div`
  display: flex;
  text-transform: none;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;

  img {
    width: 100px;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    position: absolute;
    top: -20px;
    right: -10px;

    img {
      width: 225px;
    }
  }
`;
