import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Avatar } from '../types';

export interface LoadAvatarsFilters {}

export interface LoadAvatarsData
  extends RequestListParams<LoadAvatarsFilters> {}

export const loadAvatars = async (
  data: LoadAvatarsData = {},
): Promise<RequestPagingResponse<Avatar>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/accounts/avatar/',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Avatar>(response.data);
};
