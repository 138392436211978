import { FC, useMemo } from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Skill } from '../../types';
import { ChartWrapper, Total } from './styled';

ChartJS.register(ArcElement);

export interface SkillsDonutChartProps {
  items: Array<Skill>;
}

export const SkillsDonutChart: FC<SkillsDonutChartProps> = (props) => {
  const { items } = props;

  const [chartData, totalHours] = useMemo(() => {
    if (items) {
      let total = 0;
      const labels: string[] = [];
      const data: number[] = [];
      const backgroundColor: string[] = [];

      items.forEach((item) => {
        labels.push(item.name);
        data.push(parseFloat(item.time_spent));
        backgroundColor.push(item.css_config?.color || '#BDFEFF');
        if (item.time_spent) {
          const spent = parseFloat(item.time_spent);
          if (spent > 0) {
            total += spent / 60 / 60;
          }
        }
      });

      if (total > 0) {
        return [
          {
            labels,
            datasets: [
              {
                data,
                backgroundColor,
                borderWidth: 0,
              },
            ],
          },
          Math.round(total * 100) / 100,
        ];
      }
    }

    return [{
      labels: [],
      datasets: [
        {
          data: [1],
          backgroundColor: ['#03FBFD'],
          borderWidth: 0,
        },
      ],
    }, 0];
  }, [items]);

  return (
    <ChartWrapper>
      <Total>
        {`${totalHours} hours`}
        <br />
        total
      </Total>
      {items && (
        <Doughnut
          data={chartData}
          options={{
            cutout: '70%',
            devicePixelRatio: 10,
            animation: {
              duration: 2000,
              delay: 2000,
            },
          }}
        />
      )}
    </ChartWrapper>
  );
};
