import { FC } from 'react';
import { Link } from 'react-router-dom';
import { LogoWrapper, Root } from './styled';
import { UserMenu } from '../UserMenu';
import { Logo } from '../Logo';
import { GlitchWrapper } from '../../../ui';

export interface HeaderProps {
  position?: 'static' | 'absolute' | 'sticky';
  logo?: boolean;
  userMenu?: boolean;
  background?: boolean;
}

export const Header: FC<HeaderProps> = (props) => {
  const {
    position = 'static',
    logo = true,
    userMenu = true,
    background = false,
  } = props;
  return (
    <Root position={position} background={background}>
      <div>
        {logo && (
          <GlitchWrapper playMode="hover">
            <LogoWrapper as={Link} to="/">
              <Logo />
            </LogoWrapper>
          </GlitchWrapper>
        )}
      </div>
      <div>{userMenu && <UserMenu />}</div>
    </Root>
  );
};
