import {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthPartnerLayout } from '../../layouts';
import { PartnerSignupData } from './types';
import { useSignupSupplier, useUpdateSupplierContact } from '../../hooks';
import { SignupData } from '../../network';
import { Step1Company } from './steps/Step1Company';
import { Step1Individual } from './steps/Step1Individual';
import { useAuthContext, useUserContext } from '../../containers';
import { Step2Info } from './steps/Step2Info';
import { Step3Company } from './steps/Step3Company';

const MAX_STEPS_INDIVIDUAL = 1;
const MAX_STEPS_COMPANY = 2;

export const SignupPartnerScreen: FC = () => {
  const { type = 'company' } = useParams();
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { isLoading, request } = useSignupSupplier();
  const { request: requestSupplierContact } = useUpdateSupplierContact();
  const { currentUser } = useUserContext();

  const checkedUserRef = useRef(false);

  const [data, setData] = useState<Partial<PartnerSignupData>>({});
  const [step, setStep] = useState(0);

  const handleNext = useCallback(
    (newData: Partial<PartnerSignupData>) => {
      if (type === 'company') {
        if (step <= MAX_STEPS_COMPANY - 1) {
          setData(newData);
          setStep(step + 1);
        } else if (step === MAX_STEPS_COMPANY) {
          if (data.supplier?.country === 'Other') {
            data.supplier.country = null;
          }

          request(
            {
              is_supplier: true,
              first_name: data.username,
              ...data,
            } as SignupData,
            {
              type, // : type === 'individual' ? 'self_employed' : 'organization',
              ...data.supplier,
            },
          ).then(() => {
            requestSupplierContact(newData);
          });
        }
      }

      if (type === 'individual') {
        if (step < MAX_STEPS_INDIVIDUAL) {
          setData(newData);
          setStep(step + 1);
        } else {
          if (newData?.supplier?.country === 'Other') {
            // eslint-disable-next-line no-param-reassign
            newData.supplier.country = null;
          }
          request(
            {
              is_supplier: true,
              first_name: newData.username,
              ...newData,
            } as SignupData,
            {
              type, // : type === 'individual' ? 'self_employed' : 'organization',
              ...newData.supplier,
            },
          );
        }
      }
    },
    [step, type, data],
  );

  const handleBack = useCallback(() => {
    setStep((x) => (x > 0 ? x - 1 : 0));
  }, []);

  useEffect(() => {
    if (checkedUserRef.current) {
      return;
    }
    if (currentUser) {
      if (currentUser?.is_supplier) {
        navigate('/account', { replace: true });
      } else {
        logout();
      }
    }
    checkedUserRef.current = true;
  }, [navigate, logout]);

  const loading = isLoading;

  return (
    <AuthPartnerLayout>
      {type === 'company' && step === 0 && (
        <Step1Company data={data} onSubmit={handleNext} />
      )}
      {type === 'individual' && step === 0 && (
        <Step1Individual data={data} onSubmit={handleNext} />
      )}
      {step === 1 && (
        <Step2Info
          type={type}
          data={data}
          loading={loading}
          onSubmit={handleNext}
          onBack={handleBack}
        />
      )}
      {type === 'company' && step === 2 && (
        <Step3Company
          type={type}
          data={data}
          loading={loading}
          onSubmit={handleNext}
          onBack={handleBack}
        />
      )}
    </AuthPartnerLayout>
  );
};
