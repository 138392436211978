import {
  apiClient,
} from '../../api';
import { Course } from '../../catalog';

export const loadPreviewCourse = async (
  id: number|string,
): Promise<Course> => {
  const response = await apiClient.request({
    path: `/api/v1/course/${id}/preview`,
    method: 'GET',
  });

  return response.data;
};
