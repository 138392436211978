import {
  FC, useCallback, useEffect, useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import {
  Button, FormTextInput, Stack, useBreakpoint,
} from '../../../ui';
import {
  Content,
  ContentTop,
  FieldsWrapper,
  InputWrapper,
  Label,
  Row,
  Title,
} from './styled';
import { StepProps } from './types';
import { useSignup } from '../../hooks';
import { SignupData } from '../../network';
import { RequestError } from '../../../api';
import { FacebookAuthButtton } from '../../components';
import { GoogleAuthButtonSDK } from '../../components/GoogleAuthButtonSDK';

const schema = yup
  .object({
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
    username: yup.string().required('Required'),
  })
  .required();

export const SignupStep1: FC<StepProps> = (props) => {
  const { data, onSubmit } = props;
  const isDesktop = useBreakpoint('up', 'md');

  const {
    result, isLoading, error, request,
  } = useSignup();
  const submitedDataRef = useRef<Partial<SignupData> | null>(null);

  const { control, handleSubmit, setError } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: data as any,
    resolver: yupResolver(schema),
  });

  const check = useCallback(
    (formData: Partial<SignupData>) => {
      submitedDataRef.current = formData;
      // Так как для успешной авторизации требуются еще другие поля,
      // Мы можем использовать этот запрос для валидации полей
      request(
        {
          username: formData.username,
        } as SignupData,
        {
          silent: true,
        },
      );
    },
    [request],
  );

  useEffect(() => {
    if (
      error
      && error instanceof RequestError
      && error.statusCode === 400
      && error.data
    ) {
      let ok = true;
      if (error.data?.username && error.data?.username.length > 0) {
        ok = false;
        setError('username', {
          message: error.data.username[0],
        });
      }
      if (!ok) {
        submitedDataRef.current = null;
        return;
      }
    }
    if (submitedDataRef.current && (error || result)) {
      onSubmit(submitedDataRef.current);
    }
  }, [result, error]);

  return (
    <Content as="form" onSubmit={handleSubmit(check)} noValidate>
      <ContentTop>
        <Title>
          Create account
          <span>|</span>
          <Link to="../signin">Login</Link>
        </Title>
      </ContentTop>
      <FieldsWrapper>
        <Row>
          <Label>First name</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="first_name"
              fullWidth
              control={control}
              hint={(
                <span>
                  your real name will
                  {' '}
                  <b>not</b>
                  {' '}
                  be visible for other users
                </span>
              )}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Label>Last name</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="last_name"
              fullWidth
              control={control}
              hint={(
                <span>
                  your real name will
                  {' '}
                  <b>not</b>
                  {' '}
                  be visible for other users
                </span>
              )}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Label>Username</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="username"
              fullWidth
              control={control}
              hint={(
                <span>
                  your username name
                  {' '}
                  <b>will</b>
                  {' '}
                  be visible for other users
                </span>
              )}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Label>Use other services</Label>
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            fullWidth
            justifyContent="space-around"
            alignItems="center"
          >
            <GoogleAuthButtonSDK />
            <FacebookAuthButtton />
          </Stack>
        </Row>
      </FieldsWrapper>
      <Button size={isDesktop ? 'lg' : 'md'} type="submit" disabled={isLoading}>
        Save and go next
      </Button>
    </Content>
  );
};
