import styled from 'styled-components';

export const HeaderRow = styled.div`
  margin-bottom: 20px;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 130% */
  letter-spacing: -0.72px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFF 18.58%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HeaderHint = styled.div`
  padding: 30px 16px;
  border-radius: 3px;
  background: linear-gradient(180deg, rgba(111, 0, 255, 0.00) 0%, rgba(255, 0, 153, 0.53) 100%);
  text-align: left;
  color: #FFF;

  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 455px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 15px;
  & + & {
    margin-top: 50px;
  }
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 20px;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }
`;

export const LabelText = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.24px;
  text-transform: uppercase;
`;

export const LabelHint = styled.div`
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
