import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthPartnerLayout } from '../../layouts';
import { useSigninImpersonate } from '../../hooks';

export const ImpersonateScreen: FC = () => {
  const navigate = useNavigate();
  const { result, error, request } = useSigninImpersonate({
    redirect: '/',
  });

  useEffect(() => {
    request();
  }, [request]);

  useEffect(() => {
    if (error || result) {
      navigate('/');
    }
  }, [error, result]);

  return (
    <AuthPartnerLayout />
  );
};
