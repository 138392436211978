import { RequestOptions, apiClient } from '../../api';

export interface ChangePasswordData {
  old_password: string;
  password: string;
}

export const changePassword = async (
  data: ChangePasswordData,
  meta: Partial<RequestOptions> = {},
): Promise<object> => {
  const body = {
    ...data,
    password_confirm: data.password,
  };
  const response = await apiClient.request({
    path: '/api/v1/accounts/change-password/',
    method: 'POST',
    body,
    withAuth: true,
    ...meta,
  });

  return response.data || {};
};
