import { useEffect, useMemo, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const mediaQuery = useMemo(() => {
    return window.matchMedia(query);
  }, [query]);

  const [matches, setMatches] = useState(mediaQuery.matches);

  useEffect(() => {
    const handleChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    handleChange(mediaQuery);

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [mediaQuery]);

  return matches;
};
