import styled from 'styled-components';
import bottomLinesMobile from '../../../shared/assets/images/bottom-lines-mobile.png';
import bottomLinesDesktop from '../../../shared/assets/images/bottom-lines-desk.png';
import cardBg from './card-bg.png';
import radarBg from './radar.png';

export const Root = styled.div`
  min-height: calc(100svh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${(p) => p.theme.breakpoints.down('mobile')} {
    margin-top:40px;
  }
`;

export const Radar = styled.div`
  width: 426px;
  aspect-ratio: ${526 / 200};
  background-image: url(${radarBg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Title = styled.div`
  ${(p) => p.theme.typography.base};
  color: #FFF;
  font-size: 30px;
  line-height: 62.4px; /* 156% */
  letter-spacing: -0.6px;
  text-transform: uppercase;
`;

export const ContentCard = styled.div`
  width: 80%;
  max-width: 800px;
  aspect-ratio: ${1642 / 576};
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  background-image: url(${cardBg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 100%;
    margin: 120px auto;
    font-size: 56px;
    line-height: 62.4px;
    letter-spacing: -0.44px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const BottomLines = styled.div`
  width: 100%;
  margin-top: 40px;
  height: 40px;
  background-image: url(${bottomLinesMobile});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 60px;
    background-image: url(${bottomLinesDesktop});
  }
`;
