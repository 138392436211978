import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProfileLayout } from '../../layouts';
import { Container, FadeIn } from '../../../shared';
import {
  Empty,
  LeftButton,
  List,
  Navigation,
  TabsWrapper,
  TopButtons,
} from './styled';
import { ShapeButton, Tabs } from '../../../ui';
import { useLoadCourses } from '../../../catalog';
import { MyCourseCard } from '../../components';

const tabs = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'started',
    label: 'In Progress',
  },
  {
    value: 'favorite',
    label: 'Saved',
  },
];

export const ProfileCoursesScreen: FC = () => {
  const [tab, setTab] = useState<string>('all');
  const { result: courses } = useLoadCourses();

  const items = useMemo(() => {
    if (!courses) {
      return null;
    }
    return courses.data.filter((x) => {
      if (tab === 'started') {
        return x.is_started;
      }
      if (tab === 'favorite') {
        return x.is_favorite;
      }
      return x.is_favorite || x.is_started;
    });
  }, [tab, courses]);

  return (
    <ProfileLayout bodyBg="fill">
      <FadeIn>
        <Navigation>
          <TabsWrapper>
            <Tabs
              direction="column"
              topLine
              items={tabs}
              value={tab}
              onChange={setTab}
            />
          </TabsWrapper>
          <TopButtons>
            <LeftButton>
              <ShapeButton
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                as={Link as any}
                to="/courses"
                glow
                hoverEffect="glitch"
              >
                Find new course
              </ShapeButton>
            </LeftButton>
            {/* <RightButton>
              <ShapeButton
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                as={Link as any}
                to="/account/guidance-test"
                glow
                hoverEffect="glitch"
              >
                Guidance test
              </ShapeButton>
            </RightButton> */}
          </TopButtons>
        </Navigation>
      </FadeIn>
      <FadeIn active={Boolean(courses)}>
        <Container>
          {items && items.length > 0 && (
            <List>
              {items?.map((item) => (
                <MyCourseCard key={item.id} item={item} />
              ))}
            </List>
          )}
          {items && items.length === 0 && (
            <Empty>You don&apos;t have courses</Empty>
          )}
        </Container>
      </FadeIn>
    </ProfileLayout>
  );
};
