import { styled } from 'styled-components';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1304px;
  margin-left: auto;
  margin-right: auto;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-left: 36px;
    padding-right: 36px;
  }
 
`;
