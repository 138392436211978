import styled from 'styled-components';
import shapeLeft from '../../assets/shapes/nav-board-left.svg';
import shapeRight from '../../assets/shapes/nav-board-right.svg';

export const NavBoard = styled.div`
  position: relative;
  width: 100%;

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    aspect-ratio: ${68 / 201};
    background-size: contain;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    background-image: url(${shapeLeft});
    background-position: left center;
  }
  &::after {
    right: 0;
    background-image: url(${shapeRight});
    background-position: right center;
  }
`;
