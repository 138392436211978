import { config } from '../../../config';
import { apiClient } from '../../api';
import { getFileExtensionByMimeType } from './mimeType';
import { parseFileUrl } from './parseFileUrl';

export interface DownloadFileOptions {
  fileName?: string | null;
  fileExtension?: string | null;
}

export const downloadBlob = (blob: Blob, options: DownloadFileOptions = {}) => {
  const blobExtension = getFileExtensionByMimeType(blob.type);

  const name: string = options.fileName || 'file';
  const extension: string | null = options.fileExtension || blobExtension || null;

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}${extension ? `.${extension}` : ''}`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const downloadFileByUrl = async (
  fileUrl: string,
  options: DownloadFileOptions = {},
): Promise<void> => {
  const url = `${config.apiBaseUrl}${fileUrl}`;
  const requestOptions = await apiClient.prepareRequestOptions({ path: url, baseUrl: '', withAuth: true });
  const response = await fetch(requestOptions.url, {
    headers: requestOptions.headers,
    method: 'GET',
    mode: 'cors',
    referrerPolicy: 'strict-origin-when-cross-origin',
  });
  // let response: Response;
  // try {
  //   response = await apiClient.request(fileUrl, {
  //     mode: 'cors',
  //     referrerPolicy: 'strict-origin-when-cross-origin',
  //   });
  // } catch (e) {
  //   // Retry for cors with new param
  //   const url = new URL(fileUrl);
  //   url.searchParams.append('download', '');
  //   response = await fetch(url.toString(), {
  //     mode: 'cors',
  //     referrerPolicy: 'strict-origin-when-cross-origin',
  //   });
  // }

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const blob = await response.blob();

  const parsedFileUrl = parseFileUrl(url || '');

  downloadBlob(blob, {
    fileName: options.fileName || parsedFileUrl.name,
    fileExtension: options.fileExtension || parsedFileUrl.extension,
  });
};
