import { apiClient } from '../../api';
import { CMSContentBlock } from '../types';

export const loadContentBlock = async (
  page: string,
): Promise<CMSContentBlock[]> => {
  const response = await apiClient.request({
    path: `/api/v1/cms/${page}/content`,
    method: 'GET',
  });

  return response.data;
};
