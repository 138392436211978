import styled from 'styled-components';

export const Root = styled.div`
  padding: 80px 23px 40px;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(229, 229, 255, 0.33) 9.9%,
    #cecdff 43.23%
  );
  font-weight: 400;

  & h3 {
    color: #fff;
    font-family: ${(p) => p.theme.typography.fontFamily};
    font-size: 16px;
    line-height: 39px;
    letter-spacing: -0.24px;
    text-transform: capitalize;

    ${(p) => p.theme.breakpoints.down('mobile')} {
      font-size: 14px;
    }
  }

  & p {
    color: #000;
    font-family: ${(p) => p.theme.typography.fontFamilySecondary};
    font-size: 14px;
    line-height: 20.5px;
    letter-spacing: 0.28px;

    ${(p) => p.theme.breakpoints.down('mobile')} {
      font-size: 12px;
    }
  }

  font-style: normal;

  ${(p) => p.theme.breakpoints.down('mobile')} {
    margin-top: 15px;
    padding: 80px 20px 40px;
  }
`;

export const ResultsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #5e009a;
  }
`;

export const CourseTitle = styled.h5`
  padding: 0;
  margin: 0;
  color: #000;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px;
  letter-spacing: -0.36px;
  text-transform: capitalize;
`;

export const ResultContent = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
`;

export const ResultAnswers = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.015em;
  text-align: left;
  color: #000;
`;
