import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Container, InfoPageLayout } from '../../../shared';
import { useStudyContext } from '../../containers';
import { LessonTextContent, ProgressWrapper } from '../../components';
import { Button } from '../../../ui';
import {
  ActionsPanel, ButtonsBlock, Content, Title,
} from '../styled';

export const StudyFinishScreen: FC = () => {
  const { userCourse } = useStudyContext();

  const content = useMemo(
    () => `<p>You completed the ${
      userCourse?.course.name || ''
    } course! <br/> <br/> 
 Very nice! You can now see your updated statistics in the profile</p>`,
    [userCourse?.course.name],
  );

  return (
    <InfoPageLayout bodyBg="fillGradient">
      <Container>
        <ProgressWrapper isFinal userCourse={userCourse}>
          <Content>
            <Title>Congratulations!</Title>
            <Title>you completed the course</Title>
            <LessonTextContent content={content} />
            <ActionsPanel>
              <ButtonsBlock>
                <Button
                  size="lg"
                  variant="contained"
                  as={Link as any}
                  to="/courses"
                >
                  FIND NEW COURSE
                </Button>
              </ButtonsBlock>
            </ActionsPanel>
          </Content>
        </ProgressWrapper>
      </Container>
    </InfoPageLayout>
  );
};
