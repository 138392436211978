import { FC, ReactNode } from 'react';
import { ContentWrapper } from './styles';

export interface AuthorizedLayoutProps {
  children: ReactNode;
}

export const AuthorizedLayout: FC<AuthorizedLayoutProps> = ({
  children,
}) => {
  return (
    <ContentWrapper>{children}</ContentWrapper>
  );
};
