import { FC } from 'react';
import { Course } from '../../types';
import courseDefaultImage from '../../../shared/assets/images/course-default.png';
import {
  Badge,
  ItemImage,
  LikeAction,
  Name,
  RatingWrapper,
  Root,
  Tags,
} from './styled';
import {
  HeartFilledIcon,
  RatingView,
  Tag,
  TimeIcon,
} from '../../../ui';
import { getLevelIcon } from '../../utils';
import { useDuration } from '../../hooks';
import { FavouriteActionWrapper } from '../FavouriteActionWrapper';
import { LikeActionText, Price } from '../../screens/CoursesScreen/styled';

export interface CourseCardProps {
  item: Course;
}

export const CourseCard: FC<CourseCardProps> = (props) => {
  const { item } = props;

  const duration = useDuration(item.duration || null);

  const LevelIcon = getLevelIcon(item.grade);

  return (
    <Root
      background={
        item.css_config?.gradient
        || item.categories?.[0]?.css_config?.gradient
        || item.css_config?.color
      }
    >
      <Tags>
        {duration && <Tag startIcon={<TimeIcon />}>{duration}</Tag>}
        <Tag startIcon={LevelIcon ? <LevelIcon /> : null}>{item.grade}</Tag>
        {!!item.rate && item.rate >= 4.5 && <Tag>Top rate</Tag>}
      </Tags>
      <ItemImage src={item.image || courseDefaultImage} />
      <Name>{item.name}</Name>
      <Price>
        Play
        {' '}
        {item.price_gbp
        && item.price_gbp !== '0.00'
        && item.price_with_commission
          ? `£${item.price_with_commission}`
          : 'free'}
      </Price>
      <RatingWrapper>
        <RatingView value={item.rate} />
      </RatingWrapper>
      <FavouriteActionWrapper item={item}>
        {(toggle) => (
          <LikeAction onClick={toggle} isActive={item.is_favorite || false}>
            <LikeActionText>
              {item.is_favorite ? 'Saved' : 'Save'}
            </LikeActionText>
            {' '}
            <HeartFilledIcon isSaved={item?.is_favorite} />
          </LikeAction>
        )}
      </FavouriteActionWrapper>
      {item.badge && <Badge>{item.badge}</Badge>}
    </Root>
  );
};
