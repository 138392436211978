import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Callout,
  Content,
  FooterWrapper,
  HeaderWrapper,
  MainScreen,
  Root,
  SubContainer,
} from '../../components/CourseContentItems/styled';
import {
  Container, FadeIn, Footer, Header,
} from '../../../shared';
import { useBuyCourse, useLoadCourse, useStartCourse } from '../../hooks';
import { CheckoutForm, CourseHeader, ReviewsCarousel } from '../../components';
import { CourseContentItem } from '../../components/CourseContentItems/CourseContentItems';
import { PageDivider } from '../../../pageBlocks';
import { ShapeButton, Stack, useBreakpoint } from '../../../ui';
import { useAuthContext, useUserContext } from '../../../auth';

export const CourseScreen: FC = () => {
  const { id } = useParams();
  const { result: item } = useLoadCourse(id ? parseInt(id, 10) : -1);
  const { currentUser } = useUserContext();
  const { authorized } = useAuthContext();

  const {
    result: buyResult,
    isLoading: buyIsLoading,
    request: buy,
  } = useBuyCourse();

  const {
    result: startResult,
    isLoading: startIsLoading,
    request: start,
  } = useStartCourse();

  const isDesktop = useBreakpoint('up', 'md');
  const navigate = useNavigate();

  const started = item?.is_started || Boolean(startResult) || false;

  const isSupplierNotVerify = useMemo(
    () => currentUser?.is_supplier
      && (!currentUser?.supplier_info?.is_verified
        || (currentUser?.supplier_info?.is_stripe_enabled
          && !currentUser?.supplier_info?.is_stripe_verified)),
    [
      currentUser?.is_supplier,
      currentUser?.supplier_info?.is_verified,
      currentUser?.supplier_info?.is_stripe_enabled,
      currentUser?.supplier_info?.is_stripe_verified,
    ],
  );

  const startHandler = useCallback(() => {
    if (item?.id) {
      if (!authorized) {
        navigate('/auth/signin', {
          state: {
            itemId: item?.id,
          },
        });
        return;
      }
      if (isSupplierNotVerify) {
        navigate('/account/verify');
        return;
      }
      if (started) {
        navigate(`/courses/${item.id}/study`);
        return;
      }
      start({
        id: item.id,
      }).then(() => {
        navigate(`/courses/${item.id}/study`);
      });
    }
  }, [item, started, authorized, navigate, currentUser]);

  const buyHandler = useCallback(() => {
    if (item?.id) {
      if (!authorized) {
        navigate('/auth/signin', {
          state: {
            itemId: item?.id,
          },
        });
        return;
      }
      if (isSupplierNotVerify) {
        navigate('/account/verify');
        return;
      }
      if (
        currentUser?.is_supplier
        && !currentUser?.supplier_info?.is_stripe_enabled
      ) {
        return;
      }
      if (item?.is_paid) {
        buy({
          id: item.id,
        });
      } else {
        start({
          id: item.id,
        }).then(() => {
          navigate(`/courses/${item.id}/study`);
        });
      }
    }
  }, [item, authorized, buy, navigate, currentUser]);

  const content = useMemo(() => {
    if (!item) {
      return null;
    }
    const youWillLearn = {
      params: {
        title: 'You will learn',
        children: item?.you_will_learn,
      },
      component: 'you_will_learn',
    };

    const shapePanels = {
      params: {
        text1: `${item?.user_started_count} people are studying right now`,
        text2: `${item?.user_finished_count} people completed the course`,
      },
      component: 'shapePanels',
    };

    const syllabus = {
      params: {
        title: 'Syllabus',
        children: item?.syllabus,
      },
      component: 'syllabus',
    };

    const supplier = {
      params: {
        image: item?.supplier_avatar?.image,
        title: 'Supplier',
        outline: true,
        children: item?.supplier_description,
      },
      component: 'supplier',
    };

    const forWhom = {
      params: {
        title: 'For whom',
        children: item?.for_whom,
      },
      component: 'for_whom',
    };

    const course_items = [
      shapePanels,
      youWillLearn,
      syllabus,
      supplier,
      forWhom,
    ];

    return course_items.map((x, index) => (
      <CourseContentItem
        course={item}
        // eslint-disable-next-line react/no-array-index-key
        key={`${x.component}_${index}`}
        data={x}
      />
    ));
  }, [item]);

  return (
    <Root>
      <MainScreen>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        {buyResult ? (
          <Stack justifyContent="center" alignItems="center">
            <FadeIn>
              <CheckoutForm course={item} data={buyResult} />
            </FadeIn>
          </Stack>
        ) : (
          <FadeIn active={Boolean(item)}>
            <Content>
              {item && (
                <>
                  <Container>
                    <CourseHeader
                      item={item}
                      startHandler={startHandler}
                      buyHandler={buyHandler}
                      startIsLoading={startIsLoading}
                      buyIsLoading={buyIsLoading}
                      started={started}
                    />
                  </Container>
                  {content}

                  <Container>
                    <SubContainer paddings>
                      <ReviewsCarousel title="Reviews" courseId={item.id} />
                    </SubContainer>
                  </Container>
                  <div>
                    <PageDivider />
                    <Callout>
                      {(!item?.is_paid || (item?.is_paid && item?.has_paid))
                      && started ? (
                        <ShapeButton
                          disabled={startIsLoading}
                          onClick={startHandler}
                          hoverEffect="glitch"
                          glow
                          color="gradient"
                          size={isDesktop ? 'xxxl' : 'lg'}
                        >
                          {started ? 'Continue' : 'Start'}
                        </ShapeButton>
                        ) : (
                          <ShapeButton
                            onClick={buyHandler}
                            disabled={buyIsLoading}
                            hoverEffect="glitch"
                            glow
                            color="gradient"
                            size={isDesktop ? 'xxxl' : 'lg'}
                          >
                            {item.price_gbp
                          && item.price_gbp !== '0.00'
                          && item.price_with_commission
                              ? `Start £${item.price_with_commission}`
                              : 'Start free'}
                          </ShapeButton>
                        )}
                    </Callout>
                    <PageDivider />
                  </div>
                </>
              )}
            </Content>
          </FadeIn>
        )}
      </MainScreen>
      {!buyResult && (
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      )}
    </Root>
  );
};
