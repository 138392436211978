import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ProfileLayout } from '../ProfileLayout';
import {
  BottomLines,
  Content,
  Navigation,
  TabsWrapper,
  WarningItem,
} from './styled';
import { TabItem, Tabs } from '../../../ui';
import { useUserContext } from '../../../auth';
import warning from '../../../shared/assets/images/warning.svg';

export interface ProfileSettingsLayoutProps {
  children: ReactNode;
}

const tabs = [
  {
    link: '../general',
    label: 'Main',
  },
  {
    link: '../about',
    label: 'About',
  },
  {
    link: '../address',
    label: 'Address',
  },
  {
    link: '../contact',
    label: 'Contact',
  },
  {
    link: '../logo',
    label: 'Logo',
  },
  {
    link: '../password',
    label: 'Password',
  },
];

export const ProfileSettingsLayout: FC<ProfileSettingsLayoutProps> = (
  props,
) => {
  const { children } = props;
  const { currentUser } = useUserContext();

  return (
    <ProfileLayout bodyBg="gradient">
      <Navigation>
        <TabsWrapper>
          <Tabs direction="column" topLine>
            {tabs.map(({ label, link }) => (
              <TabItem as={NavLink} to={link} key={label}>
                {label}
              </TabItem>
            ))}
          </Tabs>
        </TabsWrapper>
        {currentUser?.supplier_info?.is_verified === false && (
          <WarningItem>
            <img src={warning} alt="" />
            New data will appear after the
            <br />
            moderator approval
          </WarningItem>
        )}
      </Navigation>
      <Content>{children}</Content>
      <BottomLines />
    </ProfileLayout>
  );
};
