import { styled } from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.typography.base};
  font-size: 45px;
  text-transform: uppercase;
  color: #fff;
`;
