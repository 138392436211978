import { useCallback, useState } from 'react';

export interface RequestState<Result> {
  result: Result | null;
  isLoading: boolean;
  error: Error | null;
}

export interface UseRequestStateResult<Result> extends RequestState<Result> {
  setResult(result: Result | null): void;
  setIsLoading(isLoading: boolean): void;
  setError(error: Error | null): void;
  reset(): void;
}

export const useRequestState = <Result>(): UseRequestStateResult<Result> => {
  const [result, setResult] = useState<Result | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const reset = useCallback(() => {
    setResult(null);
    setIsLoading(false);
    setError(null);
  }, []);

  return {
    result,
    isLoading,
    error,
    setResult,
    setIsLoading,
    setError,
    reset,
  };
};
