import styled from 'styled-components';
import { Button } from '../../ui';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
`;

export const Title = styled.div`
  position: relative;
  margin: 0 0 8px;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 36px;
  font-weight: 400;
  line-height: 62.4px;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #fff 18.58%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 2;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 56px;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 28px;
    line-height: 36px;
    background: linear-gradient(180deg, #fff 58%, rgba(255, 255, 255, 0) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ActionsPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;


  ${(p) => p.theme.breakpoints.up('md')} {
    justify-content: flex-end;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end; 
  align-items: center;
  gap: 36px;

  ${(p) => p.theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    justify-content: center;
    gap:10px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 10px 89px 10px 88px;
  display: flex;
  width: 100%;
  max-width: 234px;
  height: 49px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #6f00ff;
  background: linear-gradient(180deg, rgba(111, 0, 255, 0) 0%, #9747ff 100%);
  color: #fff;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.down('mobile')} {
    max-width: 100px;
  }
`;
