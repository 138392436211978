import { FC, useMemo } from 'react';
import { Container, InfoPageLayout } from '../../../shared';
import {
  Content, Paragrapth, Separator, Title,
} from './styled';
import { useLoadContentBlock } from '../../../cms/hooks';
import { PAGE } from '../../../cms/constants';

export const AboutScreen: FC = () => {
  const { result } = useLoadContentBlock(PAGE.ABOUT.MAIN);

  const title = useMemo(
    () => (result?.length
      ? result?.find((obj) => obj?.block === PAGE.ABOUT.TITLE)
      : null),
    [result],
  );

  const paragrapths = useMemo(() => {
    return result?.length
      ? result?.filter((obj) => obj?.block?.startsWith(PAGE.ABOUT.PARAGRAPTH))
      : [];
  }, [result]);

  return (
    <InfoPageLayout
      bg="spaceDark"
      bodyBg="darkGradientInverse"
      headerProps={{ background: true }}
    >
      <Container>
        <Content>
          {title && (
            <Title dangerouslySetInnerHTML={{ __html: title?.content }} />
          )}
          {paragrapths?.length
            ? paragrapths.map((paragrapth, index) => (
              <>
                <Paragrapth
                  dangerouslySetInnerHTML={{ __html: paragrapth?.content }}
                />
                {index + 1 !== paragrapths?.length && <Separator />}
              </>
            ))
            : null}
        </Content>
      </Container>
    </InfoPageLayout>
  );
};
