import { css, styled } from 'styled-components';
import bgImage from '../../../shared/assets/images/background-noise.jpg';
import { Container } from '../../../shared';

export const Root = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  background-image: url('${bgImage}');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const TabsWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Body = styled.div<{bg?: 'gradient' | 'fill' | null}>`
  flex-grow: 1;
  padding: 30px 0;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 50px 0;
  }
  
  ${({ bg }) => {
    if (bg === 'fill') {
      return css`
        background-color: #010046;
      `;
    }
    if (bg === 'gradient') {
      return css`
        background: linear-gradient(180deg, #000 0%, rgba(1, 0, 70, 0.21) 100%);
      `;
    }
    return null;
  }}
`;

export const FooterWrapper = styled.div`
`;
