import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLinkdinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" {...props}>
    <path d="M9.24351 12.3172C10.7783 12.3172 12.0272 11.0691 12.0272 9.53433C12.0272 9.3822 11.904 9.25874 11.7516 9.25874C11.5992 9.25874 11.4761 9.3822 11.4761 9.53433C11.4761 10.7648 10.4746 11.7661 9.24351 11.7661C8.01273 11.7661 7.01124 10.7648 7.01124 9.53433C7.01124 8.30354 8.01273 7.30205 9.24351 7.30205C9.39591 7.30205 9.5191 7.17858 9.5191 7.02646C9.5191 6.87433 9.39591 6.75087 9.24351 6.75087C7.70848 6.75087 6.46007 7.99957 6.46007 9.53433C6.46007 11.0688 7.70876 12.3172 9.24351 12.3172Z" fill="#03FBFD" />
    <path d="M28.1627 20.5053C28.0103 20.5053 27.8871 20.6288 27.8871 20.7809V28.1604H24.3372V21.5382C24.3372 20.4218 24.3372 17.4562 21.5664 17.4562C18.8996 17.4562 18.5912 19.7174 18.5912 21.4181V28.1604H14.7682V14.4947H18.3181V16.1328C18.3181 16.2849 18.4413 16.4084 18.5937 16.4084H18.6736C18.7745 16.4084 18.8673 16.3533 18.9156 16.2648C19.5133 15.1671 20.9494 14.5377 22.8551 14.5377C23.0075 14.5377 23.1307 14.4142 23.1307 14.2621C23.1307 14.11 23.0075 13.9865 22.8551 13.9865C21.1037 13.9865 19.6969 14.4903 18.8693 15.3912V14.2191C18.8693 14.067 18.7461 13.9435 18.5937 13.9435H14.4926C14.3402 13.9435 14.2171 14.067 14.2171 14.2191V28.436C14.2171 28.5881 14.3402 28.7116 14.4926 28.7116H18.8668C19.0192 28.7116 19.1424 28.5881 19.1424 28.436V21.4181C19.1424 18.9956 19.8448 18.0074 21.5664 18.0074C23.5171 18.0074 23.786 19.7042 23.786 21.5382V28.4363C23.786 28.5884 23.9092 28.7118 24.0616 28.7118H28.1624C28.3148 28.7118 28.438 28.5884 28.4382 28.4363V20.7809C28.4382 20.6288 28.3151 20.5053 28.1627 20.5053Z" fill="#03FBFD" />
    <path d="M11.7585 13.9435H6.83735C6.68495 13.9435 6.56176 14.067 6.56176 14.2191V28.4363C6.56176 28.5884 6.68495 28.7118 6.83735 28.7118H11.7585C11.9109 28.7118 12.0341 28.5884 12.0341 28.436V14.2191C12.0341 14.067 11.9109 13.9435 11.7585 13.9435ZM11.4829 28.1607H7.11294V14.4947H11.4829V28.1607Z" fill="#03FBFD" />
    <path d="M26.0955 0H8.90013C3.99245 0 0 3.99358 0 8.9024V26.0976C0 31.0064 3.99245 35 8.90013 35H26.0952C31.0053 35 34.9997 31.0064 35 26.0976V8.9024C35 3.99358 31.0053 0 26.0955 0ZM34.4488 26.0976C34.4488 30.7024 30.7014 34.4488 26.0955 34.4488H8.90013C4.29642 34.4488 0.551177 30.7024 0.551177 26.0976V8.9024C0.551177 4.29756 4.29642 0.551181 8.90013 0.551181H26.0952C30.7011 0.551181 34.4485 4.29756 34.4488 8.9024V26.0976Z" fill="#03FBFD" />
    <path d="M33.0838 17.4978C32.9314 17.4978 32.8082 17.6213 32.8082 17.7734V25.4913C32.8082 29.4573 29.4576 32.8082 25.4914 32.8082H9.50835C5.54208 32.8082 2.19148 29.4576 2.19148 25.4913V9.50787C2.19148 5.54213 5.54208 2.1915 9.50835 2.1915H25.4285C25.5809 2.1915 25.7041 2.06803 25.7041 1.91591C25.7041 1.76378 25.5809 1.64031 25.4285 1.64031H9.50835C5.24334 1.64031 1.6403 5.24311 1.6403 9.50787V25.4916C1.6403 29.7566 5.24334 33.3597 9.50835 33.3597H25.4914C29.7564 33.3597 33.3594 29.7566 33.3594 25.4913V17.7734C33.3594 17.6213 33.2362 17.4978 33.0838 17.4978Z" fill="#03FBFD" />
    <path d="M30.9905 4.00902C29.5513 2.57016 27.2609 1.64031 25.1549 1.64031C25.0025 1.64031 24.8793 1.76378 24.8793 1.91591C24.8793 2.06803 25.0025 2.1915 25.1549 2.1915C27.1223 2.1915 29.2598 3.05795 30.6008 4.3987C30.6545 4.45244 30.7251 4.47945 30.7956 4.47945C30.8662 4.47945 30.9367 4.45244 30.9905 4.3987C31.0982 4.29094 31.0982 4.11677 30.9905 4.00902Z" fill="#03FBFD" />
    <path d="M33.0838 11.483C32.9314 11.483 32.8082 11.6065 32.8082 11.7586V15.8597C32.8082 16.0118 32.9314 16.1353 33.0838 16.1353C33.2362 16.1353 33.3594 16.0118 33.3594 15.8597V11.7586C33.3594 11.6065 33.2362 11.483 33.0838 11.483Z" fill="#03FBFD" />
  </svg>
);
export default SvgLinkdinIcon;
