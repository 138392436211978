import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ProfileLayout } from '../ProfileLayout';
import {
  BottomLines, Content, Navigation, TabsWrapper,
} from './styled';
import { TabItem, Tabs } from '../../../ui';
import { useAuthContext } from '../../../auth';

export interface ProfileSettingsLayoutProps {
  children: ReactNode;
}

export const ProfileSettingsLayout: FC<ProfileSettingsLayoutProps> = (props) => {
  const { children } = props;
  const { logout } = useAuthContext();
  return (
    <ProfileLayout bodyBg="gradient">
      <Navigation>
        <TabsWrapper>
          <Tabs
            direction="column"
            topLine
          >
            <TabItem
              as={NavLink}
              to="../general"
            >
              Account
            </TabItem>

            <TabItem
              as={NavLink}
              to="../password"
            >
              Password
            </TabItem>

            {/* <TabItem
              onClick={logout}
            >
              Logout
            </TabItem> */}
          </Tabs>
        </TabsWrapper>
      </Navigation>
      <Content>
        {children}
      </Content>
      <BottomLines />
    </ProfileLayout>
  );
};
