import React, { type SVGProps } from 'react';

const SvgStarFilled = (
  props: SVGProps<SVGSVGElement> & { stopOffSet?: number },
) => {
  const { stopOffSet = 0 } = props;
  const isShowHalfFill = stopOffSet === 0 || stopOffSet === 100;

  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
      {!isShowHalfFill && (
        <linearGradient id="linear-gradient">
          <stop offset="0%" stopColor="#FAFF00" />
          <stop offset={`${stopOffSet}%`} stopColor="#FAFF00" />
          <stop offset={`${100 - stopOffSet}%`} stopColor="#777777" />
          <stop offset="100%" stopColor="#777777" />
        </linearGradient>
      )}
      <path
        fill={isShowHalfFill ? 'currentColor' : 'url(#linear-gradient)'}
        fillRule="evenodd"
        d="m6.17 14.894-1.377 8.024L12 19.13l7.206 3.788-1.376-8.024 5.83-5.682-8.057-1.171L12 .74l-3.603 7.3L.34 9.21l5.83 5.683Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgStarFilled;
