import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSenior = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 21v-2h6v2H1ZM9 21v-2h6v2H9ZM9 17v-2h6v2H9ZM9 13v-2h6v2H9ZM17 21v-2h6v2h-6ZM17 17v-2h6v2h-6ZM17 13v-2h6v2h-6ZM17 9V7h6v2h-6ZM17 5V3h6v2h-6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSenior;
