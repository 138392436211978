import { ReactNode } from 'react';
import { Root, RootProps } from './styled';
import { FCWithAs } from '../../types';

export interface ButtonProps extends RootProps {
  children?: ReactNode;
}

export const Button: FCWithAs<'button', ButtonProps> = ({
  as = 'button',
  variant = 'contained',
  size = 'md',
  disabled = false,
  children,
  ...rest
}) => {
  return (
    <Root
      as={as}
      variant={variant}
      size={size}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Root>
  );
};
