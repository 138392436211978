import { FC, useMemo } from 'react';
import YouTube from 'react-youtube';
import { getYoutubeId } from '../../utils';
import { Root } from './styled';

export interface LessonVideoContentProps {
  videoUrl: string;
}

const opts = {
  width: '100%',
  playerVars: {
    controls: 2,
    loop: 0,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    autoplay: 1,
    iv_load_policy: 3,
  },
};

export const LessonVideoContent: FC<LessonVideoContentProps> = (props) => {
  const { videoUrl } = props;

  const videoId = useMemo(() => {
    return getYoutubeId(videoUrl);
  }, [videoUrl]);

  if (!videoId) {
    return null;
  }

  return (
    <Root>
      <YouTube videoId={videoId} opts={opts} />
    </Root>
  );
};
