import styled from 'styled-components';

export const InfoPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 860px;
  margin: 0 auto;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  color: #fff;

  h1 {
    margin-bottom: 12px;
    font-size: 26px;
    font-weight: 400;
    line-height: 31.4px; /* 120.769% */
    letter-spacing: -0.39px;
    text-transform: uppercase;

    ${(p) => p.theme.breakpoints.up('md')} {
      margin-bottom: 24px;
      font-size: 48px;
      line-height: 40px; /* 83.333% */
      letter-spacing: 0.96px;
    }
  }
  p {
    margin: 0;
  }
`;
