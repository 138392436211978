import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 50px;

  .ff-partial {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    ${(p) => p.theme.typography.base};
    color: #03FBFD;
    background: transparent;
    border: 1px solid #03FBFD;
    border-radius: 3px;

    a {
      color: #03FBFD;
      opacity: 1;
      transition: opacity 0.3 ease-in-out;

      &:hover {
        color: #03FBFD;
        opacity: 0.6;
      }
    }
  }

  .ff-form {
    background: transparent !important;


    .ff-success {
      text-align: center;
      font-size: 22px;
    }


    .ff-banner-image {
      display: none;
    }

    div, p, h3, .ff-help, .ff-item-qs, .ff-form-check-label {
      opacity: 1;
      ${(p) => p.theme.typography.base};
      font-family: ${(p) => p.theme.typography.fontFamily} !important;
      color: #fff !important;
    }

    .ff-widget-error {
      color: #f55 !important;
    }

    .ff-help {
      opacity: 0.6;
      font-size: 80%;
    }

    .ff-powered-img {
      position: absolute;
      top: -10000px;
      left: -10000px;
      pointer-events: none;
      display: none !important;
      opacity: 0;
    }

    .ff-file-upload {
      border: none;
      border-radius: 3px;
      background: linear-gradient(180deg, #6F00FF 0%, #FFA665 100%);
      a {
        color: #FFFFFF;
      }
    }

    textarea, input[type="text"] {
      background-color: transparent;
      color: #03FBFD;
      font: inherit !important;
      box-shadow: 0 0 0 1px #03FBFD !important;

      &:focus {
        box-shadow: 0 0 0 1px #03FBFD !important;
      }
    }

    .ff-form-check-input {
      accent-color: #03FBFD;
    }

    .ff-button-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    
    .ff-submit {
      border: none;
      border-radius: 3px;
      background: linear-gradient(180deg, #6F00FF 0%, #FFA665 100%);
      color: #FFFFFF;
      font: inherit !important;
    }
  }
`;

export const ActionFormBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ItemsBlock = styled.div`
  margin: 50px 0 0;
`;
