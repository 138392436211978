import { FC } from 'react';
import {
  Container, FadeIn, Footer, Header,
} from '../../../shared';
import {
  Content,
  FooterWrapper,
  HeaderWrapper,
  Item,
  Items,
  ItemsWrapper,
  MainScreen,
  Root,
  Title,
} from './styled';
import { useLoadCategories } from '../../hooks';
import { CategoryCard } from '../../components';

export const CategoriesScreen: FC = () => {
  const { result: categories } = useLoadCategories();
  return (
    <Root>
      <MainScreen>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <Content>
          <FadeIn active={Boolean(categories)}>
            <Container>
              <Title>Courses</Title>
            </Container>
            {categories?.data && (
            <ItemsWrapper>
              <Items>
                {categories?.data.map((item) => (
                  <Item key={item.id} to={`/courses?category=${item.id}`}>
                    <CategoryCard item={item} />
                  </Item>
                ))}
              </Items>
            </ItemsWrapper>
            )}
          </FadeIn>
        </Content>
      </MainScreen>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
};
