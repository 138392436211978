import {
  FC, useCallback, useEffect,
} from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, InfoPageLayout } from '../../../shared';
import { useCreateTicketMessage, useLoadTicket } from '../../hooks';
import {
  Chat, Content, FormFooter, Input, InputWrapper, MessageList, Subject, Title,
} from './styled';
import { Button, Stack } from '../../../ui';
import { TicketAttachmentInput, TicketMessageView } from '../../components';

const schema = yup
  .object({
    message: yup.string().required('Required'),
    attachments: yup.array().optional().nullable().default([]),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const TicketScreen: FC = () => {
  const { id } = useParams();
  const {
    control, reset, register, handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { result: data, request: reload } = useLoadTicket(id || 0);
  const { result, isLoading: sending, request: send } = useCreateTicketMessage();

  const onSubmit = useCallback(
    (formData: FormData) => {
      if (!id) {
        return;
      }
      const { message, attachments } = formData;
      send({
        ticketId: id,
        message,
        attachments: attachments || undefined,
      });
    },
    [send, id],
  );

  useEffect(() => {
    if (result) {
      reset();
      reload();
    }
  }, [result, reload, reset]);

  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradientCenter">
      <Container>
        <Title>ticket thread</Title>
        {data && (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Content>
              <Stack gap={4}>
                <Subject>{data.subject}</Subject>
                <Chat>
                  <MessageList>
                    {data?.messages?.map((item) => (
                      <TicketMessageView key={item.id} item={item} />
                    ))}
                  </MessageList>
                  <InputWrapper>
                    <Input placeholder="WRITE YOUR MESSAGE HERE..." {...register('message')} />
                  </InputWrapper>
                </Chat>
                <FormFooter>
                  <TicketAttachmentInput
                    control={control}
                    name="attachments"
                  />
                  <Button size="lg" type="submit" disabled={sending}>Submit</Button>
                </FormFooter>
              </Stack>
            </Content>
          </form>
        )}
      </Container>
    </InfoPageLayout>
  );
};
