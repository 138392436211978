import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Root } from './styled';
import { useLoadContentBlock } from '../../../cms/hooks';
import { PAGE } from '../../../cms/constants';

export const SpecialOffer: FC = () => {
  const { result } = useLoadContentBlock(PAGE.HOME_BANNER.MAIN);
  return (
    <Root as={Link} to="/partner">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: result?.[0]?.content ?? '' }} />
    </Root>
  );
};
