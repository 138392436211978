import { FC } from 'react';
import {
  Navigate, Route, Routes, useParams,
} from 'react-router-dom';
import { StudyPreviewProvider } from '../../containers';
import { StudyLessonPreviewScreen } from '../StudyLessonPreviewScreen';
import { StudyStructPreviewScreen } from '../StudyStructPreviewScreen';

export const StudyPreviewScreen: FC = () => {
  const { courseId } = useParams();

  if (!courseId) {
    return <Navigate replace to="/" />;
  }

  return (
    <StudyPreviewProvider courseId={courseId}>
      <Routes>
        <Route path="/" element={<StudyStructPreviewScreen />} />
        <Route path="/:lessonId" element={<StudyLessonPreviewScreen />} />
      </Routes>
    </StudyPreviewProvider>
  );
};
