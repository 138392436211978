import React, { FC, useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useUserContext } from '../../../auth';
import { ShapeCard } from '../../../shared';
import {
  ErrorMessage, Footer, Form, Root,
} from './styled';
import { Button } from '../../../ui';
import { Course } from '../../types';

export interface CheckoutFormViewProps {
  course: Course | null;
}

export const CheckoutFormView: FC<CheckoutFormViewProps> = (props) => {
  const { course } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useUserContext();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/courses/${course?.id}/payment-result`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || 'Unknown error');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
    defaultValues: {
      billingDetails: {
        email: currentUser?.email,
      },
    },
  };

  return (
    <Root>
      <ShapeCard>
        <Form id="payment-form" onSubmit={handleSubmit}>
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(e) => setEmail(e.value.email.toLowerCase())}
          />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <Footer>
            <Button disabled={isLoading || !stripe || !elements} id="submit">
              Pay now
            </Button>
            {message && <ErrorMessage id="payment-message">{message}</ErrorMessage>}
          </Footer>
        </Form>
      </ShapeCard>
    </Root>
  );
};
