import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileLayout } from '../../layouts';
import { Container, FadeIn } from '../../../shared';
import { AvatarList } from '../../components';
import { useUserContext } from '../../../auth';
import { Avatar } from '../../types';
import {
  ButtonWrapper,
  HeaderScreen,
  HeaderSubtitle,
  HeaderTitle,
} from './styles';
import { Button, useBreakpoint } from '../../../ui';

export const SelectAvatarScreen: FC = () => {
  const {
    currentUser, updating, updated, update,
  } = useUserContext();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<Avatar | null>(null);
  const [ready, setReady] = useState(false);

  const isDesktop = useBreakpoint('up', 'md');

  const onSelect = useCallback((item: Avatar) => {
    setCurrent(item);
  }, []);

  const onReady = useCallback(() => {
    setReady(true);
  }, []);

  const save = useCallback(() => {
    if (current) {
      update({
        avatar: current,
      });
    }
  }, [current]);

  const doubleClick = useCallback((avatar: Avatar) => {
    if (avatar) {
      update({
        avatar,
      });
    }
  }, []);

  const requestCustomAvatar = useCallback(() => {
    window.open('https://www.theastra-web.co.uk/', '_blank');
  }, []);

  useEffect(() => {
    if (updated) {
      navigate('/account/welcome');
    }
  }, [updated]);

  return (
    <ProfileLayout showTabs={false} bodyBg="gradient">
      <FadeIn active={ready}>
        <Container>
          <HeaderScreen>
            <HeaderTitle>AVATAR</HeaderTitle>
            <HeaderSubtitle>
              Now, please choose your profile picture. you can always change it
              later in your profile settings
            </HeaderSubtitle>
          </HeaderScreen>
          <AvatarList
            current={current?.id || currentUser?.avatar?.id || null}
            onSelect={onSelect}
            onDoubleClick={doubleClick}
            onReady={onReady}
            onRequestCustomAvatar={requestCustomAvatar}
          />
        </Container>
        <ButtonWrapper>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            onClick={save}
            disabled={
              updating || !current || current?.id === currentUser?.avatar?.id
            }
          >
            Select avatar
          </Button>
          <Button size={isDesktop ? 'lg' : 'md'} onClick={requestCustomAvatar}>
            Get custom avatar
          </Button>
        </ButtonWrapper>
      </FadeIn>
    </ProfileLayout>
  );
};
