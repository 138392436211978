/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import { FC } from 'react';
import { Container, InfoPageLayout } from '../../../shared';
import { InfoPageContent } from '../../components';

export const PrivacyPolicyScreen: FC = () => {
  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradient">
      <Container>
        <InfoPageContent>
          <h1>Privacy policy</h1>
          <h2 id="-website-privacy-policy-"><strong>Website Privacy Policy</strong></h2>
          <p><strong>1. Data Protection</strong></p>
          <p>We comply with the principles of the Data Protection Act 2018, General Data Protection Regulation (GDPR) and the UKGDPR when dealing with all data received from customers or visitors to our website.</p>
          <p><strong>2. Our Services</strong></p>
          <p>We only hold the data necessary to offer services provided on our website. We do not ask for or use more data than necessary to provide you with a proper service. This means we only process and hold data related to purchases and subsequent support related to those purchases, in accordance with Article 6(1)(b) of the General Data Protection Regulation (GDPR). Any processing of your data is only done to provide or perform the services or products you have purchased.</p>
          <p><strong>3. Data Protection Register</strong></p>
          <p>Data is only used for the purposes described in our entry on the Data Protection Register.</p>
          <p><strong>4. Required Period</strong></p>
          <p>We only hold personal data for as long as necessary. Once data is no longer needed, we delete it.</p>
          <p><strong>5. Card &amp; Payment Processing Data</strong></p>
          <p>We use third-party payment providers to handle card and payment processing data securely. We never see or store any card or payment information. In accordance with Article 6(1)(b) of the General Data Protection Regulation (GDPR), any other data is only used to process your purchase securely. In accordance with Article 6(1)(c) of the General Data Protection Regulation (GDPR), we need to retain data related to all purchases to fulfil our existing legal obligations to account for tax and VAT on all transactions.</p>
          <p><strong>6. Backup &amp; Data Storage</strong></p>
          <p>For administrative reasons, customer data and email subscription data may be passed to and stored securely with third-party service providers located outside the UK or the EEA (European Economic Area). This is done to backup and preserve your data where it is needed to carry on offering a service to you.</p>
          <p><strong>7. Data Requests &amp; Right to Erasure</strong></p>
          <p>As a customer, you can at any time (and free of charge) request details of the data we hold relating to you - and you can also request any such data is amended or deleted – the right to erasure (the right to be forgotten). Please note that if you request deletion of your data, this may prevent us from offering any further support relating to your previous purchases or previous services offered to you.</p>
          <p><strong>8. Email Updates</strong></p>
          <p>We regularly email website news and information updates to those customers who have specifically subscribed to our email service. All subscription emails sent by us contain clear information on how to unsubscribe from our email service. Our email service is an opt-in service only; with your prior consent required in accordance with Article 6(1)(a) of the General Data Protection Regulation (GDPR). This means to subscribe you must enter your details into the email subscription page and submit them to us.</p>
          <p><strong>9. Our Promise</strong></p>
          <p>We never sell rent or exchange mailing lists.</p>
          <p><strong>10. Data Sharing With Partners</strong></p>
          <p>We do not share commercial or technical data with our partners unless we have specifically sought and obtained your prior approval. Where we request such approval, we will always fully disclose our partners’ details and how your data is used – please see the Schedule to this policy for further details. Please note that even when we have your specific consent, we only share data with partners that operate their own privacy policy in full accordance with the Data Protection Act 2018 and the General Data Protection Regulation (GDPR).</p>
          <p><strong>11. Spam</strong></p>
          <p>In accordance with the Privacy and Electronic Communications (EC Directive) Regulations 2003, we never send bulk unsolicited emails, (popularly known as Spam) to email addresses.</p>
          <p>
            <strong>12.</strong>
            {' '}
            <strong>Product Updates</strong>
          </p>
          <p>We may send emails to existing customers or prospective customers who have enquired or registered with us, regarding products or services directly provided by us.</p>
          <p><strong>13. Email Content</strong></p>
          <p>All emails sent by us will be clearly marked as originating from us. All such emails will also include clear instructions on how to unsubscribe from our email service and any future emails. Such instructions will either include a link to a page to unsubscribe or a valid email address to which you should reply, with “unsubscribe” as the email subject heading.</p>
          <p><strong>14. Cookies</strong></p>
          <p>Our website uses &quot;cookies&quot; to track use and allow customers to purchase from our website. Please note that these cookies do not contain or pass any personal, confidential or financial information or any other information that could be used to identify individual visitors or customers purchasing from our website. Please note that you are free to refuse cookies. However, for purely technical reasons this may prevent you from purchasing from our website. This is because anonymous cookies are commonly used to keep track of the contents of customers’ shopping baskets or trolleys during the checkout process. This facility ensures that the items added to (or removed from) your basket are accurately stated when you go to pay.</p>
          <p><strong>15. Contact Us</strong></p>
          <p>
            If you have any questions relating to our Privacy Policy or wish to contact us regarding the data we hold relating to you, please email us at
            {' '}
            <em>[Insert email address here]</em>
            {' '}
            or use our contact form.
          </p>
          <p>
            This policy was last updated on
            {' '}
            <em>[insert date]</em>
          </p>
          <p><strong>Schedule</strong></p>
          <p><em>[Insert details of partners with whom you share customer data and the purpose for which it is shared. If you do not share data remove this Schedule]</em></p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 23.0</p>
          <h2 id="cookie-policy-ads-only">Cookie policy ads only</h2>
          <p><strong>1. What are cookies?</strong></p>
          <p>Cookies are small files containing a short string of numbers and letters placed automatically by a website into the cookie folder in your browser. Cookies are generally used to make a website easier and faster to use. The cookie recognises that a device is or has accessed the website and acts accordingly, (depending on what the cookie is designed to do). Mostly cookies perform mundane, but necessary tasks.</p>
          <p><strong>2. How do we use cookies?</strong></p>
          <p>We typically use cookies for the following purposes:</p>
          <p>
            <strong>2.1</strong>
            {' '}
            Ensure all parts of the website requested by visitors work correctly and appear quickly.
          </p>
          <p>
            <strong>2.2</strong>
            {' '}
            Alert us whenever a page on the website or the entire website is slow and not working at all.
          </p>
          <p>
            <strong>2.3</strong>
            {' '}
            To display adverts that may be more relevant and useful to you.
          </p>
          <p>
            <strong>2.4</strong>
            {' '}
            Analyse how visitors use our website, so that we can continually improve it by adding more information and services and making it easier to navigate and use.
          </p>
          <p>
            <strong>2.5</strong>
            {' '}
            To remember you as a visitor.
          </p>
          <p>
            <strong>2.6</strong>
            {' '}
            To allow visitors to share our website content on social media and with friends.
          </p>
          <p><strong>3. Consent and cookies</strong></p>
          <p>Some cookies used by us are sent to your computer or mobile device as soon as the first page you view on our website is displayed. As a result we infer consent for these and other cookies if you use our website.</p>
          <p><strong>4. No personal information in cookies</strong></p>
          <p>None of the cookies we use contain or pass personal, confidential, financial information or any other information that could be used to identify individual visitors to the website.</p>
          <p><strong>5. Refusing cookies</strong></p>
          <p>Using your browser settings you are free to refuse cookies. However, for purely technical reasons this may cause errors when trying to use our website.</p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 3.0</p>
          <h2 id="cookie-policy-e-commerce-ads">Cookie policy e-commerce ads</h2>
          <p><strong>1. What are cookies?</strong></p>
          <p>Cookies are small files containing a short string of numbers and letters placed automatically by a website into the cookie folder in your browser. Cookies are generally used to make a website easier and faster to use. The cookie recognises that a device is or has accessed the website and acts accordingly, (depending on what the cookie is designed to do). Mostly cookies perform mundane, but necessary tasks.</p>
          <p><strong>2. How do we use cookies?</strong></p>
          <p>We typically use cookies for the following purposes:</p>
          <p>
            <strong>2.1</strong>
            {' '}
            Ensure all parts of the website requested by visitors work correctly and appear quickly.
          </p>
          <p>
            <strong>2.2</strong>
            {' '}
            Alert us whenever a page on the website or the entire website is slow and not working at all.
          </p>
          <p>
            <strong>2.3</strong>
            {' '}
            To display adverts that may be more relevant and useful to you.
          </p>
          <p>
            <strong>2.4</strong>
            {' '}
            Analyse how visitors use our website, so that we can continually improve it by adding more information and services and making it easier to navigate and use.
          </p>
          <p>
            <strong>2.5</strong>
            {' '}
            To ensure that products you as a customer add to your shopping basket remain in your basket as you go through the checkout process, so you get what you pay for.
          </p>
          <p>
            <strong>2.6</strong>
            {' '}
            To remember you as a customer.
          </p>
          <p>
            <strong>2.7</strong>
            {' '}
            To allow customers and visitors to share our website content on social media and with friends.
          </p>
          <p><strong>3. Consent and cookies</strong></p>
          <p>Some cookies used by us are sent to your computer or mobile device as soon as the first page you view on our website is displayed. As a result we infer consent for these and other cookies if you use our website.</p>
          <p><strong>4. No personal information in cookies</strong></p>
          <p>None of the cookies we use contain or pass personal, confidential, financial information or any other information that could be used to identify individual visitors to the website.</p>
          <p><strong>5. Your shopping basket and cookies</strong></p>
          <p>If you buy a product from our website we do use cookies to pass your contact details through the checkout process. But we never request or store any financial details in this way. Your financial details are only ever passed using an encrypted and secure format from our secure payment page to your own bank for processing.</p>
          <p><strong>6. Refusing cookies</strong></p>
          <p>Using your browser settings you are free to refuse cookies. However, for purely technical reasons this may cause errors when trying to use our website.</p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 3.0</p>
          <h2 id="cookie-policy-e-commerce-only">Cookie policy e-commerce only</h2>
          <p><strong>1. What are cookies?</strong></p>
          <p>Cookies are small files containing a short string of numbers and letters placed automatically by a website into the cookie folder in your browser. Cookies are generally used to make a website easier and faster to use. The cookie recognises that a device is or has accessed the website and acts accordingly, (depending on what the cookie is designed to do). Mostly cookies perform mundane, but necessary tasks.</p>
          <p><strong>2. How do we use cookies?</strong></p>
          <p>We typically use cookies for the following purposes:</p>
          <p>
            <strong>2.1</strong>
            {' '}
            Ensure all parts of the website requested by visitors work correctly and appear quickly.
          </p>
          <p>
            <strong>2.2</strong>
            {' '}
            Alert us whenever a page on the website or the entire website is slow and not working at all.
          </p>
          <p>
            <strong>2.3</strong>
            {' '}
            Analyse how visitors use our website, so that we can continually improve it by adding more information and services and making it easier to navigate and use.
          </p>
          <p>
            <strong>2.4</strong>
            {' '}
            To ensure that products you as a customer add to your shopping basket remain in your basket as you go through the checkout process, so you get what you pay for.
          </p>
          <p>
            <strong>2.5</strong>
            {' '}
            To remember you as a customer.
          </p>
          <p>
            <strong>2.6</strong>
            {' '}
            To allow customers and visitors to share our website content on social media and with friends.
          </p>
          <p><strong>3. Consent and cookies</strong></p>
          <p>Some cookies used by us are sent to your computer or mobile device as soon as the first page you view on our website is displayed. As a result we infer consent for these and other cookies if you use our website.</p>
          <p><strong>4. No personal information in cookies</strong></p>
          <p>None of the cookies we use contain or pass personal, confidential, financial information or any other information that could be used to identify individual visitors to the website.</p>
          <p><strong>5. Your shopping basket and cookies</strong></p>
          <p>If you buy a product from our website we do use cookies to pass your contact details through the checkout process. But we never request or store any financial details in this way. Your financial details are only ever passed using an encrypted and secure format from our secure payment page to your own bank for processing.</p>
          <p><strong>6. Refusing cookies</strong></p>
          <p>Using your browser settings you are free to refuse cookies. However, for purely technical reasons this may cause errors when trying to use our website.</p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 3.0</p>
          <h2 id="cookie-policy-info-website-only">Cookie policy info website only</h2>
          <p><strong>1. What are cookies?</strong></p>
          <p>Cookies are small files containing a short string of numbers and letters placed automatically by a website into the cookie folder in your browser. Cookies are generally used to make a website easier and faster to use. The cookie recognises that a device is or has accessed the website and acts accordingly, (depending on what the cookie is designed to do). Mostly cookies perform mundane, but necessary tasks.</p>
          <p><strong>2. How do we use cookies?</strong></p>
          <p>We use typically cookies for the following purposes:</p>
          <p>
            <strong>2.1</strong>
            {' '}
            Ensure all parts of the website requested by visitors work correctly and appear quickly.
          </p>
          <p>
            <strong>2.2</strong>
            {' '}
            Alert us whenever a page on the website or the entire website is slow and not working at all.
          </p>
          <p>
            <strong>2.3</strong>
            {' '}
            Analyse how visitors use our website, so that we can continually improve it by adding more information and services and making it easier to navigate and use.
          </p>
          <p>
            <strong>2.4</strong>
            {' '}
            To remember you as a visitor.
          </p>
          <p>
            <strong>2.5</strong>
            {' '}
            To allow visitors to share our website content on social media and with friends.
          </p>
          <p><strong>3. Consent and cookies</strong></p>
          <p>Some cookies used by us are sent to your computer or mobile device as soon as the first page you view on our website is displayed. As a result we infer consent for these and other cookies if you use our website.</p>
          <p><strong>4. No personal information in cookies</strong></p>
          <p>None of the cookies we use contain or pass personal, confidential, financial information or any other information that could be used to identify individual visitors to the website.</p>
          <p><strong>5. Refusing cookies</strong></p>
          <p>Using your browser settings you are free to refuse cookies. However, for purely technical reasons this may cause errors when trying to use our website.</p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 2.0</p>
        </InfoPageContent>
      </Container>
    </InfoPageLayout>
  );
};
