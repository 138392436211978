import { FC } from 'react';
import companyAvatar from '../../../shared/assets/images/company-avatar.png';
import { TicketMessage } from '../../types';
import {
  Avatar, Content, Message, Root, Username,
} from './styled';
import { useUserContext } from '../../../auth';

export interface TicketMessageViewProps {
  item: TicketMessage;
}

export const TicketMessageView: FC<TicketMessageViewProps> = (props) => {
  const { item } = props;
  const { currentUser } = useUserContext();

  return (
    <Root>
      <Avatar src={item.from_user ? currentUser?.avatar?.image : companyAvatar} />
      <Content>
        <Username>{item.from_user ? currentUser?.username || 'You' : 'supoRRt'}</Username>
        <Message>{item.message}</Message>
      </Content>
    </Root>
  );
};
