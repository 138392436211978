import { ComponentType } from 'react';
import { JuniorIcon, MiddleIcon, SeniorIcon } from '../../ui';
import { CourseLevel } from '../types';

export const getLevelIcon = (level: CourseLevel | null = null): ComponentType | null => {
  if (level === CourseLevel.NOVICE) {
    return JuniorIcon;
  }
  if (level === CourseLevel.MEDIUM) {
    return MiddleIcon;
  }
  if (level === CourseLevel.PROFESSIONAL) {
    return SeniorIcon;
  }
  return null;
};
