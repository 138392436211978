import { FC, ReactNode } from 'react';
import {
  Content, Image, PreChildren, Root, Title,
} from './styled';

export interface PageTextProps {
  title?: ReactNode;
  image?: string;
  outline?: boolean;
  children?: ReactNode;
}

export const PageText: FC<PageTextProps> = (props) => {
  const {
    title, image, outline, children,
  } = props;

  return (
    <Root outline={outline}>
      <Content>
        {title && <Title>{title}</Title>}
        <PreChildren>{children}</PreChildren>
      </Content>
      {image && <Image src={image} />}
    </Root>
  );
};
