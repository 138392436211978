import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1096px;
  margin: 0 auto;
  padding: 160px 45px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #010046 100%);
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  color: #fff;
`;

export const Title = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  ${(p) => p.theme.typography.base};
  font-size: 56px;
  background: linear-gradient(180deg, #FFF 44.13%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 40px;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 80px;
  }
`;

export const Subtitle = styled.div`
margin-bottom: 10px;
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 156% */
  letter-spacing: -0.6px;
  text-transform: capitalize;
  ${(p) => p.theme.breakpoints.down('mobile')} {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 40px;
  }
`;

export const Paragrapth = styled.div`
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;

  margin-bottom: 24px;

  a {
    color: inherit;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 16px;
  }
`;

export const FaqWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 64px;
`;
