import { IStorage } from './types';

export class LocalStorage<KeyMap = { [key: string]: unknown }> implements IStorage<KeyMap> {
  namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  getFullKey(key: string): string {
    return `${this.namespace}/${key}`;
  }

  setItem = async <Key extends keyof KeyMap>(
    key: Key,
    value: KeyMap[Key] | null,
  ): Promise<void> => {
    this.precheck();
    if (value === null) {
      this.removeItem(key);
      return;
    }
    const valueStr = JSON.stringify(value);
    localStorage.setItem(this.getFullKey(key as string), valueStr);
  };

  getItem = async <Key extends keyof KeyMap>(
    key: Key,
  ): Promise<KeyMap[Key] | null> => {
    this.precheck();
    const data = localStorage.getItem(this.getFullKey(key as string));
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        return parsedData;
      } catch (e) {
        this.removeItem(key);
      }
    }

    return null;
  };

  removeItem = async <Key extends keyof KeyMap>(key: Key): Promise<void> => {
    this.precheck();
    localStorage.removeItem(this.getFullKey(key as string));
  };

  getAllKeys = async (): Promise<Array<string>> => {
    this.precheck();
    return Object.keys(localStorage).filter((x) => x.startsWith(`${this.namespace}/`));
  };

  clear = async (): Promise<void> => {
    const keys = await this.getAllKeys();
    keys.forEach((x) => {
      localStorage.removeItem(x);
    });
  };

  private precheck = () => {
    if (!localStorage) {
      throw new Error(
        'local storage is not supported by the current environment',
      );
    }
  };
}
