import { RequestOptions, apiClient } from '../../api';

export interface ToggleFavoriteData {
  id: number;
  is_favorite: boolean;
}

export const toggleFavorite = async (
  data: ToggleFavoriteData,
  meta: Partial<RequestOptions> = {},
): Promise<object> => {
  const {
    id, is_favorite,
  } = data;

  const response = await apiClient.request({
    path: `/api/v1/course/${id}/${is_favorite ? 'add-favorite' : 'remove-favorite'}/`,
    method: 'POST',
    withAuth: true,
    ...meta,
  });

  return response.data || {};
};
