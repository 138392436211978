import styled from 'styled-components';

export const Root = styled.div`
  width: 800px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const ErrorMessage = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: none;
  color: #f55;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
`;
