import { createContext } from 'react';
import { UserCourse } from '../../types';
import { RequestError } from '../../../api';

export interface StudyContextValue {
  userCourse: UserCourse | null;
  isLoading: boolean;
  error: Error | RequestError | null;
  reload: () => void;
  stepsOpen: number | null | undefined;
  handleOpen: (id: number) => void;
  handleOpenActiveSection: (id: number) => void;
}

export const StudyContext = createContext<StudyContextValue>(
  {} as StudyContextValue,
);
