import { Category } from './Category';

export enum CourseLevel {
  NOVICE = 'novice',
  MEDIUM = 'medium',
  PROFESSIONAL = 'professional',
  FOR_ANY = 'any',
}

export type CourseLessonType = 'text' | 'video' | 'results';

export type TQuestionsTest = {
  id: string;
  text: string;
  is_correct?: boolean;
};

export interface CourseLesson {
  id: number;
  type: CourseLessonType;
  name: string;
  duration: string;
  video_url?: string | null;
  content_text?: string | null;
  questions: TQuestionsTest[];
  skill?: number;
}

export interface CourseSection {
  id: number;
  name: string;
  steps: Array<CourseLesson>;
}

export interface Course {
  id: number;
  name: string;
  image?: string | null;
  description?: string;
  grade?: CourseLevel;
  duration?: string;
  rate?: number;
  badge?: string;
  categories?: Array<Category> | null;
  is_paid?: boolean | null;
  is_promoted?: boolean | null;
  is_started?: boolean | null;
  is_favorite?: boolean | null;
  is_published?: boolean | null;
  has_paid?: boolean | null;
  published_date?: string | null;
  price_gbp?: string | null;
  price_with_commission?: string | null;
  reviews_count?: number | null;
  user_finished_count?: number | null;
  user_started_count?: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_json?: Array<any> | null;
  css_config: null | {
    gradient?: string | null;
    color?: string | null;
  };
  sections?: Array<CourseSection>;
  supplier_avatar: {
    id: number | null;
    image: string | null;
    name: string | null;
  };
  supplier_description: string | null;
  syllabus: string[];
  you_will_learn: string | null;
  for_whom: string | null;
}
