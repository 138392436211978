import { FC, useEffect } from 'react';
import { Avatar } from '../../types';
import { useLoadAvatars } from '../../hooks';
import { List, ListItem } from './styled';

export interface AvatarListProps {
  current: Avatar['id'] | null;
  onSelect?: (avatar: Avatar) => void;
  onDoubleClick?: (avatar: Avatar) => void;
  onReady?: () => void;
  onRequestCustomAvatar?:()=>void
}

export const AvatarList: FC<AvatarListProps> = (props) => {
  const {
    current, onSelect, onRequestCustomAvatar, onDoubleClick, onReady,
  } = props;
  const { result } = useLoadAvatars();

  useEffect(() => {
    if (onReady && result) {
      onReady();
    }
  }, [result, onReady]);

  return (
    <List>
      {result?.data
        && result?.data.map((item) => (
          <ListItem
            key={item.id}
            src={item.image}
            selected={current === item.id}
            onClick={onSelect ? () => onSelect(item) : undefined}
            onDoubleClick={
              onDoubleClick ? () => onDoubleClick(item) : undefined
            }
          />
        ))}
      <ListItem
        src=""
        onClick={onRequestCustomAvatar}
      >
        request custom avatar
      </ListItem>
    </List>
  );
};
