import { FC, ReactNode } from 'react';
import { ContentWrapper, SupplierImage, TitleWrapper } from './styles';

export interface SupplierLogoBoardProps {
  image?: string | null;
  title?: ReactNode;
}

export const SupplierLogoBoard: FC<SupplierLogoBoardProps> = (props) => {
  const { image, title } = props;
  return (
    <ContentWrapper>
      {title && (
        <TitleWrapper>{title}</TitleWrapper>
      )}
      <SupplierImage src={image}>
        {!image && (
          <span>Not set</span>
        )}
      </SupplierImage>
    </ContentWrapper>
  );
};
