/* eslint-disable react/no-unknown-property */
import {
  FC, ReactNode, useMemo, useState,
} from 'react';
import Select, { StylesConfig, defaultTheme } from 'react-select';
// import { StateOption, stateOptions } from '../data';
import { TextInput } from '../TextInput';
import { ChevronBottomIcon } from '../../icons';
import { Root } from './styled';
import { BaseInputProps } from '../BaseInput';

const { colors } = defaultTheme;

// styled components

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: '#000000',
        color: '#03FBFD',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export type SelectInputOption = {
  label: string;
  value: string | null;
}

const selectStyles: StylesConfig<SelectInputOption, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 205,
    margin: 8,
    fontWeight: 400,
  }),
  input: () => ({
    fontWeight: 400,
    color: '#000',
  }),
  valueContainer: () => ({
    fontWeight: 400,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

export interface SelectInputProps extends BaseInputProps {
  value: SelectInputOption;
  options?: Array<SelectInputOption>;
  placeholder?: string
  onChange: (value: SelectInputOption) => void;
  plainValue?: boolean;
}

export const Selectinput: FC<SelectInputProps> = (props) => {
  const {
    value, placeholder = 'Select', options, onChange, plainValue, ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [selectValue, selectOnChange] = useMemo((): [SelectInputOption, SelectInputProps['onChange']] => {
    if (!plainValue) {
      return [value, onChange];
    }
    let option = value ? { value, label: value } : null;
    if (value && options && options.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      option = options.find((x) => x.value === value) || option;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [option, (newValue) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange(newValue?.value ?? null);
    }];
  }, [plainValue, value, options, onChange]);

  return (
    <Root dark={rest.color === 'primary'}>
      <Dropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        target={(
          <div onClick={() => setIsOpen((prev) => !prev)}>
            <TextInput
              {...rest}
              placeholder={placeholder}
              value={selectValue?.label || ''}
              readOnly
              endIcon={<ChevronBottomIcon onClick={() => setIsOpen((prev) => !prev)} />}
            />
          </div>
      )}
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          classNamePrefix="rselect"
          // isSearchable={false}
          onChange={(newValue) => {
            selectOnChange(newValue as SelectInputOption);
            setIsOpen(false);
          }}
          options={options}
          placeholder=""
          styles={selectStyles}
          tabSelectsValue={false}
          value={selectValue}
        />
      </Dropdown>
    </Root>
  );
};
