import { StyledObject } from 'styled-components';
import { ThemeTypography } from '../types';

const fontFamily = '\'Russo One\', sans-serif';
const fontFamilySecondary = '\'Play\', sans-serif';

const base: StyledObject<object> = {
  fontFamily,
  fontSize: '14px',
  fontWeight: 400,
};

export const typography: ThemeTypography = {
  fontFamily,
  fontFamilySecondary,
  base,
  headerXL: {
    ...base,
    fontSize: '64px',
    fontWeight: 600,
    lineHeight: '110%',
  },
  headerLG: {
    ...base,
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '110%',
  },
  headerMD: {
    ...base,
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '120%',
  },
  headerSM: {
    ...base,
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '120%',
  },
  headerXS: {
    ...base,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
  },
  textLGBold: {
    ...base,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '140%',
  },
  textMDBold: {
    ...base,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '140%',
  },
  textSMBold: {
    ...base,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '140%',
  },
  textXSBold: {
    ...base,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '140%',
  },
  textLG: {
    ...base,
    fontSize: '18px',
    lineHeight: '140%',
  },
  textMD: {
    ...base,
    fontSize: '16px',
    lineHeight: '140%',
  },
  textSM: {
    ...base,
    fontSize: '14px',
    lineHeight: '140%',
  },
  textXS: {
    ...base,
    fontSize: '12px',
    lineHeight: '140%',
  },
  captureSMBold: {
    ...base,
    fontSize: '10px',
    lineHeight: '120%',
    fontWeight: 600,
  },
  captureSM: {
    ...base,
    fontSize: '10px',
    lineHeight: '120%',
  },
};
