import { FC, ReactNode } from 'react';
import {
  Root, ShapeContent, ShapeFirst, ShapeSecond,
} from './styled';

export interface PageTwoShapePanelsTextProps {
  text1: ReactNode;
  text2: ReactNode;
}

export const PageTwoShapePanelsText: FC<PageTwoShapePanelsTextProps> = (props) => {
  const { text1, text2 } = props;

  return (
    <Root>
      <ShapeFirst>
        <ShapeContent>
          {text1}
        </ShapeContent>
      </ShapeFirst>
      <ShapeSecond>
        <ShapeContent>
          {text2}
        </ShapeContent>
      </ShapeSecond>
    </Root>
  );
};
