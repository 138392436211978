import styled from 'styled-components';

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
`;

export const Total = styled.div`
  position: absolute;
  inset: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 32.4px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;


  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 24px;
    line-height: 38.4px;
    letter-spacing: -0.32px;
  }
`;
