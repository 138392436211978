import {
  FC, useCallback, useEffect,
} from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Container, InfoPageLayout } from '../../../shared';
import {
  Button, FormTextInput,
} from '../../../ui';
import { useCreateTicketRequest } from '../../hooks';
import {
  Buttons,
  HeaderHint,
  HeaderRow,
  InputWrapper,
  LabelHint, LabelRow, LabelText, Title,
} from './styled';
import { TicketAttachmentInput } from '../../components';

const schema = yup
  .object({
    subject: yup.string().required('Required'),
    message: yup.string().required('Required'),
    attachments: yup.array().optional().nullable().default([]),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const RequestCourseScreen: FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { result, isLoading, request } = useCreateTicketRequest();

  const onSubmit = useCallback(
    (data: FormData) => {
      const { subject, message, attachments } = data;
      request({
        subject,
        message,
        attachments: attachments || undefined,
      });
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      toast('Thank you for your request', {
        type: 'success',
      });
      navigate('/courses');
    }
  }, [result, navigate]);

  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradientCenter">
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <HeaderRow>
            <Title>Request</Title>
            <HeaderHint>
              We will add the course as soon as we can
              <br />
              You will be notified when your request is completed
            </HeaderHint>
          </HeaderRow>
          <InputWrapper>
            <LabelRow>
              <LabelText>please, tell us you desired topic </LabelText>
              <LabelHint>
                State the field and theme, eg IT, Design, etc.
              </LabelHint>
            </LabelRow>
            <FormTextInput
              control={control}
              name="subject"
              fullWidth
              color="primary"
            />
          </InputWrapper>
          <InputWrapper>
            <LabelRow>
              <LabelText>add more details</LabelText>
              <LabelHint>
                Clarify, please. Tell us more about what you want to learn. The
                more details the better.
              </LabelHint>
            </LabelRow>
            <FormTextInput
              control={control}
              name="message"
              multiline
              fullWidth
              color="primary"
            />
          </InputWrapper>
          <TicketAttachmentInput
            control={control}
            name="attachments"
          />
          <Buttons>
            <Button size="lg" type="submit" disabled={isLoading}>Submit</Button>
          </Buttons>
        </form>
      </Container>
    </InfoPageLayout>
  );
};
