import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ShapeButton, useBreakpoint } from '../../../ui';
import { useAuthContext, useUserContext } from '../../../auth';
import {
  Avatar, Logout, UserInfo, Wrapper,
} from './styled';

export const UserMenu: FC = () => {
  const { authorized, logout } = useAuthContext();
  const { currentUser } = useUserContext();
  const isDesktop = useBreakpoint('up', 'md');

  if (authorized) {
    if (!currentUser) {
      return null;
    }
    return (
      <Wrapper>
        <UserInfo
          as={Link}
          to="/account"
          avatar={Boolean(currentUser?.avatar?.image)}
        >
          {currentUser?.username || currentUser?.email}
          {currentUser?.avatar?.image && (
          <Avatar src={currentUser.avatar.image} />
          )}
        </UserInfo>
        <Logout onClick={logout}>Logout</Logout>
      </Wrapper>
    );
  }

  return (
    <ShapeButton
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as={Link as any}
      to="/identify"
      size={isDesktop ? 'xl' : 'sm'}
      hoverEffect="glitch"
    >
      Signup/Login
    </ShapeButton>
  );
};
