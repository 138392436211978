import * as React from 'react';
import type { SVGProps } from 'react';

const SvgChevronBottom = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.293 7.293 1.414 1.414L12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronBottom;
