import { RequestOptions, apiClient } from '../../api';
import { AuthData } from '../types';

export interface RefreshTokenData {
  refresh: string;
}

export const refreshToken = async (
  data: RefreshTokenData,
  meta: Partial<RequestOptions> = {},
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: '/api/v1/token/refresh/',
    method: 'POST',
    body: data,
    withAuth: false,
    ...meta,
  });

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh || null,
  };
};
