import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { AuthLayout } from '../../layouts';
import { useSendResetPasswordLink, useSignin } from '../../hooks';
import {
  Button, FormTextInput, Stack, useBreakpoint,
} from '../../../ui';
import {
  Content,
  ContentTop,
  FieldsWrapper,
  ForgotLink,
  ForgotMessage,
  InputWrapper,
  Label,
  Row,
  Title,
} from './styled';
import { GoogleAuthButtonSDK } from '../../components/GoogleAuthButtonSDK';
import { FacebookAuthButtton } from '../../components';

const schema = yup
  .object({
    email: yup.string().required('Required'),
    password: yup.string().required('Required'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const SigninScreen: FC = () => {
  const { state } = useLocation();
  const redirect = state?.itemId ? `../courses/${state.itemId}` : undefined;

  const { isLoading, request } = useSignin({ redirect });
  const isDesktop = useBreakpoint('up', 'md');
  const {
    result: forgotResult,
    isLoading: forgotIsLoading,
    request: forgotRequest,
  } = useSendResetPasswordLink();

  const {
    control, getValues, setValue, setError, handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data);
    },
    [request],
  );

  const forgotPassword = useCallback(() => {
    const values = getValues();
    if (values.email) {
      forgotRequest({
        email: values.email,
      });
    } else {
      setError('email', {
        message: 'Required',
        type: 'required',
      });
    }
  }, [getValues]);

  const handleChangeEmail = (e) => setValue('email', e.toLowerCase());

  return (
    <AuthLayout>
      <Content as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <ContentTop>
          <Title>
            <Link to="../signup" state={state}>
              Create account
            </Link>
            <span>|</span>
            Login
          </Title>
        </ContentTop>
        <FieldsWrapper>
          <Row>
            <Label>Email</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="email"
                fullWidth
                onChangeText={handleChangeEmail}
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Password</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="password"
                type="password"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Use other services</Label>
            <Stack
              direction={isDesktop ? 'row' : 'column'}
              fullWidth
              justifyContent="space-around"
              alignItems="center"
            >
              <GoogleAuthButtonSDK isLogin />
              <FacebookAuthButtton isLogin />
            </Stack>
          </Row>
        </FieldsWrapper>
        <Button
          size={isDesktop ? 'lg' : 'md'}
          type="submit"
          disabled={isLoading}
        >
          Submit
        </Button>
        {forgotResult ? (
          <ForgotMessage>
            !we’ve sent you an email with a link to reset your password!
          </ForgotMessage>
        ) : (
          <ForgotLink onClick={!forgotIsLoading ? forgotPassword : undefined}>
            Forgot your password?
          </ForgotLink>
        )}
      </Content>
    </AuthLayout>
  );
};
