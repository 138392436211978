import { FC } from 'react';
import { Root } from './styled';
import { FadeIn, Header } from '../../../shared';
import { GlitchWrapper } from '../../../ui';

export const NotFoundScreen: FC = () => {
  return (
    <Root>
      <Header position="absolute" />
      <FadeIn>
        <GlitchWrapper playMode="always" shake>
          <div>Coming soon</div>
        </GlitchWrapper>
      </FadeIn>
    </Root>
  );
};
