import { FC } from 'react';
import { Settings } from 'react-slick';
import { StyledCarousel } from './styled';

export interface CarouselProps extends Settings {}

export const Carousel: FC<CarouselProps> = (props) => {
  return (
    <StyledCarousel {...props} />
  );
};
