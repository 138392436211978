import { useCallback, useEffect, useState } from 'react';
import { authService } from '../services';
import { signinFacebook } from '../network';
import { useAuthContext } from '../containers';

export interface UseSigninFacebookOptions {
  redirect?: string | null;
}

export const useSigninFacebook = (options: UseSigninFacebookOptions = {}): {
  result: boolean | null,
  isLoading: boolean,
  error: Error | null,
  request: (...args: Parameters<typeof signinFacebook>) => void,
} => {
  const { redirect = null } = options;
  const { setAuthorizedRedirect } = useAuthContext();
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (...args: Parameters<typeof signinFacebook>) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await signinFacebook(...args);
        await authService.setAuthData(response);
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [],
  );

  useEffect(() => {
    if (redirect) {
      setAuthorizedRedirect(redirect);
      return () => {
        setAuthorizedRedirect(null);
      };
    }
    return undefined;
  }, [redirect]);

  return {
    result,
    isLoading,
    error,
    request,
  };
};
