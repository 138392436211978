import styled from 'styled-components';

export const Root = styled.div`
  display: block;
  width: 100%;
  aspect-ratio: ${1065 / 399};
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;

  & img {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 225px;
  }

  &:hover {
    transform: scale(1.02);
  }
`;
