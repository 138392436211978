import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  StudyPreviewContext,
  StudyPreviewContextValue,
} from './StudyPreviewContext';
import { useRequestState } from '../../../common';
import { loadPreviewCourse } from '../../network';
import { Course } from '../../../catalog';

export interface StudyPreviewProviderProps {
  courseId: string | number;
  children: ReactNode;
}

export const StudyPreviewProvider: FC<StudyPreviewProviderProps> = (props) => {
  const { courseId, children } = props;

  const {
    result: course,
    error,
    isLoading,
    setResult: setCourse,
    setError,
    setIsLoading,
  } = useRequestState<Course>();

  const [stepsOpen, setStepsOpen] = useState<number | null | undefined>(null);

  const handleOpen = useCallback(
    (id: number) => {
      if (id && stepsOpen === id) {
        setStepsOpen(null);
      } else {
        setStepsOpen(id);
      }
    },
    [stepsOpen],
  );

  const loadCourse = useCallback(async () => {
    if (!courseId) {
      setError(new Error('course ID is not provided'));
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await loadPreviewCourse(courseId);
      if (!response) {
        setCourse(null);
        setError(new Error('course not found'));
      } else {
        setCourse(response);
      }
    } catch (e) {
      setCourse(null);
      setError(e);
    }
    setIsLoading(false);
  }, [courseId]);

  const handleOpenActiveSection = useCallback((id?: number) => {
    if (id) {
      setStepsOpen(id);
    }
  }, []);

  useEffect(() => {
    loadCourse();
  }, [loadCourse]);

  const contextValue: StudyPreviewContextValue = useMemo(() => {
    return {
      stepsOpen,
      handleOpenActiveSection,
      handleOpen,
      course,
      isLoading,
      error,
      reload: loadCourse,
    };
  }, [course, isLoading, error, loadCourse, stepsOpen, handleOpen]);

  return (
    <StudyPreviewContext.Provider value={contextValue}>
      {children}
    </StudyPreviewContext.Provider>
  );
};
