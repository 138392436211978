import styled from 'styled-components';

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 130% */
  letter-spacing: -0.72px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFF 18.58%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Content = styled.div`
  color: #fff;
`;

export const Subject = styled.div`
  padding: ${(p) => p.theme.spacing.get(3, 6)};
  border: 3px solid #03FBFD;
  border-radius: 3px;
  background: rgba(253, 3, 183, 0.08);
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.36px;
  text-transform: uppercase;
`;

export const Chat = styled.div`
  border: 3px solid #03FBFD;
  border-radius: 3px;
`;

export const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 400px;
  padding: ${(p) => p.theme.spacing.get(6, 6)};
  gap: ${(p) => p.theme.spacing.get(5)};
`;

export const InputWrapper = styled.div`
  border-top: 3px solid #03FBFD;
  width: 100%;
`;

export const Input = styled.textarea`
  display: block;
  width: 100%;
  height: 70px;
  padding: ${(p) => p.theme.spacing.get(5, 6)};
  outline: none;
  background: rgba(0, 255, 209, 0.15);
  color: rgba(255,255,255,0.4);

  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
