import styled from 'styled-components';

export const ChartWrapper = styled.div`
  margin-bottom: 48px;
  width: 240px;
  margin-left: auto;
  margin-right: auto;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 430px;
    margin-bottom: 78px;
  }
`;

export const SkillsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 38px;
  }
`;
