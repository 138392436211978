import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Review } from '../types';

export interface LoadReviewsFilters {
}

export interface LoadReviewsData extends RequestListParams<LoadReviewsFilters> {}

export const loadReviews = async (
  courseId: string | number,
  data: LoadReviewsData = {},
): Promise<RequestPagingResponse<Review>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: `/api/v1/course/${courseId}/reviews/`,
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Review>(response.data);
};
