export const getYoutubeId = (url: string): string | null => {
  let id = '';
  const parts = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (parts[2] !== undefined) {
    const ids = parts[2].split(/[^0-9a-z_-]/i);
    // eslint-disable-next-line prefer-destructuring
    id = ids[0];
  } else {
    id = url;
  }
  return id || null;
};
