import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Skill } from '../types';

export interface LoadSkillsFilters {}

export interface LoadSkillsData
  extends RequestListParams<LoadSkillsFilters> {}

export const loadSkills = async (
  data: LoadSkillsData = {},
): Promise<RequestPagingResponse<Skill>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/course/skills/',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Skill>(response.data);
};
