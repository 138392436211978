import { ReactNode } from 'react';
import {
  ButtonContent,
  Root,
  ButtonShape,
  ButtonText,
  RootProps,
} from './styled';
import { GlitchWrapper } from '../GlitchWrapper';
import { FCWithAs } from '../../types';

export interface ShapeButtonProps extends RootProps {
  children?: ReactNode;
  onClick?: () => void;
  icon?: ReactNode;
}

export const ShapeButton: FCWithAs<'button', ShapeButtonProps> = ({
  as,
  color = 'primary',
  size = 'md',
  hoverEffect = 'gradient',
  background = true,
  glow = false,
  disabled = false,
  children,
  onClick,
  icon,
  ...rest
}) => {
  const content = (
    <Root
      as={as as never}
      color={color}
      size={size}
      hoverEffect={hoverEffect}
      background={background}
      glow={glow}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <ButtonShape />
      <ButtonContent>
        <ButtonText>{children}</ButtonText>
        {icon && icon}
      </ButtonContent>
    </Root>
  );

  if (hoverEffect === 'glitch') {
    return (
      <GlitchWrapper key={[color, size].join('-')} playMode="hover" inline>
        {content}
      </GlitchWrapper>
    );
  }

  return content;
};
