import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { UserCourse } from '../types';

export interface LoadUserCoursesFilters {
}

export interface LoadUserCoursesData extends RequestListParams<LoadUserCoursesFilters> {}

export const loadUserCourses = async (
  data: LoadUserCoursesData = {},
): Promise<RequestPagingResponse<UserCourse>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/user-course/',
    method: 'GET',
    params,
  });

  return apiResponsePagingMapper<UserCourse>(response.data);
};
