import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 520px;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    
    justify-content: center;
    align-items: center;

  }
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 12px;
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 62.4px; /* 260% */
  letter-spacing: -0.36px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 6px;
  width: 100%;
`;

export const Label = styled.div`
  width: 105px;
  flex-shrink: 0;
  color: #03FBFD;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  
  ${(p) => p.theme.breakpoints.up('md')} {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
`;
