import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Category } from '../types';

export interface LoadCategoryFilters {}

export interface LoadCategoryData
  extends RequestListParams<LoadCategoryFilters> {}

export const loadCategories = async (
  data: LoadCategoryData = {},
): Promise<RequestPagingResponse<Category>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/category/',
    method: 'GET',
    params,
  });

  return apiResponsePagingMapper<Category>(response.data);
};
