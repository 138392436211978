import {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  EyeIcon,
  EyeNoIcon,
  FormSelectInput,
  FormTextInput,
  useBreakpoint,
} from '../../../../ui';
import {
  FormBody, FormWrapper, InputWrapper, Label, Row,
} from '../styled';
import { StepProps } from '../types';
import { useCountriesSelectOptions, useSignup } from '../../../hooks';
import { SignupData } from '../../../network';
import { RequestError } from '../../../../api';
import { ShapeCard } from '../../../../shared';

const schema = yup
  .object({
    email: yup.string().email('Invalid email').required('Required'),
    password: yup
      .string()
      .min(8, 'Min 8 symbols')
      .matches(
        /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#${}~`\][()|><^/\\.,-_+=:;'"!%*?&])/,
        'At least one uppercase letter, one lowercase letter, one number and one special character',
      )
      .notOneOf([yup.ref('email')], 'Should not look like an email')
      .required('Required'),
    supplier: yup.object({
      organization_name: yup.string().required('Required'),
      country: yup.string().required('Required'),
      registration_number: yup.string().required('Required'),
      vat_number: yup.string().optional().nullable(),
    }),
  })
  .required();

export const Step1Company: FC<StepProps> = (props) => {
  const { data, onSubmit } = props;
  const isDesktop = useBreakpoint('up', 'md');
  const {
    result, isLoading, error, request,
  } = useSignup();
  const submitedDataRef = useRef<Partial<SignupData> | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const countries = useCountriesSelectOptions();

  const toggleShowPassword = useCallback(() => {
    setShowPassword((x) => !x);
  }, []);

  const {
    setValue, control, handleSubmit, setError,
  } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: data as any,
    resolver: yupResolver(schema),
  });

  const check = useCallback(
    (formData: Partial<any>) => {
      let username = formData.supplier.organization_name;
      username = username.replaceAll(/\s/g, '_');
      username = username.replaceAll(/[^\w.@+-]/g, '.');

      // eslint-disable-next-line no-param-reassign
      formData.username = username;
      submitedDataRef.current = formData;
      // Так как для успешной авторизации требуются еще другие поля,
      // Мы можем использовать этот запрос для валидации полей
      request(
        {
          email: formData.email,
          username,
          password: formData.password,
          password_confirm: '~',
        } as SignupData,
        {
          silent: true,
        },
      );
    },
    [request],
  );

  const handleChangeEmail = (e) => setValue('email', e.toLowerCase());

  useEffect(() => {
    if (
      error
      && error instanceof RequestError
      && error.statusCode === 400
      && error.data
    ) {
      let ok = true;
      if (error.data?.username && error.data?.username.length > 0) {
        ok = false;
        let message = error.data.username[0];
        if (
          typeof message === 'string'
          && message.toLowerCase().includes('exists')
        ) {
          message = 'Company name already exist';
        } else {
          message = 'Company name invalid';
        }
        setError('supplier.organization_name', {
          message,
        });
      }
      if (error.data?.email && error.data?.email.length > 0) {
        ok = false;
        setError('email', {
          message: error.data.email[0],
        });
      }
      if (error.data?.password && error.data?.password.length > 0) {
        ok = false;
        setError('password', {
          message: error.data.password[0],
        });
      }
      if (!ok) {
        submitedDataRef.current = null;
        return;
      }
    }
    if (submitedDataRef.current && (error || result)) {
      onSubmit(submitedDataRef.current);
    }
  }, [result, error]);

  const ToggleIcon = showPassword ? EyeNoIcon : EyeIcon;

  return (
    <ShapeCard>
      <FormWrapper as="form" onSubmit={handleSubmit(check)} noValidate>
        <FormBody>
          <Row>
            <Label>Company name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="supplier.organization_name"
                fullWidth
                control={control}
                hint="this will be your username"
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Email</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="email"
                type="email"
                onChangeText={handleChangeEmail}
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Password</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="password"
                type={showPassword ? 'string' : 'password'}
                fullWidth
                control={control}
                endIcon={<ToggleIcon onClick={toggleShowPassword} />}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Country</Label>
            <InputWrapper>
              <FormSelectInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                options={countries}
                plainValue
                name="supplier.country"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Registration number</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="supplier.registration_number"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>VAT number</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError // ={!isDesktop}
                name="supplier.vat_number"
                fullWidth
                control={control}
                hint="this field is not required"
              />
            </InputWrapper>
          </Row>
        </FormBody>
        <Button
          size={isDesktop ? 'lg' : 'md'}
          type="submit"
          disabled={isLoading}
        >
          save and go next
        </Button>
      </FormWrapper>
    </ShapeCard>
  );
};
