import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initGoogleStcript } from './googleInitScript';
import { SocialButton } from '../SocialButton';
import { GoogleIcon } from '../../../ui';
import { config } from '../../../../config';
import { useSigninGoogle } from '../../hooks';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleGoogleAuthResponse?: (data: any) => void;
  }
}

export const GoogleAuthButtonSDK: FC<{isLogin?: boolean}> = ({ isLogin }) => {
  const { state } = useLocation();

  // eslint-disable-next-line no-nested-ternary
  const redirect = state?.itemId
    ? `../courses/${state.itemId}`
    : isLogin
      ? '/account'
      : '/account/select-avatar';

  const { request, isLoading } = useSigninGoogle({
    redirect,
  });

  useEffect(() => {
    window.handleGoogleAuthResponse = (data) => {
      if (data.credential) {
        request({
          access_token: data.credential,
        });
      }
    };

    if (window.google) {
      try {
        window.google.accounts.id.renderButton(
          document.getElementById('google_auth_button_wrapper'),
          {
            theme: 'outline',
            size: 'large',
            click_listener: window.handleGoogleAuthResponse,
          },
        );
      } catch (e) {
        // pass
      }
    } else {
      initGoogleStcript();
    }

    return () => {
      window.handleGoogleAuthResponse = undefined;
    };
  }, [request]);

  return (
    <SocialButton text="Google" icon={<GoogleIcon />} disabled={isLoading}>
      <div id="google_auth_button_wrapper">
        <div
          id="g_id_onload"
          data-client_id={config.googleAppId}
          data-callback="handleGoogleAuthResponse"
          data-auto_prompt="false"
        />
        <div className="g_id_signin" data-type="standard" />
      </div>
    </SocialButton>
  );
};
