import { FC, ReactNode } from 'react';
import { Items, Root, Title } from './styled';

export interface PageFeaturesProps {
  title: ReactNode;
  children: ReactNode;
}

export const PageFeatures: FC<PageFeaturesProps> = (props) => {
  const { title, children } = props;

  return (
    <Root>
      <Title>{title}</Title>
      <Items>
        {children}
      </Items>
    </Root>
  );
};
