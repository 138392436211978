import { styled } from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end; 

  ${(p) => p.theme.breakpoints.up('md')} {
    align-items: center;
    padding: 12px 18px;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    align-items: center;
  }
`;

export const ContentTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: 12px;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -24px;
  ${(p) => p.theme.typography.base};
  font-size: 26px;
  line-height: 30.4px; /* 116.923% */
  letter-spacing: -0.39px;
  text-transform: uppercase;
  color: #FFFFFF;

  & > a, & > span {
    color: #C1C1C1;
    font-size: 16px;
    letter-spacing: -0.24px;
  }
  & > span {
    display: none;
  }
  & > a {
    text-decoration: none;
    transition: color .3s ease-in-out;
    &:hover {
      color: #FFFFFF;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 0.5em;
    margin-bottom: -12px;
    font-size: 40px;
    line-height: 62.4px;
    letter-spacing: -0.72px;
    & > span {
      display: unset;
    }
    & > a, & > span {
      font-size: 24px;
      letter-spacing: -0.24px;
    }
  }
`;

export const ForgotLink = styled.div`
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 62.5% */
  font-variant: all-small-caps;
  letter-spacing: 0.32px;
  color: #FFF;
  cursor: pointer;
`;

export const ForgotMessage = styled.div`
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 62.5% */
  font-variant: all-small-caps;
  letter-spacing: 0.32px;
  color: #FFF;
`;

export const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 6px;
  width: 100%;
  ${(p) => p.theme.breakpoints.down('mobile')} {
    flex-direction: column;
  }
`;

export const Label = styled.div`
  width: 105px;
  flex-shrink: 0;
  color: #03FBFD;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px; /* 200% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  
  ${(p) => p.theme.breakpoints.up('md')} {
    width: 140px;
    margin-left: auto;
    margin-right: auto;
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
`;
