import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Skill } from '../types';

export interface LoadSupplierCoursesFilters {}

export interface LoadSupplierCoursesData
    extends RequestListParams<LoadSupplierCoursesFilters> {}

export const loadSupplierCourses = async (
  data: LoadSupplierCoursesData = {},
): Promise<RequestPagingResponse<Skill>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/course/supplier-courses/',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Skill>(response.data);
};
