import { FC } from 'react';
import { ProfileLayout } from '../../layouts';
import { Container, FadeIn } from '../../../shared';
import { useLoadSkills } from '../../hooks';
import { SkillProgressBar, SkillsDonutChart } from '../../components';
import { ChartWrapper, SkillsList } from './styled';

export const ProfileSkillsScreen: FC = () => {
  const {
    result,
  } = useLoadSkills();
  return (
    <ProfileLayout bodyBg="gradient">
      <FadeIn active={result !== null}>
        <Container>
          <ChartWrapper>
            <SkillsDonutChart
              items={result?.data || []}
            />
          </ChartWrapper>
          <SkillsList>
            {result?.data?.map((x) => (
              <SkillProgressBar item={x} />
            ))}
          </SkillsList>
        </Container>
      </FadeIn>
    </ProfileLayout>
  );
};
