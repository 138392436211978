import {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { AuthLayout } from '../../layouts';
import { useSignin, useSignup } from '../../hooks';
import { SignupData } from '../../network';
import { SignupStep1 } from './Step1';
import { SignupStep2 } from './Step2';
import { SignupStep3 } from './Step3';

export const SignupScreen: FC = () => {
  const sentDataRef = useRef<Partial<SignupData> | null>(null);
  const [data, setData] = useState<Partial<SignupData>>({});
  const [step, setStep] = useState(0);
  const { result, isLoading, request } = useSignup();
  const { isLoading: signinIsLoading, request: signin } = useSignin();

  const handleNext = useCallback(
    (newData: Partial<SignupData>) => {
      if (step < 2) {
        setData(newData);
        setStep(step + 1);
      } else {
        sentDataRef.current = newData;
        request(newData as SignupData);
      }
    },
    [step],
  );

  const handleBack = useCallback(() => {
    setStep((x) => (x > 0 ? x - 1 : 0));
  }, []);

  useEffect(() => {
    if (result) {
      if (sentDataRef.current) {
        signin({
          email: sentDataRef.current.email || '',
          password: sentDataRef.current.password || '',
        });
      }
    }
  }, [result, signin]);

  return (
    <AuthLayout>
      {step === 0 && <SignupStep1 data={data} onSubmit={handleNext} />}
      {step === 1 && (
        <SignupStep2 data={data} onSubmit={handleNext} onBack={handleBack} />
      )}
      {step === 2 && (
        <SignupStep3
          data={data}
          onSubmit={handleNext}
          onBack={handleBack}
          loading={isLoading || signinIsLoading}
        />
      )}
    </AuthLayout>
  );
};
