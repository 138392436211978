import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Course } from '../../types';
import {
  HeartFilledIcon,
  RatingView,
  ShapeButton,
  Tag,
  TimeIcon,
  useBreakpoint,
} from '../../../ui';
import courseDefaultImage from '../../../shared/assets/images/course-default.png';
import {
  Actions,
  Content,
  Description,
  ItemImage,
  Name,
  RatingWrapper,
  Reviews,
  Root,
  Tags,
} from './styled';
import { getLevelIcon } from '../../utils';
import { useDuration } from '../../hooks';
import { FavouriteActionWrapper } from '../FavouriteActionWrapper';

export interface CourseHeaderProps {
  item: Course;
  isPreview?: boolean;
  startHandler?: () => void;
  buyHandler?: () => void;
  startIsLoading?: boolean;
  buyIsLoading?: boolean;
  started?: boolean;
}

export const CourseHeader: FC<CourseHeaderProps> = (props) => {
  const {
    item,
    isPreview,
    startHandler,
    buyHandler,
    startIsLoading,
    buyIsLoading,
    started,
  } = props;
  const isDesktop = useBreakpoint('up', 'md');
  const duration = useDuration(item.duration || null);

  const LevelIcon = getLevelIcon(item.grade);

  return (
    <Root>
      <Content>
        <RatingWrapper>
          <RatingView value={item.rate} />
          {item.reviews_count && (
            <Reviews>
              Reviews (
              {item.reviews_count}
              )
            </Reviews>
          )}
        </RatingWrapper>
        <Tags>
          {duration && <Tag startIcon={<TimeIcon />}>{duration}</Tag>}
          <Tag startIcon={LevelIcon ? <LevelIcon /> : null}>{item.grade}</Tag>
          {item.rate && item.rate >= 4.5 && <Tag>Top rate</Tag>}
        </Tags>
        <Name>{item.name}</Name>
        <Description>{item.description}</Description>
        <Actions>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isPreview ? (
            <ShapeButton
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              as={Link as any}
              to={`/courses/${item.id}/preview/`}
              glow
              color="gradient"
              size={isDesktop ? 'lg' : 'xs'}
            >
              {item.price_gbp
              && item.price_gbp !== '0.00'
              && item.price_with_commission
                ? `Start £${item.price_with_commission}`
                : 'Start free'}
            </ShapeButton>
          ) : (!item?.is_paid || (item?.is_paid && item?.has_paid))
            && started ? (
              <ShapeButton
                disabled={startIsLoading}
                onClick={startHandler}
                glow
                color="gradient"
                size={isDesktop ? 'lg' : 'xs'}
              >
                {started ? 'Continue' : 'Start'}
              </ShapeButton>
            ) : (
              <ShapeButton
                onClick={buyHandler}
                disabled={buyIsLoading}
                glow
                color="gradient"
                size={isDesktop ? 'lg' : 'xs'}
              >
                {item.price_gbp
              && item.price_gbp !== '0.00'
              && item.price_with_commission
                  ? `Start £${item.price_with_commission}`
                  : 'Start free'}
              </ShapeButton>
            )}

          <FavouriteActionWrapper item={item}>
            {isPreview
              ? () => (
                <ShapeButton size={isDesktop ? 'lg' : 'xs'}>Save</ShapeButton>
              )
              : (toggle) => (
                <ShapeButton
                  size={isDesktop ? 'lg' : 'xs'}
                  onClick={toggle}
                  icon={<HeartFilledIcon isSaved={item?.is_favorite} />}
                >
                  {item.is_favorite ? 'Saved' : 'Save'}
                </ShapeButton>
              )}
          </FavouriteActionWrapper>
        </Actions>
      </Content>
      <ItemImage src={item.image || courseDefaultImage} />
    </Root>
  );
};
