import { PlayModes, PowerGlitchOptions } from 'powerglitch';
import { FC, ReactNode } from 'react';
import { useGlitch } from '../../hooks';
import { Root } from './styled';

export interface GlitchWrapperProps extends Partial<Omit<PowerGlitchOptions, 'createContainers' | 'html' | 'playMode' | 'shake'>> {
  inline?: boolean;
  shake?: boolean;
  playMode?: PlayModes;
  className?: string;
  children?: ReactNode;
}

export const GlitchWrapper: FC<GlitchWrapperProps> = ({
  inline = true,
  playMode = 'always',
  shake = false,
  className,
  children,
  ...options
}) => {
  const glitch = useGlitch({
    ...options,
    shake: shake ? undefined : false,
    playMode,
    createContainers: false,
  });

  return (
    <Root ref={glitch.ref} className={className} inline={inline}>
      {children}
    </Root>
  );
};
