import { RequestOptions, apiClient } from '../../api';
import { User } from '../types';

export const updateSupplier = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  meta: Partial<RequestOptions> = {},
): Promise<User> => {
  const response = await apiClient.request({
    path: '/api/v1/accounts/supplier/',
    method: 'POST',
    body: data,
    withAuth: true,
    ...meta,
  });

  return response.data;
};
