import { styled } from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  padding: 31px 10px;
  border-radius: 3px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  background: linear-gradient(180deg, rgba(230, 230, 230, 0.00) 0%, #AD06E7 100%);
  transform: scale(1);
  transition: transform .3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 80px 24px;

    &:hover {
      transform: scale(1.02);
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 48px 24px 68px;
  }
`;

export const Title = styled.div`
  ${(p) => p.theme.typography.base};
  text-align: center;
  font-size: 12px;
  color: #FFF;
  letter-spacing: -0.18px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    line-height: 64px;
    font-size: 20px;
  }
`;

export const Content = styled.div`
  padding: 0 24px;
  color: #D0D0D0;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.32px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    line-height: 24px; /* 150% */
    font-size: 16px;
  }
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;
