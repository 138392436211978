/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import { FC, useMemo } from 'react';
import { Container, InfoPageLayout } from '../../../shared';
import { InfoPageContent } from '../../components';
import { useLoadContentBlock } from '../../../cms/hooks';
import { PAGE } from '../../../cms/constants';

export const TermsAndConditionsScreen: FC = () => {
  const { result } = useLoadContentBlock(PAGE.TERMS_AND_CONDITION.MAIN);

  const content = useMemo(
    () => (result?.length
      ? result?.find((item) => item?.block === PAGE.TERMS_AND_CONDITION.CONTENT)
      : null),
    [result],
  );
  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradient">
      <Container>
        {content && (
          <InfoPageContent
            dangerouslySetInnerHTML={{ __html: content?.content }}
          />
        )}
      </Container>
    </InfoPageLayout>
  );
};
