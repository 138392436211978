import { FC } from 'react';
import { Footer, Header, Logo } from '../../../shared';
import {
  Content,
  FooterWrapper,
  HeaderWrapper,
  LogoWrapper,
  MainScreen,
  Menu,
  MenuItem,
  Root, SpecialOfferWrapper,
} from './styled';
import { useBreakpoint } from '../../../ui';
import { useUserContext } from '../../../auth';
import { SpecialOffer } from '../../componets';

export const HomeScreen: FC = () => {
  const { currentUser } = useUserContext();
  const isDesktop = useBreakpoint('up', 'md');

  return (
    <Root>
      <MainScreen>
        <HeaderWrapper>
          <Header logo={false} />
        </HeaderWrapper>
        <Content>
          <Menu>
            {currentUser && !currentUser?.is_supplier && (
              <MenuItem to="/account/profile/courses">Resume</MenuItem>
            )}
            <MenuItem to="/categories">Courses</MenuItem>
            <MenuItem to="/account">Profile</MenuItem>
            <MenuItem to="/about" small>
              About
            </MenuItem>
            <MenuItem to="/account/support" small>
              Support
            </MenuItem>
            {!currentUser?.is_supplier && (
              <MenuItem to="/partner" wide>
                Become a partner
              </MenuItem>
            )}
          </Menu>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <SpecialOfferWrapper>
            <SpecialOffer />
          </SpecialOfferWrapper>
        </Content>
        {!isDesktop && (
          <FooterWrapper>
            <Footer isHomeScreen />
          </FooterWrapper>
        )}
      </MainScreen>
      {isDesktop && (
        <FooterWrapper>
          <Footer isHomeScreen />
        </FooterWrapper>
      )}
    </Root>
  );
};
