import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {
  Stripe, StripeConstructorOptions, StripeElementsOptions, loadStripe,
} from '@stripe/stripe-js';
import { BuyCourseResponse } from '../../network';
import { CheckoutFormView } from './CheckoutFormView';
import { Course } from '../../types';

export interface CheckoutFormProps {
  data: BuyCourseResponse;
  course: Course | null;
}

const publicKyes: Record<string, Promise<Stripe | null>> = {

};

export const CheckoutForm: FC<CheckoutFormProps> = (props) => {
  const { data, course } = props;
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (data?.public_key) {
      const key = `${data.public_key}---${data.account_id || ''}`;
      if (!(key in publicKyes)) {
        const options: StripeConstructorOptions = {};
        if (data.account_id) {
          options.stripeAccount = data.account_id;
        }
        publicKyes[key] = loadStripe(data.public_key, options);
      }
      setStripePromise(publicKyes[key]);
    }
  }, [data?.public_key]);

  const options: StripeElementsOptions = useMemo(() => {
    if (!data?.client_secret) {
      return {};
    }
    return {
      clientSecret: data.client_secret,
      appearance: {
        theme: 'night',
      },
    };
  }, [data]);

  if (stripePromise === null) {
    return null;
  }

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutFormView course={course} />
    </Elements>
  );
};
