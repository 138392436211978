import { FC } from 'react';
import { Review } from '../../types';
import {
  Avatar,
  Content,
  Header, Root, Username,
} from './styled';

export interface ReviewCardProps {
  item: Review;
}

export const ReviewCard: FC<ReviewCardProps> = (props) => {
  const { item } = props;

  return (
    <Root>
      <Header>
        {item.user?.avatar?.image && (
          <Avatar src={item.user.avatar.image} />
        )}
        <Username>{item.user.username}</Username>
      </Header>
      <Content>
        {item.text}
      </Content>
    </Root>
  );
};
