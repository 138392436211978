import { styled } from 'styled-components';

export const Root = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.spacing.get(5, 8)};
  border-radius: 3px;
  border: 1px solid #03FBFD;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(3, 8)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${(p) => p.theme.spacing.get(4)};
  }
`;

export const Content = styled.div`
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(p) => p.theme.spacing.get(3)};
  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(6, 0)};
  }
`;

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(2)};

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: ${(p) => p.theme.spacing.get(3)};
  }
`;

export const Reviews = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(2)};

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: ${(p) => p.theme.spacing.get(3)};
  }
`;

export const ItemImage = styled.img`
  display: none;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: block;
    flex-shrink: 0;
    width: 300px;
    height: 300px;
    object-fit: contain;
    object-position: center;
  }
`;

export const Name = styled.div`
  ${(p) => p.theme.typography.base};
  font-size: 26px;
  letter-spacing: -0.39px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;

export const Description = styled.div`
  max-width: 300ch;
  word-break: break-word;
  white-space: normal;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${(p) => p.theme.spacing.get(4)};
  gap: ${(p) => p.theme.spacing.get(4)};

  ${(p) => p.theme.breakpoints.up('md')} {
    padding-top: ${(p) => p.theme.spacing.get(6)};
    gap: ${(p) => p.theme.spacing.get(6)};
  }
`;
