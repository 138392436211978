import { createContext } from 'react';
import { RequestError } from '../../../api';
import { Course } from '../../../catalog';

export interface StudyPreviewContextValue {
  course: Course | null;
  isLoading: boolean;
  error: Error | RequestError | null;
  reload: () => void;
  stepsOpen: number | null | undefined;
  handleOpen: (id: number) => void;
  handleOpenActiveSection: (id: number) => void;
}

export const StudyPreviewContext = createContext<StudyPreviewContextValue>(
  {} as StudyPreviewContextValue,
);
