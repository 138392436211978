import {
  FC, useCallback, useEffect,
} from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Container, InfoPageLayout } from '../../../shared';
import {
  Button, FormTextInput,
} from '../../../ui';
import { useCreateTicket } from '../../hooks';
import {
  Buttons,
  HeaderRow,
  InputWrapper,
  LabelRow, LabelText, Title,
} from './styled';
import { TicketAttachmentInput } from '../../components';

const schema = yup
  .object({
    subject: yup.string().required('Required'),
    message: yup.string().required('Required'),
    attachments: yup.array().optional().nullable().default([]),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const NewTicketScreen: FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { result, isLoading, request } = useCreateTicket();

  const onSubmit = useCallback(
    (data: FormData) => {
      const { subject, message, attachments } = data;
      request({
        subject,
        message,
        attachments: attachments || undefined,
      });
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      toast('Thank you for your request', {
        type: 'success',
      });
      navigate(`/account/support/${result.id}`);
    }
  }, [result, navigate]);

  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradientCenter">
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <HeaderRow>
            <Title>New ticket</Title>
          </HeaderRow>
          <InputWrapper>
            <LabelRow>
              <LabelText>please, state the theme of your question</LabelText>
            </LabelRow>
            <FormTextInput
              control={control}
              name="subject"
              fullWidth
              color="primary"
            />
          </InputWrapper>
          <InputWrapper>
            <LabelRow>
              <LabelText>please, write your question</LabelText>
            </LabelRow>
            <FormTextInput
              control={control}
              name="message"
              multiline
              fullWidth
              color="primary"
            />
          </InputWrapper>
          <TicketAttachmentInput
            control={control}
            name="attachments"
          />
          <Buttons>
            <Button size="lg" type="submit" disabled={isLoading}>Submit</Button>
          </Buttons>
        </form>
      </Container>
    </InfoPageLayout>
  );
};
