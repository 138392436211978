import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import { ProfileLayout } from '../../layouts';
import { Container, FadeIn } from '../../../shared';
import { AvatarList } from '../../components';
import { useUserContext } from '../../../auth';
import { Avatar } from '../../types';
import { ButtonWrapper } from './styles';
import { ShapeButton } from '../../../ui';

export const ProfileAvatarScreen: FC = () => {
  const {
    currentUser, updating, updated, update,
  } = useUserContext();
  const [current, setCurrent] = useState<Avatar | null>(null);
  const [ready, setReady] = useState(false);

  const onSelect = useCallback((item: Avatar) => {
    setCurrent(item);
  }, []);

  const onReady = useCallback(() => {
    setReady(true);
  }, []);

  const save = useCallback(() => {
    if (current) {
      update({
        avatar: current,
      });
    }
  }, [current]);

  const doubleClick = useCallback((avatar: Avatar) => {
    if (avatar) {
      update({
        avatar,
      });
    }
  }, []);

  useEffect(() => {
    if (updated) {
      toast('Avatar successfully updated', { type: 'success' });
    }
  }, [updated]);

  return (
    <ProfileLayout bodyBg="fill">
      <FadeIn active={ready}>
        <Container>
          <AvatarList
            current={current?.id || currentUser?.avatar?.id || null}
            onSelect={onSelect}
            onDoubleClick={doubleClick}
            onReady={onReady}
          />
        </Container>
        <ButtonWrapper>
          <ShapeButton
            onClick={save}
            disabled={updating || !current || current?.id === currentUser?.avatar?.id}
          >
            Select
          </ShapeButton>
        </ButtonWrapper>
      </FadeIn>
    </ProfileLayout>
  );
};
