import React, { FC, ReactNode, useMemo } from 'react';
import { UserCourse } from '../../types';
import { ProgressBar } from '../ProgressBar';
import { SectionMenu } from '../SectionMenu';
import {
  ContentBlock, HeaderBar, Container, SideBar,
} from './styled';
import { CourseSection } from '../../../catalog';

interface ProgressWrapperProps {
  userCourse: UserCourse | null;
  children: ReactNode;
  isFinal?: boolean;
  lessonId?: number;
}

export const ProgressWrapper: FC<ProgressWrapperProps> = (props) => {
  const {
    isFinal, userCourse, children, lessonId,
  } = props;

  const activeSectionStep = useMemo((): number | null => {
    if (lessonId && !Number.isNaN(+lessonId)) {
      const currentUserStep = userCourse?.user_steps?.find(
        (userStep) => userStep.id === +lessonId,
      );
      return currentUserStep?.step?.id || null;
    }
    return null;
  }, [lessonId, userCourse?.user_steps]);

  const activeSection = useMemo((): CourseSection | null => {
    if (lessonId && !Number.isNaN(+lessonId)) {
      if (!activeSectionStep) {
        return null;
      }

      const currentActiveSection = userCourse?.course?.sections?.find(
        (item) => {
          return item.steps.find((sectionStep) => {
            return sectionStep.id === activeSectionStep;
          });
        },
      );
      return currentActiveSection || null;
    }

    return null;
  }, [userCourse?.course?.sections, lessonId, activeSectionStep]);

  const currentSectionIndex = useMemo(() => {
    const index = userCourse?.course?.sections
      && userCourse?.course?.sections?.length > 0
      && userCourse?.course?.sections?.findIndex(
        (item) => item.id === activeSection?.id,
      );
    if (index && !Number.isNaN(index)) {
      return index + 1;
    }
    return 1;
  }, [userCourse?.course?.sections, activeSection?.id]);

  const sectionIndexes = useMemo(
    () => (userCourse?.course?.sections?.length
      ? userCourse.course.sections.map((_, index) => index + 1)
      : []),
    [userCourse?.course?.sections],
  );

  return (
    <Container>
      {sectionIndexes?.length > 1 && (
        <HeaderBar>
          <ProgressBar
            isFinal={isFinal}
            arrIndex={sectionIndexes}
            currentIndex={currentSectionIndex}
          />
        </HeaderBar>
      )}
      <ContentBlock>
        <SideBar>
          <SectionMenu
            userCourse={userCourse}
            activeSectionStep={activeSectionStep}
            activeSection={activeSection}
          />
        </SideBar>
        {children}
      </ContentBlock>
    </Container>
  );
};
