const mimeTypeExtensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/bmp': 'bmp',
  'image/webp': 'webp',
  'image/svg+xml': 'svg',
  'application/pdf': 'pdf',
  'text/plain': 'txt',
};

const extensionMimeTypeMap = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  pdf: 'application/pdf',
  txt: 'text/plain',
};

export const getFileExtensionByMimeType = (mimeType: string): string | null => {
  if (mimeType in mimeTypeExtensionMap) {
    return mimeTypeExtensionMap[mimeType] || null;
  }
  return null;
};

export const getFileMimeTypeByExtension = (
  extension: string,
): string | null => {
  const lower = extension.toLocaleLowerCase();
  if (lower in extensionMimeTypeMap) {
    return extensionMimeTypeMap[lower] || null;
  }
  return null;
};
