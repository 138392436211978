import { FC, useEffect, useMemo } from 'react';
import { QuestionButtonsBlock } from '../QuestionButtonsBlock';
import { CourseLesson, TQuestionsTest } from '../../../catalog';
import { useCheckUserCourseAnswers } from '../../hooks';
import { getRandom } from '../../utils';
import { UserCourseStep } from '../../types';
import {
  CourseTitle,
  ResultsRow,
  Root,
  ResultContent,
  ResultAnswers,
} from './styled';

interface LessonResultsContentProps {
  userCourseId?: number;
  userSteps?: CourseLesson[];
  step?: UserCourseStep;
}

export const LessonResultsContent: FC<LessonResultsContentProps> = (props) => {
  const { userCourseId, step, userSteps } = props;
  const { result, request } = useCheckUserCourseAnswers();

  useEffect(() => {
    if (userCourseId) {
      request(userCourseId);
    }
  }, [userCourseId]);

  const resultValues = useMemo(() => {
    const resultObj = { correct: 0, all: 0 };
    if (!userSteps && result) {
      resultObj.all = result.filter(
        (item) => item.step.questions?.length,
      )?.length;
      result.forEach((item) => {
        const user_answers = item.answers.map((answer) => +answer);
        const answers = item.step.questions
          .filter((question) => question.is_correct)
          .map((correctQuestion) => +correctQuestion.id);
        if (answers?.length === 1 && answers.includes(user_answers[0])) {
          resultObj.correct += 1;
        }
      });
    } else if (userSteps) {
      const questionNumber = userSteps.filter(
        (item) => item.questions?.length,
      )?.length;
      resultObj.correct = questionNumber;
      resultObj.all = questionNumber;
    }
    return resultObj;
  }, [userSteps, result]);

  const lessonResult = useMemo(() => {
    if (userSteps) {
      const generateAnswers = (questions: TQuestionsTest[]) => {
        return [questions[getRandom(0, questions?.length)]?.id];
      };

      const filterUserSteps = userSteps.filter(
        (item) => item.type !== 'results',
      );

      return filterUserSteps.map((item) => ({
        answers: generateAnswers(item.questions),
        id: getRandom(1, 200),
        is_finished: true,
        step: {
          id: item.id,
          name: item.name,
          type: item.type,
          duration: item.duration,
          video_url: item.video_url,
          content_text: item.content_text,
          skill: item.skill,
          questions: item.questions,
        },
      }));
    }
    return result;
  }, [userSteps, result]);

  return (
    <Root>
      {step ? (
        <ResultContent
          dangerouslySetInnerHTML={{ __html: step.step.content_text || '' }}
        />
      ) : null}
      <ResultAnswers>
        You have
        {' '}
        {resultValues.correct}
        /
        {resultValues.all}
        {' '}
        of correct answers
      </ResultAnswers>
      {Array.isArray(lessonResult) && lessonResult?.length > 0
        ? lessonResult.map((item: UserCourseStep) => {
          if (item?.step?.questions?.length) {
            return (
              <ResultsRow key={item?.id}>
                <CourseTitle>{item?.step?.name}</CourseTitle>
                <ResultContent
                  dangerouslySetInnerHTML={{
                    __html: item?.step.content_text || '',
                  }}
                />

                <QuestionButtonsBlock
                  questions={item?.step?.questions || []}
                  answers={item?.answers}
                  isResultPage
                />
              </ResultsRow>
            );
          }
          return null;
        })
        : null}
    </Root>
  );
};
