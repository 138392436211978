import { apiClient } from '../../api';

export const updateStepAnswers = async (
  userCourseId: number | string,
  stepId: number | string,
  body: { answers: number[] | string[] },
): Promise<unknown> => {
  const response = await apiClient.request({
    path: `/api/v1/user-course/${userCourseId}/step/${stepId}/`,
    body,
    method: 'PATCH',
  });

  return response.data;
};
