import { css, keyframes, styled } from 'styled-components';
import { ShapeButtonColor, ShapeButtonHoverEffect, ShapeButtonSize } from './types';

const animtionShape = keyframes`
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const ButtonShape = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
    background: var(--background);
    clip-path: url(#button-shape-background);
  }
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
    background: var(--shape-color);
    background-size: 50% 200%;
    background-repeat: repeat;
    background-position: 100% 100%;
    clip-path: url(#button-shape-borders);
    animation: ${animtionShape} 2s linear infinite;
    animation-play-state: var(--animation-state);
  }
`;

const animtionText = keyframes`
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
`;

export const ButtonContent = styled.div`
  position: absolute;
  inset: 19% 7% 17% 6%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
   
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: inherit;
  text-transform: uppercase;

  background: var(--text-background);
  background-position: 100% 100%;
  background-size: 200% 200%;
  animation: ${animtionText} 2s linear infinite;
  animation-play-state: var(--animation-state);
  background-clip: text;
  text-fill-color: transparent;
`;

export const ButtonText = styled.div`
  max-width: 100%;
  text-decoration: none;
`;

export interface RootProps {
  color?: ShapeButtonColor;
  size?: ShapeButtonSize;
  hoverEffect?: ShapeButtonHoverEffect;
  background?: boolean;
  glow?: boolean;
  disabled?: boolean;
}

export const Root = styled.button<RootProps>`
  position: relative;
  display: block;
  aspect-ratio: ${1647 / 550};
  border: 0;
  margin: 0;
  background: transparent;

  letter-spacing: -1.5%;
  color: #fff;
  cursor: pointer;

  --background: ${(p) => (p.background ? '#000000' : 'transparent')};
  

  ${(p) => p.glow && css`
    filter: drop-shadow(10px 10px 33px #57FEFF); 
  `}

  ${({ color = 'primary', hoverEffect }) => {
    if (color === 'gradient') {
      return css`
        --shape-color: linear-gradient(126deg, #FE19A9 0%, #20F7E6 25%, #FE19A9 50%, #20F7E6 75%, #FE19A9 100%);
        --text-background: linear-gradient(180deg, #FF0099 45%, #FFFFFF 90%, #03FBFD 100%);
        --animation-state: paused; 

        &:hover {
          --animation-state: ${hoverEffect === 'gradient' ? 'running' : 'paused'};
        }
      `;
    }
    return css`
      --shape-color: #20F7E6;
      --text-background: #ffffff;

      ${hoverEffect === 'gradient' && css`
        --animation-state: paused;
        &:hover {
          --shape-color: linear-gradient(126deg, #FFFFFF 0%, #20F7E6 25%,  #FFFFFF 50%, #20F7E6 75%, #FFFFFF 100%);
          --text-background: linear-gradient(0deg, #FFFFFF 0%, #20F7E6 25%,  #FFFFFF 50%, #20F7E6 75%, #FFFFFF 100%); 
          --animation-state: running;
        }
      `}
    `;
  }}

  /**
    xs     sm      md      lg      xl      xxl     xxxl
    36,    46,     56,     66,     78      86,     120
    11,    12.86,  14,29   18,     20      34,29   48 
  */
  ${({ size = 'md' }) => {
    // base for md
    let height = 56;
    let fontSize = 14.29;
    if (size === 'xs') {
      height = 36;
      fontSize = 11;
    } else if (size === 'sm') {
      height = 46;
      fontSize = 12.86;
    } else if (size === 'lg') {
      height = 66;
      fontSize = 18;
    } else if (size === 'xl') {
      height = 78;
      fontSize = 20;
    } else if (size === 'xxl') {
      height = 86;
      fontSize = 34.29;
    } else if (size === 'xxxl') {
      height = 140;
      fontSize = 40;
    }

    return {
      height,
      fontSize,
    };
  }}


  ${(p) => p.disabled && css`
    pointer-events: none;
    filter: grayscale(1);
  `}
`;
