import { LocalStorage } from '../../common';
import { AuthData } from '../types';
import { AuthStorage, AuthStorageKeyMap } from './types';

export class AuthLocalStorage extends LocalStorage<AuthStorageKeyMap> implements AuthStorage {
  constructor() {
    super('@auth');
  }

  setAuthData = async (data: AuthData | null) => {
    await this.setItem('data', data);
  };

  getAuthData = (): Promise<AuthData | null> => {
    return this.getItem('data');
  };
}
