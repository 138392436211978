/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';
import { useMemoize, useRequest } from '../hooks';

export interface MakeLoadHookOptions<Args extends any[]> {
  autoExecute?: boolean | ((...args: Args) => boolean);
}

export const makeLoadHook = <Fn extends (...args: any[]) => any>(
  requestFn: Fn,
  options: MakeLoadHookOptions<Parameters<Fn>> = {},
) => {
  const {
    autoExecute = true,
  } = options;

  return (
    ...args: Parameters<Fn>
  ) => {
    const dataMemo = useMemoize(args);

    const load: () => ReturnType<Fn> = useCallback(() => {
      return requestFn(...dataMemo);
    }, [dataMemo]);

    const {
      request,
      ...rest
    } = useRequest(load);

    useEffect(() => {
      if (autoExecute) {
        if (typeof autoExecute === 'function') {
          if (autoExecute(...dataMemo)) {
            request();
          }
        } else {
          request();
        }
      }
    }, [load, typeof autoExecute === 'function' ? dataMemo : undefined]);

    return {
      request,
      ...rest,
    };
  };
};
