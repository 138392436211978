import {
  FC, ReactNode, useCallback, useMemo,
} from 'react';
import { Root, RootProps, TabItem } from './styled';

type Value = any;

export interface TabsProps extends RootProps {
  value?: Value | null;
  items?: Array<string | {value: Value, label: ReactNode}>;
  className?: string;
  onChange?: (value: Value) => void;
  children?: ReactNode;
}

export const Tabs: FC<TabsProps> = ({
  value = null,
  items = [],
  direction = 'row',
  glow = false,
  topLine = false,
  fullWidth = false,
  className,
  onChange,
  children,
}) => {
  const handleChange = useCallback((val: Value) => {
    if (onChange) {
      onChange(val);
    }
  }, [onChange]);

  const tabs = useMemo(() => {
    if (items) {
      return items.map((x) => {
        if (typeof x === 'object') {
          return x;
        }
        return {
          value: x,
          label: x,
        };
      });
    }
    return [];
  }, [items]);

  return (
    <Root
      direction={direction}
      glow={glow}
      topLine={topLine}
      className={className}
      fullWidth={fullWidth}
    >
      {tabs && tabs.map((item) => (
        <TabItem
          key={item.value}
          className={value === item.value ? 'active' : undefined}
          onClick={() => handleChange(item.value)}
        >
          {item.label}
        </TabItem>
      ))}
      {children}
    </Root>
  );
};
