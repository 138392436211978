import { styled } from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(3)};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(3)};
`;
