import { Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { UnauthorizedLayout } from '../layouts';
import { useAuthContext } from '../../auth';

// temp solution
// #TODO: refactor it
export const tempRedirectStore: { redirect: string | null } = {
  redirect: null,
};

export const UnauthorizedContainer = () => {
  const { authorized, initialized, authorizedRedirect } = useAuthContext();

  if (!initialized) {
    return null;
  }

  if (authorized) {
    return <Navigate replace to={authorizedRedirect || tempRedirectStore.redirect || '/account'} />;
  }

  return (
    <UnauthorizedLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </UnauthorizedLayout>
  );
};
