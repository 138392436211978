import { FC, Suspense } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RootNavigator } from './navigation';
import { RootLayout } from './layouts';
import { baseTheme } from '../ui';
import { AuthProvider, UserContainer } from '../auth';
import 'react-toastify/dist/ReactToastify.css';

export const App: FC = () => {
  return (
    <StyleSheetManager enableVendorPrefixes>
      <ThemeProvider theme={baseTheme}>
        <AuthProvider>
          <UserContainer>
            <Router>
              <RootLayout>
                <Suspense>
                  <RootNavigator />
                </Suspense>
              </RootLayout>
            </Router>
          </UserContainer>
        </AuthProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </ThemeProvider>
    </StyleSheetManager>
  );
};
