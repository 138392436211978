import styled, { css, keyframes } from 'styled-components';

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeAndSlide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(14px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FadeIn = styled.div<{ active?: boolean, slide?: boolean }>`
  animation: ${({ slide = true }) => (slide ? fadeAndSlide : fade)} 0.8s 0s ease-out backwards;
  animation-play-state: paused;

  ${({ active = true }) => active && css`
    animation-play-state: running;
  `}
`;
