import { useCallback, useState } from 'react';
import { downloadFileByUrl } from '../utils';

export const useDownloadFileByUrl = (): {
  isLoading: boolean;
  error: Error | null;
  download: (...params: Parameters<typeof downloadFileByUrl>) => void;
  downloadMultiple: (items: Parameters<typeof downloadFileByUrl>[]) => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const download = useCallback(
    async (...params: Parameters<typeof downloadFileByUrl>) => {
      setIsLoading(true);
      setError(null);
      try {
        await downloadFileByUrl(...params);
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    },
    [],
  );

  const downloadMultiple = useCallback(
    async (items: Array<Parameters<typeof downloadFileByUrl>>) => {
      setIsLoading(true);
      setError(null);

      const promises = items.map((x) => downloadFileByUrl(...x));

      try {
        await Promise.all(promises);
      } catch (e) {
        setError(e);
      }

      setIsLoading(false);
    },
    [],
  );

  return {
    isLoading,
    error,
    download,
    downloadMultiple,
  };
};
