import { CMSPages } from '../types';

export const PAGE: CMSPages = {
  ABOUT: {
    MAIN: 'about',
    TITLE: 'title',
    PARAGRAPTH: 'paragrapth',
  },
  FOR_PARTNERS: {
    MAIN: 'for_partners',
    CONTENT: 'content',
  },
  TERMS_AND_CONDITION: {
    MAIN: 'terms_and_conditions',
    CONTENT: 'content',
  },
  HOME_BANNER: {
    MAIN: 'home',
    CONTENT: 'content',
  },

};
