import { useCallback, useState } from 'react';
import { signup } from '../network';
import { User } from '../types';
import { RequestError } from '../../api';

export const useSignup = (): {
  result: User | null,
  isLoading: boolean,
  error: RequestError | Error | null,
  request: (...args: Parameters<typeof signup>) => void,
} => {
  const [result, setResult] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | Error | null>(null);

  const request = useCallback(
    async (...args: Parameters<typeof signup>) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await signup(...args);
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [],
  );

  return {
    result,
    isLoading,
    error,
    request,
  };
};
