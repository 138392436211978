import { FC } from 'react';
import { Root } from './styled';

export interface LessonTextContentProps {
  content: string;
}

export const LessonTextContent: FC<LessonTextContentProps> = (props) => {
  const { content } = props;

  return (
    // eslint-disable-next-line react/no-danger
    <Root dangerouslySetInnerHTML={{ __html: content }} />
  );
};
