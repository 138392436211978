import { FC, ReactNode } from 'react';
import { FadeIn, Footer, Header } from '../../../shared';
import {
  Root,
  MainScreen,
  Content,
  FooterWrapper,
  BottomLines,
  Wrapper,
} from './styled';

export interface AuthPartnerLayoutProps {
  children?: ReactNode;
  showUserMenu?: boolean;
}

export const AuthPartnerLayout: FC<AuthPartnerLayoutProps> = (props) => {
  const { children, showUserMenu = false } = props;

  return (
    <Root>
      <MainScreen>
        <Wrapper>
          <Header userMenu={showUserMenu} />
          <FadeIn style={{ width: '100%' }}>
            <Content>
              {children}
            </Content>
          </FadeIn>
          <BottomLines />
        </Wrapper>
      </MainScreen>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
};
