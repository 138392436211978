import { css, styled } from 'styled-components';
import exclamationShape from '../../../shared/assets/shapes/exclamation-shape.svg';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  ${(p) => p.theme.breakpoints.up('md')} {
    align-items: center;
  }
`;

export const ContentTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: 12px;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -24px;
  ${(p) => p.theme.typography.base};
  font-size: 26px;
  line-height: 30.4px; /* 116.923% */
  letter-spacing: -0.39px;
  text-transform: uppercase;
  color: #FFFFFF;

  & > a, & > span {
    color: #C1C1C1;
    font-size: 16px;
    letter-spacing: -0.24px;
  }
  & > span {
    display: none;
  }
  & > a {
    text-decoration: none;
    transition: color .3s ease-in-out;
    &:hover {
      color: #FFFFFF;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 0.5em;
    margin-bottom: -12px;
    font-size: 40px;
    line-height: 62.4px;
    letter-spacing: -0.72px;
    & > span {
      display: unset;
    }
    & > a, & > span {
      font-size: 24px;
      letter-spacing: -0.24px;
    }
  }
`;

export const BackLink = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    position: absolute;
    top: 16px;
    left: 0;
    width: auto;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* gap: 8px; */

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 563px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
`;

export const FormBody = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 320px;
  padding-left: 30px;
  padding-right: 15px;
  margin-bottom: 40px;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row-reverse;

    & button {
      flex-shrink: 0;
      max-width: 180px;
    }
  }

  ${(p) => p.theme.breakpoints.down('md')} {
    margin-top: 10px;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    margin-top: 50px;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: url(${exclamationShape});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
`;

export const FooterText = styled.div<{error?: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.24px;
  color: #FFFFFF;

  .checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: stretch;
    cursor: pointer;

    .checkbox-text {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px 0 11px;
      margin-left: -3px;
      border-radius: 0 3px 3px 0;
      border: 1px solid #03FBFD;
      border-width: 1px 1px 1px 0;

      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 10.5px; /* 105% */
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
    /* display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px; */
    /* border-radius: 3px;
    box-shadow: 1px 1px 1px #03FBFD; */
  }

  ${({ error }) => error && css`
    color: #f55;
  `}
`;

export const FormTitle = styled.div`
  width: 100%;
  margin-bottom: 12px;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  font-variant: all-small-caps;
  letter-spacing: 0.24px;
  color: #03FBFD;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.32px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 6px;
  width: 100%;

  ${(p) => p.theme.breakpoints.down('mobile')} {
    flex-direction: column;

    //&:last-of-type {
    //  margin-bottom: 40px;
    //}
  }
`;

export const Label = styled.div`
  width: 105px;
  flex-shrink: 0;
  color: #03fbfd;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 15px; /* 200% */
  letter-spacing: 0.24px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    width: auto;
    font-size: 10px;
  }
`;

export const FieldHint = styled.div`
  margin-top: 2px;
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 83.333% */
  font-variant: all-small-caps;
  letter-spacing: 0.24px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
`;

export const InfoStepFormWrapper = styled(FormWrapper)`
  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: none;
  }

  ${Row} {
    align-items: center;
  }

  ${FormBody} {
    gap: 30px;
  }
  
  .teaching_info {
    min-height: 200px;
  }
  .expectation_info {
    min-height: 164px;
  }
`;

export const FooterLink = styled.a`
  color: #FFFFFF
`;
