import { createContext } from 'react';

export interface AuthContextValue {
  initialized: boolean;
  authorized: boolean;
  authorizedRedirect: string | null;
  setAuthorizedRedirect: (path: string | null) => void;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue,
);
