import { styled } from 'styled-components';
import shape1 from './shape1.svg';
import shape2 from './shape2.svg';

export const Root = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 4.51;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.down('mobile')} {
   margin-bottom:60px;
  }
 
`;

export const ShapeContent = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #03FBFD;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;

  white-space: nowrap;
  text-overflow: ellipsis; 
  overflow: hidden;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;

export const ShapeFirst = styled.div`
  position: absolute;
  bottom: 0;
  left: -12%;
  width: 60%;
  aspect-ratio: ${835 / 210};
  background-image: url(${shape1});
  background-size: cover;

  ${ShapeContent} {
    inset: 8% 0 55% 26%;
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    width: 100%;

  }
`;

export const ShapeSecond = styled.div`
  position: absolute;
  top: 0;
  right: -12%;
  width: 60%;
  aspect-ratio: ${835 / 210};
  background-image: url(${shape2});
  background-size: cover;

  ${ShapeContent} {
    inset: 55% 26% 8% 0;
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    width: 100%;
    top: 60px;

  }
`;
