import * as React from 'react';
import type { SVGProps } from 'react';

const SvgHeartFilled = (
  props: SVGProps<SVGSVGElement> & { isSaved?: boolean | null },
) => {
  const { isSaved } = props;
  return (
    <svg width="22" height="22" fill="none" viewBox="0 0 17 18" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9097 12.1469C13.8735 10.3807 14.875 8.64568 14.875 6.95983C14.875 4.88377 13.5208 3.34071 11.7223 3.33326C10.8991 3.33325 10.445 3.41718 9.90386 3.68205C9.57101 3.84498 9.2757 4.06493 9.01811 4.34443L8.50166 4.90483L7.98046 4.34884C7.72854 4.0801 7.4375 3.86505 7.10822 3.70218C6.55076 3.42646 6.06637 3.33325 5.2803 3.33325C3.45925 3.33325 2.125 4.85455 2.125 6.95983C2.125 8.64568 3.12646 10.3807 5.09028 12.1469C6.16096 13.1099 7.5374 14.0722 8.5 14.5771C9.4626 14.0722 10.839 13.1099 11.9097 12.1469Z"
        stroke="#03FBFD"
        fill={isSaved ? '#03FBFD' : 'none'}
      />
    </svg>
  );
};
export default SvgHeartFilled;
