import styled, { css } from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${(p) => p.theme.spacing.get(6)};
`;

export const Avatar = styled.div<{ src?: string }>`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 100%;
  ${({ src }) => src && css`
    background-image: url(${src});
  `}
  background-size: cover;
  background-position: center;
`;

export const Content = styled.div``;

export const Username = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(3)};
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: 0.48px;
`;

export const Message = styled.div`
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  white-space: pre-wrap;
`;
