import { RequestOptions, apiClient } from '../../api';
import { Country } from '../types';

export const loadCountries = async (
  meta: Partial<RequestOptions> = {},
): Promise<Array<Country>> => {
  const response = await apiClient.request({
    path: '/api/v1/accounts/country/',
    withAuth: false,
    ...meta,
  });

  return response.data;
};
