import { styled } from 'styled-components';

const defaultBackground = 'linear-gradient(180deg, #9747FF 0%, #CF47FF 33.33%, #9747FF 66.67%, rgba(255, 13, 255, 0.00) 100%)';

export const Root = styled.div<{ background?: string | null }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${(p) => p.theme.spacing.get(6, 4)};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(2.5)};
  background: ${(p) => p.background || defaultBackground};
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: ${(p) => p.theme.spacing.get(2, 4, 8)};
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(8, 6, 6)};
    gap: ${(p) => p.theme.spacing.get(2)};

    &:hover {
      transform: scale(1.02);
    }
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 104px;
  object-fit: contain;
  object-position: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 240px;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(1)};
`;

export const Name = styled.div`
  ${(p) => p.theme.typography.base};
  text-align: center;
  font-size: 12px;
  color: #03fbfd;
  letter-spacing: -0.18px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 20px;
    padding: ${(p) => p.theme.spacing.get(4, 0)};
  }
`;

export const RatingWrapper = styled.div``;

export const LikeAction = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  line-height: 0;
  color: ${(p) => (p.isActive ? '#f55' : '#03FBFD')};
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(2)};
    font-size: 24px;
  }
`;

export const Badge = styled.div`
  position: absolute;
  right: -4px;
  top: -10px;
  padding: ${(p) => p.theme.spacing.get(1, 2)};
  border-radius: 20px;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  background: #03fbfd;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 14px;
    right: -10px;
    top: -14px;
  }
`;
