import styled, { css } from 'styled-components';
import { Button } from '../../../ui';

export const QuestionBtn = styled(Button)<{
  isSelected?: boolean;
  isCorrect?: boolean;
}>`
  padding: 32px 17px;
  border-radius: 3px;
  background: #cecdff;
  ${({ isCorrect, isSelected }) => {
    if (isCorrect !== undefined && isCorrect) {
      return css`
        box-shadow: 0 0 21.5px 10px #03fbfdc4;
        border: 2px solid #00aaac;
        transform: ${isSelected ? 'scale(1.2)' : 'none'};
      `;
    }
    if (isCorrect !== undefined && !isCorrect && isSelected) {
      return css`
        box-shadow: 0 0 21.5px 10px #ffbfdd;
        border: 2px solid #b30054;
        transform: ${isSelected ? 'scale(1.2)' : 'none'};
      `;
    }
    if (isSelected) {
      return css`
        transform: scale(1.2);
        border: 2px solid #5e009a;
      `;
    }
    return css`
      border: 2px solid #5e009a;
    `;
  }}
  color: #2d2d2d;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p) => p.theme.breakpoints.down('md')} {
    padding: 25px 17px
  }
`;

export const QuestionBtnBlock = styled.div`
  margin: 20px 0 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 72px;
  align-items: center;
  width: 100%;

  ${(p) => p.theme.breakpoints.down('md')} {
    gap: 15px;
  }
`;
