import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AuthPartnerLayout } from '../../layouts';
import {
  Content, ContentCard, LinkItem, LinksWrapper,
} from './styled';

export const IdentifyScreen: FC = () => {
  return (
    <AuthPartnerLayout>
      <Content>
        <ContentCard>
          Please
          <br />
          identify yourself
        </ContentCard>
      </Content>
      <LinksWrapper>
        <LinkItem>
          <Link to="/partner">
            I came to collaborate
          </Link>
        </LinkItem>
        <LinkItem>
          <Link to="/auth/signup">
            I’m here to learn
          </Link>
        </LinkItem>
        <LinkItem>
          <Link to="/auth/signin">
            I’ve been here before (login)
          </Link>
        </LinkItem>
      </LinksWrapper>
    </AuthPartnerLayout>
  );
};
