import { FC, ReactNode } from 'react';
import {
  Body, BodyBg, FooterWrapper, PageBg, Root, Wrapper,
} from './styled';
import { Footer, Header, HeaderProps } from '../../components';
import { useBreakpoint } from '../../../ui';

export interface InfoPageLayoutProps {
  bg?: PageBg;
  bodyBg?: BodyBg;
  footerBg?: string | null;
  children?: ReactNode;
  headerProps?: Partial<HeaderProps>;
}

export const InfoPageLayout: FC<InfoPageLayoutProps> = (props) => {
  let {
    bg = 'noise', bodyBg, footerBg, children, headerProps = {},
  } = props;
  const isDesktop = useBreakpoint('up', 'md');

  footerBg = footerBg || bodyBg === 'darkGradientInverse' ? '#000' : undefined;

  return (
    <Root>
      <Wrapper bg={bg}>
        <Header {...headerProps} />
        <Body bg={bodyBg}>
          {children}
          {!isDesktop && (
            <FooterWrapper bg={footerBg}>
              <Footer />
            </FooterWrapper>
          )}
        </Body>
      </Wrapper>
      {isDesktop && (
        <FooterWrapper bg={footerBg}>
          <Footer />
        </FooterWrapper>
      )}
    </Root>
  );
};
