import { styled } from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../../shared/assets/images/background-grid.jpg';
import { Container } from '../../../shared';

export const Root = styled.div`
  width: 100%;
`;

export const MainScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('${bg}');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Title = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  ${(p) => p.theme.typography.base};
  font-size: 64px;
  background: linear-gradient(180deg, #FFF 44.13%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 96px;
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    font-size: 42px;
  }

`;

export const HeaderWrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  
`;

export const ItemsWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: -120px -100px -40px;
    background: linear-gradient(180deg, rgba(1, 0, 70, 0.00) 0%, #010046 25.52%, #010046 90.63%, rgba(1, 0, 70, 0.00) 100%);
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    &:before {
      display: none;
    }
  }
`;

export const Items = styled(Container)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  padding-bottom: 40px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled(Link)`
  text-decoration: none;
  position: relative;

  /* ${(p) => p.theme.breakpoints.up('md')} {
    width: 400px;
    height: 500px;
  } */
`;

export const FooterWrapper = styled.div`
`;
