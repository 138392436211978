import styled from 'styled-components';

export const Root = styled.div`
  padding: 80px 23px 40px;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(180deg,
  rgba(0, 0, 0, 0) 0%,
  rgba(229, 229, 255, 0.33) 9.9%,
  #cecdff 43.23%);
  font-weight: 400;

  & h3 {
    color: #fff;
    font-family: ${(p) => p.theme.typography.fontFamily};
    font-size: 16px;
    line-height: 39px;
    letter-spacing: -0.24px;
    text-transform: capitalize;

    ${(p) => p.theme.breakpoints.down('mobile')} {
      font-size: 14px;
    }
  }

  & p {
    color: #000;
    font-family: ${(p) => p.theme.typography.fontFamilySecondary};
    font-size: 14px;
    line-height: 20.5px;
    letter-spacing: 0.28px;

    ${(p) => p.theme.breakpoints.down('mobile')} {
      font-size: 12px;
    }
  }

  font-style: normal;

  ${(p) => p.theme.breakpoints.down('mobile')} {
    margin-top: 15px;
    padding: 80px 20px 40px;
  }
`;
