import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../../../ui';
import SvgArrowCircleTop from '../../../ui/components/icons/IconComponents/ArrowCircleTop';

export const SectionMenuWrapper = styled.ul`
  position: sticky;
  top: 0;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: auto;
  border-left: 5px solid #03fbfd;
`;

export const SectionContainer = styled.li`
  width: 100%;
  height: 100%;
  list-style: none;
`;

export const SectionStepBlock = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const SectionBlock = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  left: ${({ isActive }) => (isActive ? '-25px' : '-20px')};
`;

export const SectionName = styled.li`
  list-style: none;
  color: #fff;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 20.9px;
  letter-spacing: -0.21px;
  text-transform: capitalize;
`;

export const SectionIndex = styled.div<{ isActive?: boolean }>`
  width: ${({ isActive }) => (isActive ? '56px' : '44px')};
  height: ${({ isActive }) => (isActive ? '45px' : '35px')};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ isActive }) => (isActive ? '#F047FF' : '#9747ff')};
  border-radius: 23px;
  color: #fff;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.21px;
  border: ${({ isActive }) => isActive && '2px solid #000'};
  box-shadow: ${({ isActive }) => isActive && '0px 0px 24px #03FBFD'};
`;

export const SectionStepsBlock = styled.ul<{ isOpen?: boolean }>`
  margin: 20px 0;
  width: 300px;
  padding-left: 30px;
`;

export const LinkStep = styled('a')`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkStepPreview = styled(Link)`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SectionStep = styled.li<{ isActive?: boolean }>`
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style: none;
  color: #fff;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.28px;
  text-decoration-line: ${({ isActive }) => (isActive ? 'underline' : 'none')};
`;

export const OpenBtn = styled(Button)`
  margin-left: 13px;
  border: none;
  width: auto;
  height: auto;
`;

export const ArrowSvg = styled(SvgArrowCircleTop)<{ isOpen?: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '0')};
  transition: transform 0.5s ease-in-out;
`;
