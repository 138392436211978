import styled, { css } from 'styled-components';

export const StyledInput = styled.input<{ size?: string }>`
  display: block;
  min-width: 0px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.get(2, 3)};
  border: none;
  font: inherit;
  color: ${({ theme }) => theme.palette.neutral5};
  background: transparent;
  outline: none;

  textarea& {
    min-height: 164px;
    resize: none;
  }

  &:first-child {
    padding-left: ${({ theme }) => theme.spacing.get(4)};
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.spacing.get(4)};
    color:#fff;
  }

  ${({ color }) => color === 'primary' && css`
    color: #03FBFD;
    &::placeholder {
      color: #9A9A9A;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
      border: 1px solid transparent;
      -webkit-text-fill-color: #03FBFD;
      /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
      transition: background-color 5000s ease-in-out 0s;
    }
  `}

  ${({ size }) => size === 'sm' && css`
    padding: ${({ theme }) => theme.spacing.get(2, 3)};
  `}
`;
