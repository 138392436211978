import {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import {
  InputWrapper, Label, Root, Row,
} from './styled';
import { Button, FormTextInput, useBreakpoint } from '../../../ui';
import {
  useLoadSupplierContact,
  useUpdateSupplierContact,
  useUserContext,
} from '../../../auth';

const schema = yup
  .object({
    title: yup.string().optional().nullable(),
    first_name: yup.string().optional().nullable(),
    last_name: yup.string().optional().nullable(),
    position: yup.string().optional().nullable(),
    phone: yup.string().optional().nullable(),
    email: yup.string().optional().nullable(),
  })
  .required();

type FormData = yup.InferType<typeof schema> ;

export const SupplierSettingsContactScreen: FC = () => {
  const { currentUser } = useUserContext();
  const { result: initialData } = useLoadSupplierContact();
  const { result, isLoading, request } = useUpdateSupplierContact();
  const isDesktop = useBreakpoint('up', 'md');

  const isCompany = useMemo(
    () => currentUser?.supplier_info?.type === 'company',
    [currentUser?.supplier_info?.type],
  );
  const isIndividual = useMemo(
    () => currentUser?.supplier_info?.type === 'individual',
    [currentUser?.supplier_info?.type],
  );

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const titleCard = useMemo(() => {
    if (isIndividual) {
      return 'contact person’s detales (if aplicable)';
    }
    return 'contact person’s detales';
  }, [isIndividual]);

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data);
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      toast('Information successfully updated', { type: 'success' });
    }
  }, [result]);

  useEffect(() => {
    if (initialData) {
      if (isCompany) {
        setValue('title', initialData.title || '');
        setValue('position', initialData.position || '');
      }
      setValue('first_name', initialData.first_name || '');
      setValue('last_name', initialData.last_name || '');
      setValue('phone', initialData.phone || '');
      setValue('email', initialData.email || '');
    }
  }, [initialData, isCompany]);

  return (
    <ProfileSettingsLayout>
      <ShapeCard title={titleCard}>
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          {isCompany && (
            <Row>
              <Label>Title</Label>
              <InputWrapper>
                <FormTextInput
                  color="primary"
                  size={isDesktop ? 'md' : 'sm'}
                  reserveSpaceForError
                  name="title"
                  fullWidth
                  control={control}
                />
              </InputWrapper>
            </Row>
          )}
          <Row>
            <Label>First Name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="first_name"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Last name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="last_name"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          {isCompany && (
            <Row>
              <Label>Position</Label>
              <InputWrapper>
                <FormTextInput
                  color="primary"
                  size={isDesktop ? 'md' : 'sm'}
                  reserveSpaceForError
                  name="position"
                  fullWidth
                  control={control}
                />
              </InputWrapper>
            </Row>
          )}
          <Row>
            <Label>Phone</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="phone"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Email</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError
                name="email"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
