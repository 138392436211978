import { FC, useEffect } from 'react';
import {
  Content, ContentCard, LinkItem, LinksWrapper, WarningItem,
} from './styled';
import { AuthPartnerLayout } from '../../../auth/layouts';
import { useLoadKycLink } from '../../../auth';
import { Button } from '../../../ui';
import warning from '../../../shared/assets/images/warning.svg';

export const SupplierVerificationScreen: FC = () => {
  const { result, request } = useLoadKycLink();

  useEffect(() => {
    const host = window.location.origin;
    const url = `${host}/account/supplier`;
    request({
      refresh_url: url,
      return_url: url,
    });
  }, []);

  return (
    <AuthPartnerLayout showUserMenu>
      <Content>
        <ContentCard>
          Verify your
          <br />
          account
        </ContentCard>
      </Content>
      <LinksWrapper>
        <LinkItem>
          <div>
            Just one easy step
            <br />
            and you&apos;re all set!
          </div>
        </LinkItem>
        <WarningItem>
          <div>
            <img src={warning} alt="" />
            This action will open in Stripe
          </div>
        </WarningItem>
      </LinksWrapper>
      <Button
        as="a"
        size="lg"
        disabled={!result?.url}
        href={result?.url ? result.url : ''}
      >
        Let&apos;s go
      </Button>
    </AuthPartnerLayout>
  );
};
