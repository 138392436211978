import { RequestOptions, apiClient } from '../../api';
import { User } from '../types';

export const loadCurrentUser = async (meta: Partial<RequestOptions> = {}): Promise<User> => {
  const response = await apiClient.request({
    path: '/api/v1/accounts/profile/',
    withAuth: true,
    ...meta,
  });

  return response.data;
};
