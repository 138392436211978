import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

`;
