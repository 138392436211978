import { FC, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import {
  InputWrapper, Label, Root, Row, Title,
} from './styled';
import { Button, FormTextInput, useBreakpoint } from '../../../ui';
import { useUserContext } from '../../../auth';

const schema = yup
  .object({
    first_name: yup.string().required(''),
    last_name: yup.string().optional().nullable(),
    email: yup.string().email('Invalid email').required(''),
    birthday: yup.string().optional().nullable(),
    phone: yup.string().optional().nullable(),
    address: yup.string().optional().nullable(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const ProfileSettingsMainScreen: FC = () => {
  const {
    currentUser, updating, updated, update,
  } = useUserContext();
  const isDesktop = useBreakpoint('up', 'md');
  const {
    control, getValues, handleSubmit, setValue, watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchBirthday = watch('birthday');

  const onSubmit = useCallback(
    (data: FormData) => {
      update(data);
    },
    [update],
  );

  const handleChangeEmail = (e) => setValue('email', e.toLowerCase());

  useEffect(() => {
    if (updated) {
      toast('Information successfully updated', { type: 'success' });
    }
  }, [updated]);

  useEffect(() => {
    if (currentUser) {
      setValue('first_name', currentUser.first_name || '');
      setValue('last_name', currentUser.last_name);
      setValue('email', currentUser.email);
      setValue('birthday', currentUser.birthday);
      setValue('phone', currentUser.phone);
      setValue('address', currentUser.address);
    }
  }, [currentUser]);

  useEffect(() => {
    const value = getValues('birthday');
    if (value) {
      const arrValueDate = String(value)?.split('-');
      setValue(
        'birthday',
        `${arrValueDate[0].slice(0, 4)}-${arrValueDate[1]}-${arrValueDate[2]}`,
      );
    }
  }, [watchBirthday]);

  return (
    <ProfileSettingsLayout>
      <ShapeCard>
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Title>Only you can see this information</Title>
          <Row>
            <Label>First name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError={!isDesktop}
                name="first_name"
                placeholder="Enter first name"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Last name</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError={!isDesktop}
                name="last_name"
                placeholder="Enter last name"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Email</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError={!isDesktop}
                name="email"
                onChangeText={handleChangeEmail}
                placeholder="jonhdou@example.com"
                type="email"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Date of Birth</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError={!isDesktop}
                name="birthday"
                placeholder="dd/mm/yyyy"
                type="date"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Phone number</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError={!isDesktop}
                name="phone"
                placeholder="Enter your phone number"
                type="phone"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Row>
            <Label>Address</Label>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                reserveSpaceForError={!isDesktop}
                name="address"
                placeholder="City, street, house number"
                fullWidth
                control={control}
              />
            </InputWrapper>
          </Row>
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={updating}
          >
            Save
          </Button>
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
