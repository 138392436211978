import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../layouts';
import { useSigninGoogleRedirect } from '../../hooks';

export const AuthGoogleScreen: FC = () => {
  const navigate = useNavigate();
  const { result, error, request } = useSigninGoogleRedirect();

  useEffect(() => {
    request(window.location.search);
  }, [request]);

  useEffect(() => {
    if (error) {
      navigate('/');
    } else if (result) {
      navigate('/account/select-avatar');
    }
  }, [error, result]);

  return (
    <AuthLayout />
  );
};
