import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLoadStripeAccount, useUserContext } from '../../../auth';
import { ProfileLayout } from '../../layouts';

export const SupplierStatusContainer: FC = () => {
  const { result } = useLoadStripeAccount();
  const { currentUser } = useUserContext();

  if (!result) {
    return <ProfileLayout bodyBg="gradient" showTabs={false} />;
  }

  // if (!result.is_verified) {
  //   return <Navigate replace to="/account/verification-pending" />;
  // }

  if (
    currentUser?.supplier_info?.is_stripe_enabled
    && !result.is_verified
    && result.requirements?.disabled_reason !== 'requirements.pending_verification'
  ) {
    return <Navigate replace to="/account/verify" />;
  }

  return <Outlet />;
};
