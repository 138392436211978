/* eslint-disable */
import styled from 'styled-components';

export const ProgressBarWrapper = styled.div<{
  size?: number;
}>`
  padding: 0 71px;
  margin-bottom: 39px;
  position: relative;
  height: 5px;
  width: ${({ size }) => size && `${256 * size}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ProgressBarLine = styled.div<{
  isActive?: boolean;
}>`
  position: relative;
  height: 5px;
  width: 100%;
  box-shadow: ${({ isActive }) => isActive && '0px 3px 34px 10px #0085FF'};
  background: ${({ isActive }) => (isActive ? '#03FBFD' : 'rgba(3, 251, 253, 0.75)')};
`;

export const ProgressBarIndex = styled.div<{
  isLast?: boolean;
  size?: number;
}>`
  position: absolute;
  left: ${({ isLast }) => (isLast ? 'auto' : '0')};
  right: ${({ isLast, size }) => (isLast && size
    ? 256 * size > 1232
      ? '59px'
      : '62px'
    : 'auto')};
  top: 25px;
  color: #fff;
  text-shadow: 0 0 23px #faff00;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 62.4px;
  letter-spacing: -0.21px;
`;

export const Serif = styled.div<{
  isActive?: boolean;
}>`
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 2px;
  height: ${({ isActive }) => (isActive ? '56px' : '22px')};
  background: #03fbfd;
`;
