import {
  FC, ReactNode, useEffect, useMemo, useState,
} from 'react';
import { UserContext, UserContextValue } from './UserContext';
import { useRequest } from '../../../common';
import { loadCurrentUser } from '../../network';
import { useAuthContext } from '../AuthProvider';
import { useUpdateUser } from '../../hooks';

export interface UserContainerProps {
  children: ReactNode;
}

export const UserContainer: FC<UserContainerProps> = (props) => {
  const { children } = props;
  const { authorized } = useAuthContext();
  const {
    result: currentUser,
    isLoading,
    error,
    request: refresh,
    reset,
  } = useRequest(loadCurrentUser);
  const {
    result: updatedUser,
    isLoading: updating,
    error: updateError,
    request: update,
    reset: updateReset,
  } = useUpdateUser();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (authorized) {
      refresh();
    } else {
      reset();
      updateReset();
    }
  }, [authorized, refresh, reset]);

  useEffect(() => {
    if (updatedUser) {
      setUpdated(true);
      setTimeout(() => {
        setUpdated(false);
      }, 100);
    }
  }, [updatedUser]);

  const contextValue: UserContextValue = useMemo(() => {
    return {
      currentUser: updatedUser || currentUser,
      isLoading,
      error,
      refresh,
      updated,
      updating,
      updateError,
      update,
    };
  }, [
    currentUser,
    isLoading,
    error,
    refresh,
    updatedUser,
    updated,
    updating,
    updateError,
    update,
  ]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
