import { RequestOptions, apiClient } from '../../api';
import { SupplierContact } from '../types';

export const updateSupplierContact = async (
  data: SupplierContact,
  meta: Partial<RequestOptions> = {},
): Promise<SupplierContact> => {
  const response = await apiClient.request({
    path: '/api/v1/accounts/supplier/contacts/',
    method: 'POST',
    body: data,
    withAuth: true,
    ...meta,
  });

  return response.data;
};
