import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" {...props}>
    <path d="M22.7186 12.9393C22.6662 12.8822 22.5926 12.8497 22.5152 12.8497H19.4159V11.4825C19.4159 10.7254 19.8998 10.6667 20.0481 10.6667H22.4212C22.5733 10.6667 22.6968 10.5433 22.6968 10.3911V6.76657C22.6968 6.61472 22.5742 6.49154 22.4223 6.49098L19.0587 6.47748C15.4887 6.47748 14.2169 8.94705 14.2169 11.2582V12.85H12.5788C12.4267 12.85 12.3032 12.9734 12.3032 13.1256V17.5C12.3032 17.6521 12.4267 17.7756 12.5788 17.7756H14.4906V28.1626C14.4906 28.3147 14.614 28.4382 14.7661 28.4382H18.8669C19.0191 28.4382 19.1425 28.315 19.1425 28.1626V25.7019C19.1425 25.5497 19.0191 25.4263 18.8669 25.4263C18.7148 25.4263 18.5913 25.5497 18.5913 25.7019V27.887H15.0417V17.5C15.0417 17.3479 14.9183 17.2244 14.7661 17.2244H12.8544V13.4014H14.4925C14.6446 13.4014 14.7681 13.278 14.7681 13.1258V11.2584C14.7681 7.23287 18.3422 7.02894 19.0576 7.02894L22.1456 7.04134V10.1158H20.0481C19.4592 10.1158 18.8647 10.5386 18.8647 11.4828V13.1256C18.8647 13.2777 18.9882 13.4011 19.1403 13.4011H22.214L21.8714 17.2244H18.8669C18.7148 17.2244 18.5913 17.3479 18.5913 17.5V21.0543C18.5913 21.2064 18.7148 21.3299 18.8669 21.3299C19.0191 21.3299 19.1425 21.2064 19.1425 21.0543V17.7756H22.1233C22.2661 17.7756 22.3851 17.6667 22.3978 17.5243L22.7897 13.1498C22.7966 13.0726 22.7709 12.9963 22.7186 12.9393Z" fill="#20F8E7" />
    <path d="M26.0959 0H8.9002C3.99276 0 0 3.99358 0 8.9024V26.0976C0 31.0064 3.99276 35 8.9002 35H26.0959C31.0056 35 35 31.0064 35 26.0976V8.9024C35 3.99358 31.0056 0 26.0959 0ZM34.4488 26.0976C34.4488 30.7024 30.7016 34.4488 26.0959 34.4488H8.9002C4.29646 34.4488 0.551181 30.7024 0.551181 26.0976V8.9024C0.551181 4.29756 4.29646 0.551181 8.9002 0.551181H26.0959C30.7019 0.551181 34.4488 4.29756 34.4488 8.9024V26.0976Z" fill="#20F8E7" />
    <path d="M33.0841 17.4978C32.932 17.4978 32.8085 17.6213 32.8085 17.7734V25.4913C32.8085 29.4576 29.4579 32.8085 25.4916 32.8085H9.50815C5.54213 32.8085 2.1915 29.4576 2.1915 25.4913V9.50787C2.1915 5.54185 5.54213 2.1915 9.50815 2.19177H25.4287C25.5809 2.19177 25.7043 2.06831 25.7043 1.91618C25.7043 1.76406 25.5809 1.64059 25.4287 1.64059H9.50815C5.24339 1.64059 1.64032 5.24339 1.64032 9.50787V25.4913C1.64032 29.7563 5.24339 33.3597 9.50815 33.3597H25.4916C29.7566 33.3597 33.3597 29.7563 33.3597 25.491V17.7734C33.3597 17.6213 33.2362 17.4978 33.0841 17.4978Z" fill="#20F8E7" />
    <path d="M30.9907 4.00902C29.4854 2.50374 27.4585 1.64059 25.4287 1.64059C25.2766 1.64059 25.1529 1.76378 25.1529 1.91591C25.1529 2.06803 25.2766 2.19177 25.4287 2.19177C27.3135 2.19177 29.1985 2.99595 30.601 4.3987C30.6548 4.45244 30.7253 4.47945 30.7959 4.47945C30.8664 4.47945 30.937 4.45244 30.9907 4.3987C31.0985 4.29095 31.0985 4.11677 30.9907 4.00902Z" fill="#20F8E7" />
    <path d="M33.0841 11.483C32.932 11.483 32.8085 11.6065 32.8085 11.7586V15.8597C32.8085 16.0118 32.932 16.1353 33.0841 16.1353C33.2362 16.1353 33.3597 16.0118 33.3597 15.8597V11.7586C33.3597 11.6065 33.2362 11.483 33.0841 11.483Z" fill="#20F8E7" />
  </svg>
);
export default SvgFacebookIcon;
