import styled, { keyframes } from 'styled-components';

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  padding: 0 16px;
  border-radius: 3px;
  background: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 36px;
    padding: 0 20px;
  }
`;

const progressAnimation = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

export const ProgressBar = styled.div<{ color: string; progress: number }>`
  position: absolute;
  inset: 0 auto 0 0;
  z-index: 0;
  width: ${({ progress = 0 }) => `${3 + 97 * progress}%`};
  border-radius: 3px; 
  background: ${(p) => p.color};
  animation: ${progressAnimation} 1.2s 1.2s ease-out backwards;
  transform-origin: left;
`;

export const Name = styled.div`
  position: relative;
  z-index: 1;
  /* color: #FFF; */
  color: #000;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.24px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
    letter-spacing: 0.32px;
  }
`;

export const ProgressText = styled.div`
  position: relative;
  z-index: 1;
  color: #000;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.24px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
    letter-spacing: 0.32px;
  }
`;
