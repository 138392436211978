import { css, styled } from 'styled-components';

export const Root = styled.div<{ outline?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: ${(p) => p.theme.spacing.get(4)};
  padding: ${(p) => p.theme.spacing.get(5, 8)};
  border-radius: 3px;
  border: 1px solid transparent;

  ${(p) => p.outline
    && css`
      border-color: #03fbfd;
    `}
  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(p) => p.theme.spacing.get(5, 8)};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(p) => p.theme.spacing.get(3)};
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 18px;
  }

  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(6, 0)};
  }
`;

export const Title = styled.div`
  ${(p) => p.theme.typography.base};
  font-size: 26px;
  letter-spacing: -0.39px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;

export const Image = styled.img`
  display: block;
  flex-shrink: 0;
  width: 100%;
  height: 160px;
  object-fit: contain;
  object-position: left center;

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 300px;
    height: 300px;
    object-position: right center;
  }
`;

export const PreChildren = styled.pre`
  max-width: 500ch;
  word-break: break-word;
  white-space: normal; 
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-weight: 400;
  line-height: 24px;
`;
