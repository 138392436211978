import { FC, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormTextInput, SearchIcon, Selectinput } from '../../../ui';
import { Group, Root } from './styled';
import { LoadCoursesFilters } from '../../network';
import { CourseLevel } from '../../types';

export interface FilttersProps {
  data: Partial<LoadCoursesFilters>;
  onChange: (data: Partial<LoadCoursesFilters>) => void;
}

const levelOptions = [
  {
    value: CourseLevel.NOVICE,
    label: 'Novice',
  },
  {
    value: CourseLevel.MEDIUM,
    label: 'Medium',
  },
  {
    value: CourseLevel.PROFESSIONAL,
    label: 'Professional',
  },
  {
    value: CourseLevel.FOR_ANY,
    label: 'For any',
  },
];

// const durationOptions = [
//   {
//     value: 1,
//     label: '< month',
//     filters: {
//       duration__lte: 60 * 60 * 24 * 30,
//     },
//   },
//   {
//     value: 2,
//     label: '> month',
//     filters: {
//       duration__gte: 60 * 60 * 24 * 30,
//     },
//   },
// ];

export const Filters: FC<FilttersProps> = (props) => {
  const { data, onChange } = props;

  const timerRef = useRef<NodeJS.Timeout | undefined>();

  const { control, watch } = useForm({
    defaultValues: data,
  });

  useEffect(() => {
    const { unsubscribe } = watch((values) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let { duration, grade, ...rest } = values;
        if (duration?.filters) {
          rest = {
            ...rest,
            ...duration.filters,
          };
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (grade?.value) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rest.grade = grade.value;
        }
        onChange(rest as never);
      }, 1000);
    });

    return unsubscribe;
  }, [watch, onChange]);

  return (
    <Root>
      <Group>
        <Controller
          name="grade"
          control={control}
          render={({ field }) => (
            <Selectinput
              color="primary"
              value={field.value as never}
              onChange={field.onChange}
              placeholder="LEVEL"
              options={levelOptions}
            />
          )}
        />
        {/* <Controller */}
        {/*  name="price" */}
        {/*  control={control} */}
        {/*  render={({ field }) => ( */}
        {/*    <Selectinput */}
        {/*      color="primary" */}
        {/*      value={field.value as never} */}
        {/*      onChange={field.onChange} */}
        {/*      placeholder="PRICE" */}
        {/*      options={[]} */}
        {/*    /> */}
        {/*  )} */}
        {/* /> */}
        {/* <Controller */}
        {/*  name="duration" */}
        {/*  control={control} */}
        {/*  render={({ field }) => ( */}
        {/*    <Selectinput */}
        {/*      color="primary" */}
        {/*      value={field.value as never} */}
        {/*      onChange={field.onChange} */}
        {/*      placeholder="DURATION" */}
        {/*      options={durationOptions as never} */}
        {/*    /> */}
        {/*  )} */}
        {/* /> */}
        {/* <Controller */}
        {/*  name="sort" */}
        {/*  control={control} */}
        {/*  render={({ field }) => ( */}
        {/*    <Selectinput */}
        {/*      color="primary" */}
        {/*      value={field.value as never} */}
        {/*      onChange={field.onChange} */}
        {/*      placeholder="SORT" */}
        {/*      options={[]} */}
        {/*    /> */}
        {/*  )} */}
        {/* /> */}
      </Group>
      <FormTextInput
        name="q"
        startIcon={<SearchIcon />}
        placeholder="SEARCH"
        control={control}
      />
    </Root>
  );
};
