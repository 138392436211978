import { styled } from 'styled-components';
import logo from '../../assets/images/logo.png';

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  height: 100%;
  ${(p) => p.theme.breakpoints.down('mobile')} {
    width: 90%;
    height: auto;
  }
`;
