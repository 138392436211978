import { FC, ReactNode } from 'react';
import { GlobalStyles, HiddenContent } from './styles';
import { ShapeButtonSvgPaths } from '../../../ui';

export interface RootLayoutProps {
  children: ReactNode;
}

export const RootLayout: FC<RootLayoutProps> = (props) => {
  const { children } = props;
  return (
    <>
      <GlobalStyles />
      {children}
      <HiddenContent>
        <ShapeButtonSvgPaths />
      </HiddenContent>
    </>
  );
};
