import { RequestOptions, apiClient } from '../../api';
import { User } from '../types';

export const updateSupplierLogo = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: {
    file: File
  },
  meta: Partial<RequestOptions> = {},
): Promise<User> => {
  const formData = new FormData();
  formData.append('file', data.file);

  const response = await apiClient.request({
    path: '/api/v1/accounts/supplier/logo/',
    method: 'POST',
    contentType: 'formData',
    body: formData,
    withAuth: true,
    ...meta,
  });

  return response.data;
};
