import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Content,
  Empty,
  FiltersWrapper,
  FooterWrapper,
  HeaderWrapper,
  Item,
  Items,
  ItemsWrapper,
  MainScreen,
  Root,
  SliderWrapper,
  TabsWrapper,
} from './styled';
import {
  Container, FadeIn, Footer, Header,
} from '../../../shared';
import { CourseBanner, Filters, CourseCard } from '../../components';
import { useLoadCategories, useLoadCourses } from '../../hooks';
import {
  Carousel, ShapeButton, SwiperTabs, useBreakpoint,
} from '../../../ui';
import { LoadCoursesFilters } from '../../network';
import { InfoCard } from '../../components/InfoCard';

export const CoursesScreen: FC = () => {
  const [params] = useSearchParams();
  const isDesktop = useBreakpoint('up', 'md');

  const urlCategory = params.get('category');

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(12);

  const [category, setCategory] = useState<string | number | null>(
    urlCategory ? parseInt(urlCategory, 10) : urlCategory,
  );
  const [filters, setFilters] = useState<Partial<LoadCoursesFilters>>({});

  const { result: items } = useLoadCourses({
    paging: {
      page,
      pageSize,
    },
    filters: {
      ...filters,
      categories: category || undefined,
    },
  });
  const { result: categories } = useLoadCategories();
  const { result: promoItems } = useLoadCourses({
    filters: { is_promoted: true },
  });
  const [version, setVersion] = useState(0);

  // const handlePageSize = useCallback((size) => {
  //   setPageSize(size);
  // }, []);
  //
  // const handlePage = useCallback((numbPage: number) => {
  //   setPage(numbPage);
  // }, []);

  useEffect(() => {
    if (items) {
      setVersion((x) => x + 1);
    }
  }, [items]);

  const tabs = useMemo(() => {
    if (categories?.data) {
      return [
        {
          value: null,
          label: 'All',
        },
        ...categories.data.map((x) => ({
          value: x.id,
          label: x.name,
        })),
      ];
    }
    return [];
  }, [categories?.data]);

  return (
    <Root>
      <MainScreen>
        <HeaderWrapper>
          <Header background />
        </HeaderWrapper>
        <Content>
          <FadeIn active={Boolean(promoItems && items)}>
            <Container>
              {promoItems?.data && promoItems.data.length > 0 && (
                <SliderWrapper>
                  <Carousel adaptiveHeight autoplay>
                    {promoItems.data.map((item) => (
                      <CourseBanner key={item.id} item={item} />
                    ))}
                  </Carousel>
                </SliderWrapper>
              )}
              {tabs.length > 0 && (
                <TabsWrapper>
                  <SwiperTabs
                    value={category}
                    items={tabs}
                    glow
                    onChange={setCategory}
                  />
                </TabsWrapper>
              )}
              <FiltersWrapper>
                <Filters data={filters} onChange={setFilters} />
              </FiltersWrapper>
              {/* <PaginationBlock */}
              {/*  onChange={handlePage} */}
              {/*  currentPage={page} */}
              {/*  onChangePageSize={handlePageSize} */}
              {/*  size={pageSize} */}
              {/* /> */}
              {items && (
                <FadeIn key={version}>
                  {items.data.length > 0 ? (
                    <ItemsWrapper>
                      <Items>
                        <Item>
                          <InfoCard
                            title="Your course here"
                            content={(
                              <div>
                                <p>
                                  become a partner and help others grow
                                  alongside with you
                                </p>
                                <p>
                                  Find new clients and expand your possibilities
                                </p>
                              </div>
                            )}
                            action={(
                              <ShapeButton
                                size={isDesktop ? 'md' : 'sm'}
                                as={Link as any}
                                to="/partner"
                              >
                                <small>Become a partner</small>
                              </ShapeButton>
                            )}
                          />
                        </Item>
                        <Item>
                          <InfoCard
                            title="Request a course"
                            content={(
                              <div>
                                <p>looking for something else?</p>
                                <p>
                                  request a new course and explore a new way of
                                  learning
                                </p>
                              </div>
                            )}
                            action={(
                              <ShapeButton
                                size={isDesktop ? 'md' : 'sm'}
                                as={Link as any}
                                to="/account/request-course"
                              >
                                <small>Request</small>
                              </ShapeButton>
                            )}
                          />
                        </Item>
                        {items.data.map((item) => (
                          <Item
                            key={item.id}
                            as={Link}
                            to={`/courses/${item.id}`}
                          >
                            <CourseCard item={item} />
                          </Item>
                        ))}
                      </Items>
                    </ItemsWrapper>
                  ) : (
                    <Empty>Courses not found</Empty>
                  )}
                </FadeIn>
              )}
              {/* <PaginationBlock */}
              {/*  onChange={handlePage} */}
              {/*  currentPage={page} */}
              {/*  onChangePageSize={handlePageSize} */}
              {/*  size={pageSize} */}
              {/* /> */}
            </Container>
          </FadeIn>
        </Content>
      </MainScreen>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
};
