import { RequestOptions, apiClient } from '../../api';

export interface BuyCourseData {
  id: number;
}

export interface BuyCourseResponse {
  amount: number;
  client_secret: string;
  currency: string;
  error_message: string | null;
  id: number;
  is_failed: boolean;
  is_success: boolean;
  public_key: string;
  status: string;
  stripe_id: string;
  account_id: string;
}

export const buyCourse = async (
  data: BuyCourseData,
  meta: Partial<RequestOptions> = {},
): Promise<BuyCourseResponse> => {
  const {
    id,
  } = data;

  const response = await apiClient.request({
    path: `/api/v1/course/${id}/buy/`,
    method: 'POST',
    withAuth: true,
    ...meta,
  });

  return response.data || {};
};
