import styled from 'styled-components';
import { Container } from '../../../shared';
import angleLineShape from '../../../shared/assets/shapes/angle-line.svg';

export const Navigation = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding-left: 0;
    padding-right: 0;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const TabsWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const TopButtons = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 120px;
  padding-left: 30px;


  ${(p) => p.theme.breakpoints.up('sm')} {
    gap: 40px;
    padding-left: 0;
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 120px;
    aspect-ratio: ${235 / 140};
    background-image: url(${angleLineShape});
    background-size: cover;
    
    ${(p) => p.theme.breakpoints.down('mobile')} {
     display:none;
    }
  }
`;

export const LeftButton = styled(ButtonWrapper)`
  /* align-self: flex-start; */

  &:before {
    top: 73%;
    right: 80%;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    align-self: flex-start;
  }
`;

export const RightButton = styled(ButtonWrapper)`
  margin-left: 60px;

  &:before {
    bottom: 45%;
    left: 95%;
    transform: rotate(180deg);
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-left: 0;
    align-self: flex-end;
  }
`;

export const List = styled.div`
  padding: 50px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill);
  grid-row-gap: ${(p) => p.theme.spacing.get(4)};
  grid-column-gap: ${(p) => p.theme.spacing.get(4)};
  padding-bottom: 40px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: ${(p) => p.theme.spacing.get(7)};
    grid-column-gap: ${(p) => p.theme.spacing.get(6)};
  }
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  color: #fff;
`;
