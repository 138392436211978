import { css, keyframes } from 'styled-components';

export const slideLeftAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideRightAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const animationDelays = (count: number, step: number, initial = 0) => {
  return Array.from({ length: count }).map((_, index) => css`
    &:nth-child(${index + 1}) {
      animation-delay: ${initial + step * index}s;
    }
  `);
};
