import { FC, ReactNode } from 'react';
import { IconWrapper, Root } from './styled';

export interface TagProps {
  startIcon?: ReactNode;
  children?: ReactNode;
}

export const Tag: FC<TagProps> = (props) => {
  const { startIcon, children } = props;
  return (
    <Root>
      {startIcon && (
        <IconWrapper>
          {startIcon}
        </IconWrapper>
      )}
      {children}
    </Root>
  );
};
