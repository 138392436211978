import * as React from 'react';
import type { SVGProps } from 'react';

const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.414 12 7.293 7.293-1.414 1.414L6.586 12l8.707-8.707 1.414 1.414L9.414 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronLeft;
