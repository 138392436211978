import styled, { css } from 'styled-components';
import { NavBoard } from '../../../shared';

export const ContentWrapper = styled(NavBoard)`
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SupplierImage = styled.div<{ src?: string | null }>`
  width: 260px;
  aspect-ratio: 1;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #03FBFD;
  color: #03FBFD;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 260% */
  letter-spacing: -0.36px;
  text-transform: uppercase;

  ${({ src }) => src && css`
    background-image: url(${src});
    background-size: cover;
    background-position: center;
  `}
`;

export const TitleWrapper = styled.div`
  color: #03FBFD;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 62.4px; /* 260% */
  letter-spacing: -0.36px;
  text-transform: uppercase;
`;
