import {
  apiClient,
} from '../../api';
import { UserCourse } from '../types';

export const loadUserCourse = async (
  id: number,
): Promise<UserCourse> => {
  const response = await apiClient.request({
    path: `/api/v1/user-course/${id}/`,
    method: 'GET',
  });

  return response.data;
};
