import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;

  @supports(height: 100svh) {
    min-height: 100svh;
  }
`;
