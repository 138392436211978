import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 520px;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 12px;
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 62.4px; /* 260% */
  letter-spacing: -0.36px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;
