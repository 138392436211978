import { FC, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Root } from './styled';
import { FadeIn, Header } from '../../../shared';
import { GlitchWrapper, Stack } from '../../../ui';
import { useAuthContext } from '../../../auth';

export const CoursePaymentResultScreen: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authorized } = useAuthContext();

  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const status = new URLSearchParams(window.location.search).get(
      'redirect_status',
    );

    if (!status) {
      setMessage('Status not found');
      return;
    }

    switch (status) {
      case 'succeeded':
        setMessage('Payment succeeded!');
        break;
      case 'processing':
        setMessage('Your payment is processing.');
        break;
      case 'requires_payment_method':
        setMessage('Your payment was not successful, please try again.');
        break;
      default:
        setMessage('Something went wrong.');
        break;
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (message === 'Payment succeeded!') {
      const timer = setTimeout(() => {
        navigate(`/courses/${id}/study/`, { replace: true });
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!authorized) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <Root>
      <Header position="absolute" />
      {message && (
        <FadeIn active={Boolean(message)}>
          <Stack gap={6} alignItems="center">
            <GlitchWrapper playMode="always" shake>
              <div>{message}</div>
            </GlitchWrapper>
          </Stack>
        </FadeIn>
      )}
    </Root>
  );
};
