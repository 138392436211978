import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 50px;
`;

export const ComingSoon = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.typography.base};
  font-size: 45px;
  text-transform: uppercase;
  color: #fff;
`;
