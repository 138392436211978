/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  FC, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  ChevronLeftIcon,
  FormSelectInput,
  FormTextInput,
  useBreakpoint,
} from '../../../ui';
import {
  BackButton,
  Content,
  ContentTop,
  FieldsWrapper,
  FooterText,
  InputWrapper,
  Label,
  Row,
  Title,
} from './styled';
import { StepProps } from './types';
import { SignupData } from '../../network';
import { useSignin, useSignup } from '../../hooks';
import { RequestError } from '../../../api';
import { AuthLayoutFooterPortal } from '../../layouts';
import { FooterLink, FormFooter } from '../SignupPartnerScreen/styled';

const schema = yup
  .object({
    agree: yup.boolean().isTrue().required(),
    birthday: yup.string().required('Required'),
    gender: yup.string().required('Required'),
  })
  .required();

const genders = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Non-Binary', label: 'Non-Binary' },
  { value: 'Agender', label: 'Agender' },
  { value: 'prefer not to say', label: 'prefer not to say' },
];

export const SignupStep3: FC<StepProps> = (props) => {
  const { data, onBack } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useBreakpoint('up', 'md');
  const sentDataRef = useRef<Partial<SignupData> | null>(null);

  const redirect = useMemo(
    () => (state?.itemId ? `../courses/${state.itemId}` : '/account/select-avatar'),
    [state?.itemId],
  );

  const {
    result, isLoading, error, request,
  } = useSignup();
  const {
    isLoading: signinIsLoading,
    error: signinError,
    request: signin,
  } = useSignin({
    redirect,
  });

  const loading = isLoading || signinIsLoading;

  const {
    control, handleSubmit, setError, getValues, watch, setValue,
  } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: data as any,
    resolver: yupResolver(schema),
  });

  const watchBirthday = watch('birthday');

  const onSubmit = useCallback(
    (newData: Partial<SignupData>) => {
      sentDataRef.current = newData;
      request(newData as SignupData, {
        silent: true,
      });
    },
    [request],
  );

  useEffect(() => {
    if (
      error
      && error instanceof RequestError
      && error.statusCode === 400
      && error.data
    ) {
      if (error.data?.username && error.data?.username.length > 0) {
        setError('username', {
          message: error.data.username[0],
        });
        return;
      }
    }
    if (error) {
      toast('Something went wrong', {
        type: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      if (sentDataRef.current) {
        signin({
          email: sentDataRef.current.email || '',
          password: sentDataRef.current.password || '',
        });
      }
    }
  }, [result, signin]);

  useEffect(() => {
    if (signinError) {
      navigate('../signin');
    }
  }, [signinError]);

  useEffect(() => {
    const value = getValues('birthday');
    if (value) {
      const arrValueDate = String(value)?.split('-');
      setValue(
        'birthday',
        `${arrValueDate[0].slice(0, 4)}-${arrValueDate[1]}-${arrValueDate[2]}`,
      );
    }
  }, [watchBirthday]);

  return (
    <Content as="form" onSubmit={handleSubmit(onSubmit)} noValidate vCenter>
      <ContentTop>
        <Title>
          <BackButton onClick={loading ? undefined : onBack}>
            <ChevronLeftIcon />
          </BackButton>
          {' '}
        </Title>
        {/* <Description>Your nickname will be visible for other users</Description> */}
      </ContentTop>
      <FieldsWrapper>
        <Row>
          <Label>Birthday</Label>
          <InputWrapper>
            <FormTextInput
              color="primary"
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="birthday"
              type="date"
              fullWidth
              control={control}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Label>Gender</Label>
          <InputWrapper>
            <FormSelectInput
              color="primary"
              options={genders}
              size={isDesktop ? 'md' : 'sm'}
              reserveSpaceForError // ={!isDesktop}
              name="gender"
              plainValue
              fullWidth
              control={control}
              hint="Please Select Gender or write your own option"
            />
          </InputWrapper>
        </Row>
      </FieldsWrapper>
      <Button size={isDesktop ? 'lg' : 'md'} type="submit" disabled={loading}>
        Submit
      </Button>

      <AuthLayoutFooterPortal>
        <FormFooter>
          <Controller
            name="agree"
            control={control}
            render={({ field, fieldState }) => (
              <FooterText error={fieldState.invalid}>
                <span>
                  By creating a business account you agree to RightRoad Edtech
                  Limited Conditions of Content Use & Sale.
                  {' '}
                  <br />
                  {' '}
                  Please, see
                  and agree with our
                  {' '}
                  <FooterLink
                    href="https://courses.rroaded.co.uk/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </FooterLink>
                  , our Cookies Policy, our
                  {' '}
                  <FooterLink
                    href="https://courses.rroaded.co.uk/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </FooterLink>
                  {' '}
                  and
                  {' '}
                  <FooterLink
                    href="https://courses.rroaded.co.uk/for-partners"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Agreement for Partners
                  </FooterLink>
                  .
                </span>
                <label className="checkbox">
                  <div className="checkbox-text">Check</div>
                  <Checkbox checked={field.value} onChange={field.onChange} />
                </label>
              </FooterText>
            )}
          />
        </FormFooter>
      </AuthLayoutFooterPortal>
    </Content>
  );
};
