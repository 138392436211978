import { styled } from 'styled-components';
import shape1 from './shapes/footer-shape-1.svg';
import shape2 from './shapes/footer-shape-2.svg';
import shape3 from './shapes/footer-shape-3.svg';
import shape4 from './shapes/footer-shape-4.svg';
import backgroundFooter from '../../assets/images/background-footer.png';

export const Root = styled.div<{ isHomeScreen?: boolean }>`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  overflow: hidden;
  background: ${({ isHomeScreen }) => (isHomeScreen
    ? 'linear-gradient(180deg, rgba(9, 5, 13, 0.53) 0%, #5E009A 35.27%, rgba(22, 0, 70, 0.28) 70.31%, #200065 100%)'
    : `linear-gradient(180deg, #160046 0%, rgba(18, 0, 69, 0.75) 22.22%, rgba(10, 0, 69, 0.20) 86.81%), url(${backgroundFooter})   center / auto  no-repeat`)};

  ${(p) => p.theme.breakpoints.up('lg')} {
    padding-top: 60px;
    padding-bottom: 40px;
    background-size: cover;
    background-position: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  
  ${(p) => p.theme.breakpoints.down('sm')} {
    gap: 70px;
  }
  
  ${(p) => p.theme.breakpoints.up('lg')} {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    margin-left: -19%;
    margin-right: -12%;
  }
`;

export const FooterContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    gap: 0;
  }
`;

const FooterObjectBase = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  background-size: contain;
  background-repeat: no-repeat;

  ${(p) => p.theme.breakpoints.up('lg')} {
    max-width: none;
  }
`;

export const FooterObject1 = styled(FooterObjectBase)`
  aspect-ratio: ${662 / 276};
  background-image: url(${shape1});

  & ${FooterContent} {
    inset: 23% 12% 31% 45%;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 30%;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    width: 28%;
    aspect-ratio: ${662 / 210};
    background-position: 70px 0;
    
    & ${FooterContent} {
      inset: 0 0 10% 47%;
    }
  }
`;

export const FooterObject2 = styled(FooterObjectBase)`
  width: 100%;
  aspect-ratio: ${635 / 215};
  background-image: url(${shape2});
  background-position: 146px -14px;

  & ${FooterContent} {
    inset: 2% 12% 14% 20%;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 36%;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    width: 32%;
    background-position: 170px 0;
  }

  @media (max-width: 342px) {
    background-position: 77px -20px;
    aspect-ratio: 1.9;
  }
`;

export const FooterObject3 = styled(FooterObjectBase)`
  width: 100%;
  aspect-ratio: ${771 / 299};
  background-image: url(${shape3});
  background-position: -25px -4px;

  & ${FooterContent} {
    inset: 25% 3% 20% 38%;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    aspect-ratio: ${671 / 299};
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 30%;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    aspect-ratio: ${671 / 250};
    width: 28%;

    & ${FooterContent} {
      inset: 1% 6% 8% 32%;
    }
  }
`;

export const FooterObjectSocial = styled.div`
  position: relative;
  left: 15px;
  width: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: ${124 / 424};
  background-image: url(${shape4});

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 6%;
    margin-left: -2%;
  }
  ${(p) => p.theme.breakpoints.up('xl')} {
   
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.div`
  color: #fff;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.21px;
  text-transform: uppercase;

  ${(p) => p.theme.typography.base};
  font-size: 12px;

  ${(p) => p.theme.breakpoints.up('lg')} {
    font-size: 14px;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    font-size: 18px;
  }
`;

export const FooterLink = styled.div`
  display: block;
  position: relative;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 10px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    font-size: 12px;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    font-size: 14px;
  }
`;

export const IconLink = styled('a')`
  width: 35px;
  height: 35px;
  position: absolute;
  right: 40px;

  &:nth-of-type(1) {
    top: 30px;
  }

  &:nth-of-type(2) {
    top: 95px;
  }

  &:nth-of-type(3) {
    top: 160px;
  }

  ${(p) => p.theme.breakpoints.up('xl')} {
    transform: scale(1.6);
    right: 70px;

    &:nth-of-type(1) {
      top: 50px;
    }

    &:nth-of-type(2) {
      top: 140px;
    }

    &:nth-of-type(3) {
      top: 235px;
    }
  }
`;
