import { css, styled } from 'styled-components';

export const Avatar = styled.div<{ src: string }>`
  position: absolute;
  inset: 0 0 0 auto;
  aspect-ratio: 1;
  border-left: 1px solid #03FBFD;

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 6px;
    border-radius: 50%;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const UserInfo = styled.div<{ avatar?: boolean }>`
  display: block;
  padding: 8px 12px;
  border: 1px solid #03FBFD;
  border-radius: 3px;
  color: #FFF;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.9px;
  letter-spacing: -0.18px;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 27px;
    line-height: 42.4px; /* 231.111% */
    letter-spacing: -0.405px;
  }

  ${({ avatar, theme }) => avatar && css`
    & {
      padding-right: 68px;
      position: relative;
    }
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Logout = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 8px 12px;
  border: 1px solid #03FBFD;
  border-radius: 3px;
  text-align: center;
  color: #FFF;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.9px;
  letter-spacing: -0.18px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
  }

  ${Wrapper}:hover & {
    display: block;
  }
`;
