import { css, styled } from 'styled-components';
import bgNoise from '../../assets/images/background-noise.jpg';
import bgSpace from '../../assets/images/background-grid.jpg';
import bgSpaceDark from '../../assets/images/background-grid-dark.jpg';

export const Root = styled.div`
  width: 100%;
`;

export type PageBg = 'noise' | 'space' | 'spaceDark' | null;

export const Wrapper = styled.div<{bg?: PageBg}>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${({ bg }) => {
    if (!bg) {
      return '';
    }

    let bgImage = bgNoise;
    if (bg === 'space') {
      bgImage = bgSpace;
    } else if (bg === 'spaceDark') {
      bgImage = bgSpaceDark;
    }

    return css`
      background-image: url('${bgImage}');
      background-attachment: fixed;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `;
  }}
`;

export type BodyBg = 'darkGradient' | 'darkGradientInverse' | 'fillGradient' | 'fillGradientCenter' | 'fill' | null

export const Body = styled.div<{bg?: BodyBg}>`
  flex-grow: 1;
  padding: 30px 0;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 50px 0;
  }
  
  ${({ bg }) => {
    if (bg === 'fill') {
      return css`
        background-color: #010046;
      `;
    }
    if (bg === 'darkGradient') {
      return css`
        background: linear-gradient(180deg, #000 0%, rgba(1, 0, 70, 0.21) 100%);
      `;
    }
    if (bg === 'darkGradientInverse') {
      return css`
        background: linear-gradient(0deg, #000 0%, rgba(1, 0, 70, 0.21) 100%);
      `;
    }
    if (bg === 'fillGradient') {
      return css`
        background: linear-gradient(180deg, #000 0%, #010046 20.78%, #471E7B 53.32%, #010046 85.17%);
      `;
    }
    if (bg === 'fillGradientCenter') {
      return css`
        background-color: #160046;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(93, 0, 101, 0.28) 40.97%, #020079 100%);
      `;
    }
    return null;
  }}
`;

export const FooterWrapper = styled.div<{ bg?: string | null}>`
  background-color: ${({ bg }) => (bg || 'transparent')};
`;
