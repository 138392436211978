/* eslint-disable import/no-unresolved,@typescript-eslint/ban-ts-comment */
import {
  FC, ReactNode, useCallback, useMemo,
} from 'react';

import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import {
  Root, RootProps, RRSwiper, TabItem,
} from './styled';

type Value = any;

export interface SwiperTabsProps extends RootProps {
  value?: Value | null;
  items?: Array<string | { value: Value; label: ReactNode }>;
  className?: string;
  onChange?: (value: Value) => void;
  children?: ReactNode;
  swipeDirection?: 'horizontal' | 'vertical';
}

export const SwiperTabs: FC<SwiperTabsProps> = ({
  value = null,
  items = [],
  direction = 'row',
  glow = false,
  topLine = false,
  fullWidth = false,
  className,
  onChange,
  children,
  swipeDirection = 'horizontal',
}) => {
  const handleChange = useCallback(
    (val: Value) => {
      if (onChange) {
        onChange(val);
      }
    },
    [onChange],
  );

  const tabs = useMemo(() => {
    if (items) {
      return items.map((x) => {
        if (typeof x === 'object') {
          return x;
        }
        return {
          value: x,
          label: x,
        };
      });
    }
    return [];
  }, [items]);

  return (
    <Root
      direction={direction}
      glow={glow}
      topLine={topLine}
      className={className}
      fullWidth={fullWidth}
    >
      <RRSwiper
        // @ts-ignore
        direction={swipeDirection}
        slidesPerView="auto"
        freeMode
        scrollbar
        mousewheel
        modules={[FreeMode, Scrollbar, Mousewheel]}
      >
        <SwiperSlide style={{ display: 'flex' }}>
          {tabs
            && tabs.map((item) => (
              <TabItem
                key={item.value}
                className={value === item.value ? 'active' : undefined}
                onClick={() => handleChange(item.value)}
              >
                {item.label}
              </TabItem>
            ))}
        </SwiperSlide>
      </RRSwiper>
      {children}
    </Root>
  );
};
