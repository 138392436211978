import { styled } from 'styled-components';
import shape from '../../../shared/assets/shapes/angle-line.svg';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${(p) => p.theme.spacing.get(23, 28)};
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  background: linear-gradient(180deg, rgba(173, 6, 231, 0) 0%, #ad06e7 100%);

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(23, 40, 45)};
  }
`;

export const Title = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(5)};
  font-size: 26px;
  font-weight: 400;
  line-height: 62.4px;
  letter-spacing: -0.39px;
  text-transform: uppercase;
  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(12)};
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${(p) => p.theme.spacing.get(10)};
  max-width: 458px;

  ${(p) => p.theme.breakpoints.up('md')} {
    gap: ${(p) => p.theme.spacing.get(20)};
  }
`;

export const Item = styled.div`
  color: #fff;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
    letter-spacing: 0.32px;
  }
`;

export const ItemTitle = styled.div`
  position: relative;
  margin-bottom: 1em;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-transform: uppercase;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    right: calc(100% - 30px);
    background-image: url(${shape});
    background-size: cover;
    width: 70px;
    aspect-ratio: ${235 / 140};
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 24px;
    letter-spacing: 0.48px;

    &:before {
      width: 230px;
      top: calc(100% + 10px);
      right: calc(100% - 55px);
    }
  }
`;
