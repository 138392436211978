import { FC, ReactNode } from 'react';
import { Item, ItemTitle } from './styled';

export interface PageFeaturesItemProps {
  header: ReactNode;
  text?: ReactNode;
}

export const PageFeaturesItem: FC<PageFeaturesItemProps> = (props) => {
  const { header, text } = props;
  return (
    <Item>
      <ItemTitle>{header}</ItemTitle>
      {text}
    </Item>
  );
};
