import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCheckMark = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.707 14.293 19 5l1.414 1.414L9.707 17.121 4 11.414 5.414 10l4.293 4.293Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheckMark;
