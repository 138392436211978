/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LessonTextContent,
  LessonVideoContent,
  ProgressBar,
} from '../../components';
import { QuestionButtonsBlock } from '../../components/QuestionButtonsBlock';
import { Container, InfoPageLayout } from '../../../shared';
import {
  ActionsPanel,
  ButtonsBlock,
  Content,
  StyledButton,
  Title,
} from '../styled';
import { useStudyPreviewContext } from '../../containers';
import { CourseLesson, CourseSection } from '../../../catalog';
import {
  ContentBlock,
  HeaderBar,
  SideBar,
} from '../../components/ProgressWrapper/styled';
import { SectionPreviewMenu } from '../../components/SectionPreviewMenu';
import { LessonResultsContent } from '../../components/LessonResultsContent';

interface StudyLessonPreviewScreenContentProps {
  lessonId: number;
  courseId: number;
}

export const StudyLessonPreviewScreenContent: FC<
  StudyLessonPreviewScreenContentProps
> = (props) => {
  const { lessonId, courseId } = props;

  const navigate = useNavigate();

  const { course } = useStudyPreviewContext();

  const [answers, setAnswers] = useState<number[] | string[]>([]);

  const userSteps = useMemo(() => {
    let arrayUserSteps: CourseLesson[] = [];
    course?.sections?.forEach((section) => {
      arrayUserSteps = [...arrayUserSteps, ...section.steps];
    });
    return arrayUserSteps;
  }, [course?.sections]);

  const [step, stepIndex] = useMemo(() => {
    if (!course || !lessonId) {
      return [null, null];
    }
    const index = userSteps.findIndex((x) => x.id === Number(lessonId));
    if (index === -1) {
      return [null, null];
    }
    return [userSteps[index], index];
  }, [course, lessonId]);

  const isDisabledNextBtn = useMemo(() => {
    return step?.questions?.length ? !answers?.length : false;
  }, [step?.questions?.length, answers]);

  const nextStep = useMemo(() => {
    const currentIndex = userSteps.findIndex((x) => x.id === Number(lessonId));
    return userSteps[currentIndex + 1] || null;
  }, [userSteps, lessonId]);

  const activeSectionStep = useMemo((): number | null => {
    if (lessonId && !Number.isNaN(+lessonId)) {
      const currentUserStep = userSteps?.find(
        (userStep) => userStep.id === +lessonId,
      );
      return currentUserStep?.id || null;
    }
    return null;
  }, [lessonId, userSteps]);

  const activeSection = useMemo((): CourseSection | null => {
    if (lessonId && !Number.isNaN(+lessonId)) {
      if (!activeSectionStep) {
        return null;
      }

      const currentActiveSection = course?.sections?.find((item) => {
        return item.steps.find((sectionStep) => {
          return sectionStep.id === activeSectionStep;
        });
      });
      return currentActiveSection || null;
    }

    return null;
  }, [course?.sections, lessonId, activeSectionStep]);

  const currentSectionIndex = useMemo(() => {
    const index = course?.sections
      && course?.sections?.length > 0
      && course?.sections?.findIndex((item) => item.id === activeSection?.id);
    if (index && !Number.isNaN(index)) {
      return index + 1;
    }
    return 1;
  }, [course?.sections, activeSection?.id]);

  const sectionIndexes = useMemo(
    () => (course?.sections?.length
      ? course?.sections.map((_, index) => index + 1)
      : []),
    [course?.sections],
  );

  const goToNext = useCallback(() => {
    if (!course || !lessonId) {
      return;
    }
    if (nextStep) {
      navigate(`../${nextStep.id}`);
    }
  }, [lessonId, nextStep, course, navigate]);

  const handlePrev = useCallback(() => {
    if (!stepIndex || !course) {
      return;
    }
    const prevStep = userSteps[stepIndex - 1];
    if (prevStep) {
      navigate(`../${prevStep.id}`);
    }
  }, [stepIndex, course, navigate]);

  const handleSetAnswers = useCallback((id: number[]) => {
    setAnswers(id);
  }, []);

  const handleNext = useCallback(() => {
    if (!step || !course) {
      return;
    }
    goToNext();
  }, [step, course, goToNext, answers]);

  if (!step) {
    return null;
  }
  return (
    <InfoPageLayout bodyBg="fillGradient">
      <Container>
        <Container>
          {sectionIndexes?.length > 1 && (
            <HeaderBar>
              <ProgressBar
                arrIndex={sectionIndexes}
                currentIndex={currentSectionIndex}
              />
            </HeaderBar>
          )}
          <ContentBlock>
            <SideBar>
              <SectionPreviewMenu
                courseId={courseId}
                sections={course?.sections}
                activeSectionStep={activeSectionStep}
                activeSection={activeSection}
              />
            </SideBar>

            <Content>
              <Title>{step?.name}</Title>
              {step.type === 'text' && (
                <LessonTextContent
                  content={step.content_text || 'Empty content'}
                />
              )}
              {step.type === 'video' && (
                <LessonVideoContent videoUrl={step.video_url || ''} />
              )}
              {step.type === 'results' && (
                <LessonResultsContent userSteps={userSteps} />
              )}
              {step.questions?.length ? (
                <QuestionButtonsBlock
                  questions={step.questions}
                  onSetAnswers={handleSetAnswers}
                  answers={answers}
                />
              ) : null}
              <ActionsPanel>
                <ButtonsBlock>
                  {stepIndex > 0 && (
                    <StyledButton
                      // @ts-ignore
                      onClick={handlePrev}
                    >
                      Previous
                    </StyledButton>
                  )}
                  {nextStep && (
                    <StyledButton
                      // @ts-ignore
                      onClick={handleNext}
                      disabled={isDisabledNextBtn}
                    >
                      Next
                    </StyledButton>
                  )}
                </ButtonsBlock>
              </ActionsPanel>
            </Content>
          </ContentBlock>
        </Container>
      </Container>
    </InfoPageLayout>
  );
};

export const StudyLessonPreviewScreen: FC = () => {
  const { lessonId, courseId } = useParams();

  return (
    <StudyLessonPreviewScreenContent
      key={lessonId}
      lessonId={Number(lessonId)}
      courseId={Number(courseId)}
    />
  );
};
