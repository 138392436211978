/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo } from 'react';
import { Container } from '../../../shared';
import { SubContainer } from './styled';
import {
  PageFeatures,
  PageFeaturesItem,
  PageText,
  PageTwoShapePanelsText,
} from '../../../pageBlocks';
import { Course } from '../../types';

export interface CourseContentItemProps {
  course: Course;
  data: {
    component: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
  };
}

export const CourseContentItem: FC<CourseContentItemProps> = (props) => {
  const {
    course,
    data: { component, params = {} },
  } = props;

  const { children: content, ...restProps } = params;

  const children = useMemo(() => {
    if (content && typeof content === 'object') {
      return <CourseContentItem course={course} data={content} />;
    }

    return content;
  }, [content]);

  if (
    ['you_will_learn', 'for_whom', 'supplier'].includes(component)
    && children
  ) {
    return (
      <Container>
        <SubContainer>
          <PageText {...restProps}>{children}</PageText>
        </SubContainer>
      </Container>
    );
  }

  if (['shapePanels'].includes(component)) {
    return (
      <Container>
        <SubContainer>
          <PageTwoShapePanelsText {...restProps}>
            {children}
          </PageTwoShapePanelsText>
        </SubContainer>
      </Container>
    );
  }

  if (['syllabus'].includes(component) && content?.length > 0) {
    return (
      <Container>
        <SubContainer>
          <PageFeatures {...restProps}>
            {children.props.data.map((x) => (
              <PageFeaturesItem {...x} />
            ))}
          </PageFeatures>
        </SubContainer>
      </Container>
    );
  }

  return null;
};
