import {
  apiClient,
} from '../../api';

export const finishUserCourseStep = async (
  userCourseId: number | string,
  stepId: number | string,
): Promise<unknown> => {
  const response = await apiClient.request({
    path: `/api/v1/user-course/${userCourseId}/step/${stepId}/finish/`,
    method: 'POST',
  });

  return response.data;
};
