import styled, { css } from 'styled-components';

export const Label = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.get(1)};
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 12px;
    letter-spacing: 0.32px;
    line-height: 200%;
    text-transform: uppercase;

    ${theme.breakpoints.up('md')} {
      font-size: 16px;
      line-height: 150%;
    }
  `,
);

export const InputWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: stretch;
    min-height: ${theme.spacing.get(12)};
    border-radius: 3px;

    color: #fff;

    background: #000;
    box-shadow: 4px 4px 10px 0px rgba(3, 251, 253, 0.50);

    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 12px;
    text-transform: uppercase;

    ${theme.breakpoints.up('md')} {
      font-size: 16px;
    }
    
    border: none;
    outline: none;
    overflow: hidden;
  `,
);

export const ErrorMessage = styled.div(
  ({ theme }) => css`
    padding-top: 6px;
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.24px;
    color: #f55;
  `,
);

export const HintMessage = styled.div(
  ({ theme }) => css`
    padding-top: 6px;
    color: #FFF;

    line-height: 17.5px; 
    font-family: ${theme.typography.fontFamilySecondary};;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-variant: all-small-caps;
    letter-spacing: 0.24px;
  `,
);

export const IconHolder = styled.div<{ position?: 'start' | 'end' }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: ${({ theme }) => theme.spacing.get(3)};

  ${({ theme, position }) => {
    if (position === 'start') {
      return css`
        padding-left: ${theme.spacing.get(3)};
        &:first-child {
          padding-left: ${theme.spacing.get(4)};
        }
      `;
    }
    if (position === 'end') {
      return css`
        padding-right: ${theme.spacing.get(3)};
        &:last-child {
          padding-right: ${theme.spacing.get(4)};
        }
      `;
    }
    return '';
  }}
`;

export const Clear = styled(IconHolder)`
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary100};
  }
`;

interface RootProps {
  focused?: boolean;
  empty?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  success?: boolean;
  color?: 'base' | 'primary';
  size?: 'sm' | 'md';
}

export const Root = styled.label<RootProps>(
  ({
    theme, focused, empty, disabled, error, fullWidth, success, color, size,
  }) => css`
    display: inline-flex;
    flex-direction: column;
    color: #fff;

    ${focused && !error && css`
      ${InputWrapper} {
      }

      ${Clear} {
        color: #20F7E6;
      }
    `};

    ${empty && css`
      /* color: ${theme.palette.neutral20}; */
      /* ${InputWrapper} {
        color: ${theme.palette.neutral20};
      } */
    `};

    ${disabled && css`
      color: #ccc;
      background-color: #aaa;
    `};

    ${error && css`
      color: #f55;

      ${InputWrapper} {
        box-shadow: 0 0 0 1px #f55;
      }
    `}

    /* ${success && css`
      ${InputWrapper} {
        box-shadow: 0 0 0 2px ${theme.palette.success100};
      }
    `} */
    
    ${fullWidth && css`
      width: 100%;

      ${InputWrapper} {
        width: 100%;
      }
    `}

    ${color === 'primary' && css`
      ${InputWrapper} {
        background-color: transparent;
        color: ${error ? '#f55' : '#03FBFD'};
        box-shadow: 0 0 0 1px ${error ? '#f55' : '#03FBFD'};
        
      }
    `}

    ${size === 'sm' && css`
      ${InputWrapper} {
          min-height: 30px;
        }
    `}
  `,
);
