import styled from 'styled-components';
import bottomLinesMobile from '../../../shared/assets/images/bottom-lines-mobile.png';
import bottomLinesDesktop from '../../../shared/assets/images/bottom-lines-desk.png';

export const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Title = styled.div`
  ${(p) => p.theme.typography.base};
  color: #FFF;
  font-size: 30px;
  line-height: 62.4px; /* 156% */
  letter-spacing: -0.6px;
  text-transform: uppercase;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const BottomLines = styled.div`
  width: 100%;
  margin-top: 40px;
  height: 40px;
  background-image: url(${bottomLinesMobile});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 60px;
    background-image: url(${bottomLinesDesktop});
  }
`;
