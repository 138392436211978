import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '../..';

export const AccountIndexScreen: FC = () => {
  const { currentUser } = useUserContext();

  if (!currentUser) {
    return null;
  }

  if (currentUser?.is_supplier) {
    return <Navigate replace to="/account/supplier" />;
  }

  return (
    <Navigate replace to="/account/profile" />
  );
};
