import {
  useState, type FC, useCallback, useEffect,
} from 'react';
import {
  CheckboxContainer,
  ErrorText,
  HiddenCheckbox,
  IconHolder,
  Label,
  StyledCheckbox,
} from './styled';
import { CheckMarkIcon, TimeIcon } from '../icons';

export interface CheckboxProps {
  label?: string;
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  onChange?: (value: boolean) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
  label = '',
  indeterminate = false,
  checked = false,
  disabled = false,
  onChange,
  error = false,
}) => {
  const [internalChecked, setInternalChecked] = useState(checked);

  const handleOnChange = useCallback(() => {
    if (disabled) {
      return;
    }
    const newChecked = !internalChecked;
    setInternalChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  }, [disabled, setInternalChecked, internalChecked]);

  useEffect(() => {
    setInternalChecked(checked);
  }, [checked]);

  return (
    <div>
      <CheckboxContainer onClick={handleOnChange}>
        <HiddenCheckbox checked={internalChecked} disabled={disabled} />
        <StyledCheckbox
          checked={internalChecked}
          indeterminate={indeterminate}
          disabled={disabled}
        >
          {internalChecked && (
            <IconHolder>
              <CheckMarkIcon color="inherit" />
            </IconHolder>
          )}
          {indeterminate && (
            <IconHolder>
              {/* #TODO: change icon if need indeterminate functionality  */}
              <TimeIcon color="inherit" />
            </IconHolder>
          )}
        </StyledCheckbox>
        {label && <Label disabled={disabled}>{label}</Label>}
      </CheckboxContainer>
      {error && (
        <ErrorText>{typeof error === 'string' ? error : 'Error'}</ErrorText>
      )}
    </div>
  );
};
