import { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Callout,
  Content,
  FooterWrapper,
  HeaderWrapper,
  MainScreen,
  Root,
} from '../../components/CourseContentItems/styled';
import {
  Container, FadeIn, Footer, Header,
} from '../../../shared';
import { useLoadCoursePreview } from '../../hooks';
import { CourseHeader } from '../../components';
import { CourseContentItem } from '../../components/CourseContentItems/CourseContentItems';
import { PageDivider } from '../../../pageBlocks';
import { ShapeButton, useBreakpoint } from '../../../ui';

export const CoursePreviewScreen: FC = () => {
  const { id } = useParams();
  const { result: item } = useLoadCoursePreview(id ? parseInt(id, 10) : -1);

  const isDesktop = useBreakpoint('up', 'md');

  const content = useMemo(() => {
    if (!item) {
      return null;
    }
    const youWillLearn = {
      params: {
        title: 'You will learn',
        children: item?.you_will_learn,
      },
      component: 'you_will_learn',
    };

    const shapePanels = {
      params: {
        text1: `${item?.user_started_count} people are studying right now`,
        text2: `${item?.user_finished_count} people completed the course`,
      },
      component: 'shapePanels',
    };

    const syllabus = {
      params: {
        title: 'Syllabus',
        children: item?.syllabus,
      },
      component: 'syllabus',
    };

    const supplier = {
      params: {
        image: item?.supplier_avatar?.image,
        title: 'Supplier',
        outline: true,
        children: item?.supplier_description,
      },
      component: 'supplier',
    };

    const forWhom = {
      params: {
        title: 'For whom',
        children: item?.for_whom,
      },
      component: 'for_whom',
    };

    const course_items = [
      shapePanels,
      youWillLearn,
      syllabus,
      supplier,
      forWhom,
    ];

    return course_items.map((x, index) => (
      <CourseContentItem
        course={item}
        // eslint-disable-next-line react/no-array-index-key
        key={`${x.component}_${index}`}
        data={x}
      />
    ));
  }, [item]);

  return (
    <Root>
      <MainScreen>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>

        <FadeIn active={Boolean(item)}>
          <Content>
            {item && (
              <>
                <Container>
                  <CourseHeader item={item} isPreview />
                </Container>
                {content}
                <div>
                  <PageDivider />
                  <Callout>
                    <ShapeButton
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      as={Link as any}
                      to={`/courses/${item.id}/preview/`}
                      hoverEffect="glitch"
                      color="gradient"
                      size={isDesktop ? 'xxxl' : 'xl'}
                    >
                      Start
                    </ShapeButton>
                  </Callout>
                  <PageDivider />
                </div>
              </>
            )}
          </Content>
        </FadeIn>
      </MainScreen>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
};
