import { RequestOptions, apiClient } from '../../api';
import { AuthData } from '../types';

export const signinImpersonate = async (
  _ = null,
  meta: Partial<RequestOptions> = {},
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: '/api/v1/accounts/impersonate/',
    method: 'POST',
    withAuth: false,
    credentials: 'include',
    ...meta,
  });

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh || null,
  };
};
