import { FC } from 'react';

export const ShapeButtonSvgPaths: FC = () => {
  return (
    <svg height="0" width="0" preserveAspectRatio="none">
      <defs>
        <clipPath
          id="button-shape-borders"
          clipPathUnits="objectBoundingBox"
          transform={`scale(${1 / 1647},${1 / 573})`}
        >
          <path
            className="border"
            fillRule="evenodd"
            clipRule="evenodd"
            filter="url(#filter)"
            fill="white"
            d="M1261.27 8.70974L1311.91 52.0297L1315.81 47.4704L1265.17 4.15039L1261.27 8.70974Z
                M1272.15 52.5497L1221.51 9.22976L1225.41 4.67041L1276.05 47.9904L1272.15 52.5497Z
                M1172.31 47.1699H55.6904V41.1699H1172.31V47.1699Z
                M1647.31 198.75L1567.7 132.44V527.83H1647.31V198.75ZM1586.46 423.95H1607.51V445H1586.46V423.95ZM1607.51 458.25H1586.46V479.3H1607.51V458.25ZM1586.46 492.55H1607.51V513.6H1586.46V492.55Z
                M74.7805 257.05L51.7305 238.34V282L74.7805 300.71V257.05Z
                M51.7305 300.71L74.7805 319.42V363.08L51.7305 344.37V300.71Z
                M74.7805 381.8L51.7305 363.08V406.75L74.7805 425.46V381.8Z
                M51.7305 425.46L74.7805 444.17V487.83L51.7305 469.12V425.46Z
                M1474.64 81.0298H1474.55L1474.36 80.8682L1474.22 81.0298H708.576L688.75 63.29H307.59L288.456 81.0298H17.5596V457.28L27.3841 464.965L87.25 512.963V513.34H87.7203L87.7834 513.391L87.8241 513.34H1549.75V513.6H1555.75V150.74H1555.38L1474.64 81.1103V81.0298ZM49.2716 87.0298H1472.32L1549.75 153.806V507.34H1487.16L1467.73 489.19H1273.29L1252.51 507.34H89.8285L44.7846 471.226L49.2716 87.0298Z
                M335.32 558.28V573.21H119.73L99.9297 552.86L1559.46 553.67V558.28H335.32Z
                M1348.95 52.0399L1298.3 8.71989L1302.2 4.16016L1352.85 47.4802L1348.95 52.0399Z
                M1338.25 9.22965L1388.9 52.5496L1392.8 47.9899L1342.15 4.66992L1338.25 9.22965Z
                M1431 52.0297L1380.36 8.70974L1384.26 4.15039L1434.9 47.4704L1431 52.0297Z
                M1418.02 9.22976L1468.66 52.5497L1472.56 47.9904L1421.92 4.67041L1418.02 9.22976Z"
          />
        </clipPath>
        <clipPath
          id="button-shape-background"
          clipPathUnits="objectBoundingBox"
          transform={`scale(${1 / 1647},${1 / 573})`}
        >
          <path
            fill="white"
            d="M-0.75 66.14L78.58 -1H1172L1251.73 66.14H1488.88L1567.39 132.44V334L1622.31 376V527.83H76.95L-0.75 460.71"
          />
        </clipPath>
        <filter id="filter">
          <feFlood floodColor="black" result="black" />
          <feFlood floodColor="red" result="flood1" />
          <feFlood floodColor="limegreen" result="flood2" />
          <feOffset in="SourceGraphic" dx="3" dy="0" result="off1a" />
          <feOffset in="SourceGraphic" dx="2" dy="0" result="off1b" />
          <feOffset in="SourceGraphic" dx="-3" dy="0" result="off2a" />
          <feOffset in="SourceGraphic" dx="-2" dy="0" result="off2b" />
          <feComposite in="flood1" in2="off1a" operator="in" result="comp1" />
          <feComposite in="flood2" in2="off2a" operator="in" result="comp2" />

          <feMerge x="0" width="100%" result="merge1">
            <feMergeNode in="black" />
            <feMergeNode in="comp1" />
            <feMergeNode in="off1b" />

            <animate
              attributeName="y"
              id="y"
              dur="4s"
              values="104px; 104px; 30px; 105px; 30px; 2px; 2px; 50px; 40px; 105px; 105px; 20px; 6ßpx; 40px; 104px; 40px; 70px; 10px; 30px; 104px; 102px"
              keyTimes="0; 0.362; 0.368; 0.421; 0.440; 0.477; 0.518; 0.564; 0.593; 0.613; 0.644; 0.693; 0.721; 0.736; 0.772; 0.818; 0.844; 0.894; 0.925; 0.939; 1"
              repeatCount="indefinite"
            />

            <animate
              attributeName="height"
              id="h"
              dur="4s"
              values="10px; 0px; 10px; 30px; 50px; 0px; 10px; 0px; 0px; 0px; 10px; 50px; 40px; 0px; 0px; 0px; 40px; 30px; 10px; 0px; 50px"
              keyTimes="0; 0.362; 0.368; 0.421; 0.440; 0.477; 0.518; 0.564; 0.593; 0.613; 0.644; 0.693; 0.721; 0.736; 0.772; 0.818; 0.844; 0.894; 0.925; 0.939; 1"
              repeatCount="indefinite"
            />
          </feMerge>

          <feMerge x="0" width="100%" y="60px" height="65px" result="merge2">
            <feMergeNode in="black" />
            <feMergeNode in="comp2" />
            <feMergeNode in="off2b" />

            <animate
              attributeName="y"
              id="y"
              dur="4s"
              values="103px; 104px; 69px; 53px; 42px; 104px; 78px; 89px; 96px; 100px; 67px; 50px; 96px; 66px; 88px; 42px; 13px; 100px; 100px; 104px;"
              keyTimes="0; 0.055; 0.100; 0.125; 0.159; 0.182; 0.202; 0.236; 0.268; 0.326; 0.357; 0.400; 0.408; 0.461; 0.493; 0.513; 0.548; 0.577; 0.613; 1"
              repeatCount="indefinite"
            />

            <animate
              attributeName="height"
              id="h"
              dur="4s"
              values="0px; 0px; 0px; 16px; 16px; 12px; 12px; 0px; 0px; 5px; 10px; 22px; 33px; 11px; 0px; 0px; 10px"
              keyTimes="0; 0.055; 0.100; 0.125; 0.159; 0.182; 0.202; 0.236; 0.268; 0.326; 0.357; 0.400; 0.408; 0.461; 0.493; 0.513;  1"
              repeatCount="indefinite"
            />
          </feMerge>

          <feMerge>
            <feMergeNode in="SourceGraphic" />

            <feMergeNode in="merge1" />
            <feMergeNode in="merge2" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  );
};
