import { FC, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import { InputWrapper, Root, Row } from './styled';
import { Button, FormTextInput, useBreakpoint } from '../../../ui';
import { useUpdateSupplier, useUserContext } from '../../../auth';
import { FieldHint } from '../../../auth/screens/SignupPartnerScreen/styled';

const schema = yup
  .object({
    teaching_info: yup.string().required('Required'),
  })
  .required();

export const SupplierSettingsAboutScreen: FC = () => {
  const { currentUser, refresh } = useUserContext();
  const { result, isLoading, request } = useUpdateSupplier();
  const isDesktop = useBreakpoint('up', 'md');

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema as any),
  });

  const onSubmit = useCallback(
    (data) => {
      request(data);
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      refresh();
      toast('Information successfully updated', { type: 'success' });
    }
  }, [result]);

  useEffect(() => {
    if (currentUser && currentUser.supplier_info) {
      setValue(
        'supplier.teaching_info',
        currentUser.supplier_info.teaching_info || '',
      );
    }
  }, [currentUser]);

  return (
    <ProfileSettingsLayout>
      <ShapeCard title="SUPPLIER DESCRIPTION. WHAT ARE YOU TEACHING ABOUT?">
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row />
          <Row />
          <Row>
            <InputWrapper>
              <FormTextInput
                color="primary"
                size={isDesktop ? 'md' : 'sm'}
                name="supplier.teaching_info"
                fullWidth
                multiline
                control={control}
                className="teaching_info"
              />
              <FieldHint>
                please, tell us areas you are teaching about. EG IT, Design,
                etc. (500 symbols max)
              </FieldHint>
            </InputWrapper>
          </Row>

          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save changes
          </Button>
          <Row />
          <Row />
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
