import * as React from 'react';
import type { SVGProps } from 'react';

const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M22.005 10.364c-3.267-.002-6.534-.001-9.801 0 0 1.354-.002 2.71 0 4.064h5.676a4.892 4.892 0 0 1-2.085 3.217 5.953 5.953 0 0 1-2.293.905c-.82.14-1.668.158-2.486-.007a6.118 6.118 0 0 1-2.318-1.002 6.471 6.471 0 0 1-2.387-3.198 6.308 6.308 0 0 1 .002-4.056 6.463 6.463 0 0 1 1.511-2.455 6.2 6.2 0 0 1 3.11-1.728 6.168 6.168 0 0 1 3.059.125 5.645 5.645 0 0 1 2.245 1.32c.64-.638 1.277-1.278 1.916-1.916.336-.343.688-.671 1.012-1.024a10.08 10.08 0 0 0-3.351-2.077 10.532 10.532 0 0 0-7.016-.06 10.46 10.46 0 0 0-5.9 5.167c-.417.827-.722 1.71-.905 2.618-.46 2.262-.14 4.674.904 6.734a10.493 10.493 0 0 0 2.83 3.478 10.368 10.368 0 0 0 3.784 1.909c1.735.465 3.582.454 5.328.057 1.579-.363 3.072-1.118 4.264-2.218 1.26-1.159 2.16-2.684 2.635-4.324.52-1.789.591-3.698.266-5.53Z"
    />
  </svg>
);
export default SvgGoogle;
