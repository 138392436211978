import { styled } from 'styled-components';
import { CornerCard } from '../../../shared';
import shape from '../../../shared/assets/shapes/angle-line.svg';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  ${(p) => p.theme.breakpoints.up('md')} {
    align-items: center;
  }
`;

export const ContentCard = styled(CornerCard)`
  width: 100%;
  padding: 20px 0;
  max-width: 800px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 56px;
    line-height: 62.4px;
    letter-spacing: -0.44px;
    min-height: 250px;
  }
`;

export const LinksWrapper = styled.div`
  padding: 60px 0 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 80px;

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const LinkItem = styled.div`
  position: relative;
  font-size: 18px;
  text-align: center;

  div {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    text-shadow: 0px 0px 31px transparent;
    transform: scale(1);
    transition: all .3s ease-in-out;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: calc(100% + 10px);
    background-image: url(${shape});
    background-size: cover;
    width: 110px;
    aspect-ratio: ${235 / 140};
  }


  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 24px;
    letter-spacing: 0.48px;

    &:before {
      width: 230px;
      top: 50%;
      right: calc(100% + 25px);
    }

    margin-top: 40px;
  }
`;

export const WarningItem = styled(LinkItem)`
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;

  &:before {
    right: auto;
    top: auto;
    bottom: -10px;
    left: calc(100% - 50px);
    transform: scale(-1);
  }

  div {
    display: flex;
    text-transform: none;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    img {
      width: 100px;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-top: -80px;
    margin-bottom: 40px;
    margin-right: -140px;
    margin-left: 140px;
    font-size: 16px;

    div {
      gap: 24px;
      img {
        width: 200px;
      }
    }

    &:before {
      width: 230px;
      bottom: -24px;
      left: calc(100% - 80px);
    }
  }
`;
