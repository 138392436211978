/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_ERROR_MESSAGES } from './constants';

export class RequestError extends Error {
  statusCode: number | null = null;

  statusMessage: string | null = null;

  headers: Headers | null = null;

  data: any | null = null;

  constructor(
    statusCode?: number | null,
    headers?: Headers | null,
    data?: any | null,
  ) {
    let message = 'Unknown error';

    if (data) {
      if (typeof data === 'string') {
        message = data;
      } else if (data.message) {
        message = data.message;
      } else if (data.detail) {
        message = data.detail;
      } else {
        message = JSON.stringify(data);
      }
    } else if (statusCode === null || statusCode === undefined) {
      message = 'Connection failed';
    } else if (statusCode in HTTP_ERROR_MESSAGES) {
      message = HTTP_ERROR_MESSAGES[statusCode];
    }

    super(message);
    Object.setPrototypeOf(this, RequestError.prototype);
    this.name = this.constructor.name;

    this.statusCode = statusCode || null;
    if (statusCode && statusCode in HTTP_ERROR_MESSAGES) {
      this.statusMessage = HTTP_ERROR_MESSAGES[statusCode];
    }
    this.headers = headers || null;
    this.data = data;
  }
}
