import { Suspense, useCallback, useRef } from 'react';
import {
  Outlet, Navigate, useLocation,
} from 'react-router-dom';
import { AuthorizedLayout } from '../layouts';
import { useAuthContext, useUserContext } from '../../auth';
import { tempRedirectStore } from './UnauthorizedContainer';

const customRedirects = {
  '/account/request-course': '/auth/signup',
};

export const AuthorizedContainer = () => {
  const { pathname } = useLocation();
  const { authorized, initialized } = useAuthContext();
  const { currentUser } = useUserContext();
  const hasAuthorized = useRef(false);

  useCallback(() => {
    if (authorized) {
      hasAuthorized.current = true;
    }
  }, [authorized]);

  if (!initialized) {
    return null;
  }

  if (!authorized) {
    if (!hasAuthorized.current) {
      tempRedirectStore.redirect = window.location.href.replace(window.location.origin, '');
    }
    let to = hasAuthorized.current ? '/' : '/identify';
    if (pathname in customRedirects) {
      to = customRedirects[pathname];
    }
    return <Navigate replace to={to} />;
  }

  if (!currentUser) {
    return null;
  }

  tempRedirectStore.redirect = null;

  return (
    <AuthorizedLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </AuthorizedLayout>
  );
};
