/* eslint-disable */
export const initGoogleStcript = () => {
  (function (d, s, id) {
    if (d.getElementById(id)) return;
    var js = d.createElement(s);
    js.id = id;
    js.src = 'https://accounts.google.com/gsi/client';
    document.body.append(js);
  })(document, 'script', 'google-auth');
};
