import { styled } from 'styled-components';

export const Root = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(1)};
  padding: ${(p) => p.theme.spacing.get(1, 1.25)};
  border-radius: 20px;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 8px;
  line-height: 12px;
  background: #fff; 
  color: #000000;
  white-space: nowrap;
  text-transform: capitalize;

  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 12px;
    line-height: 16px;
    padding: ${(p) => p.theme.spacing.get(1, 3)};
  }
`;

export const IconWrapper = styled.div`
  font-size: 12px;
  line-height: 0;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;
