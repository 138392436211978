/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, InfoPageLayout } from '../../../shared';
import {
  FaqWrapper, Paragrapth, Subtitle, Title,
} from './styled';

export const FaqScreen: FC = () => {
  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradient">
      <Container>
        <Title>Contact</Title>
        <Subtitle>here you can find the list of frequently asked questions</Subtitle>
        <Paragrapth>
          If you still need to contact us, please, get in touch by clicking
          {' '}
          <a href="https://www.rroaded.co.uk/" target="_blank" rel="noreferrer">here</a>
          . You will be redirected to our website where you can find the contact form. Or if you want  to  talk with us via online chat, please click
          {' '}
          <Link to="/account/support">here</Link>
          .
          <br />
          You can also email us on supporrt@rroaded.co.uk or parrtner@rroaded.co.uk
        </Paragrapth>
        <FaqWrapper>
          <Paragrapth>
            1. How to become our partner?
            <br />
            You can find the link in the menu or access partner registration page from
            {' '}
            <Link to="/partner">here</Link>
            .
          </Paragrapth>

          <Paragrapth>
            2. I didn’t find the course i want, what do I do?
            <br />
            You can use “request a course” button on the course page.
          </Paragrapth>

          <Paragrapth>
            3. What if I am having problems with logging in?
            <br />
            Please check if all details are correct. If you are sure that you’ve typed everything correctly, please try resetting your password. If this is not helping, contact us by clicking
            {' '}
            <a href="https://www.rroaded.co.uk/" target="_blank" rel="noreferrer">here</a>
            . You will be redirected to the contact form. You can also email us on supporrt@rroaded.co.uk or parrtner@rroaded.co.uk
          </Paragrapth>

          <Paragrapth>
            4. How do I change my username?
            <br />
            You can change you username by going to My Account -&gt; Settings -&gt; Account. You will have to wait until the moderator will approve this change according to our policy
          </Paragrapth>

          <Paragrapth>
            5. How do I order a personalised avatar?
            <br />
            You can leave your request  on our website rroaded.co.uk. A member from our design department will be in touch with you to discuss you wishes and calculate the price accordingly. You can also wright to our partner’s website
            {' '}
            <a href="https://www.theastra-web.co.uk/" target="_blank" rel="noreferrer">theastra-web.co.uk</a>
          </Paragrapth>
        </FaqWrapper>

        <Paragrapth>
          If you still need to contact us, please, get in touch by clicking
          {' '}
          <a href="https://www.rroaded.co.uk/" target="_blank" rel="noreferrer">here</a>
          . You will be redirected to our website where you can find the contact form. Or if you want  to  talk with us via online chat, please click here.
          <br />
          You can also email us on supporrt@rroaded.co.uk or parrtner@rroaded.co.uk
        </Paragrapth>
      </Container>
    </InfoPageLayout>
  );
};
