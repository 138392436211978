import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomLines,
  ContentCard,
  ContentWrapper,
  Radar,
  Root,
} from './styles';
import { FadeIn, InfoPageLayout, NavBoard } from '../../../shared';
import { useUserContext } from '../../../auth';

export const WelcomeScreen: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const timerRef = useRef();

  useEffect(() => {
    clearTimeout(timerRef.current);
    setTimeout(() => {
      if (currentUser?.is_supplier) {
        if (
          !currentUser?.supplier_info?.is_verified
          || (currentUser?.supplier_info?.is_stripe_enabled
            && !currentUser?.supplier_info?.is_stripe_verified)
        ) {
          navigate('/account/verify');
          return;
        }
        navigate('/account/supplier');
      }
      navigate('/account/profile');
    }, 5000);
  }, [
    navigate,
    currentUser,
  ]);

  return (
    <InfoPageLayout
      bodyBg="darkGradientInverse"
      bg="spaceDark"
      headerProps={{ position: 'absolute' }}
    >
      <FadeIn>
        <Root>
          <Radar />
          <ContentWrapper>
            <NavBoard>
              <ContentCard>
                Welcome,
                <br />
                {currentUser?.username}
              </ContentCard>
            </NavBoard>
          </ContentWrapper>
          <BottomLines />
        </Root>
      </FadeIn>
    </InfoPageLayout>
  );
};
