import {
  ChangeEvent,
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfileLayout } from '../../layouts';
import { useUpdateSupplierLogo, useUserContext } from '../../../auth';
import { SupplierLogoBoard } from '../../components';
import { Button } from '../../../ui';
import {
  BottomLines, ButtonsWrapper, ContentWrapper, Root, Title,
} from './styles';
import { config } from '../../../../config';

export const SupplierLogoScreen: FC = () => {
  const { currentUser, refresh } = useUserContext();
  const navigate = useNavigate();
  const { result, request } = useUpdateSupplierLogo();
  const [value, setValue] = useState<File | null>(null);

  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files?.length > 0) {
      const file = event.currentTarget.files[0];
      if (file.size > config.apiFileSizeLimit) {
        toast(`The file size should not exceed ${config.apiFileSizeLimitText}`, {
          type: 'error',
        });
        return;
      }
      setValue(file);
    }
  }, []);

  const submit = useCallback(() => {
    if (value) {
      request({
        file: value,
      });
    }
  }, [value]);

  useEffect(() => {
    if (result) {
      refresh();
      navigate('/account/welcome');
    }
  }, [result, refresh]);

  const image = useMemo(() => {
    if (value) {
      return URL.createObjectURL(value);
    }
    return null;
  }, [value]);

  return (
    <ProfileLayout bodyBg="gradient" showTabs={false}>
      <Root>
        <div />
        <ContentWrapper>
          <Title>Please upload photo/logo</Title>
          <SupplierLogoBoard
            image={image || currentUser?.avatar?.image}
          />
          <ButtonsWrapper>
            <Button as="label" htmlFor="uploadSupplierLogo">Upload new</Button>
            <Button onClick={submit} disabled={!value && !currentUser?.avatar?.image}>Save</Button>
          </ButtonsWrapper>
        </ContentWrapper>
        <div style={{ display: 'none' }}>
          <input id="uploadSupplierLogo" type="file" accept="image/*" onChange={onChangeFile} />
        </div>
        <BottomLines />
      </Root>
    </ProfileLayout>
  );
};
