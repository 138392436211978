import {
  apiClient,
} from '../../api';
import { Ticket } from '../types';

export const loadTicket = async (
  id: number | string,
): Promise<Ticket> => {
  const response = await apiClient.request({
    path: `/api/v1/communication/ticket/${id}/`,
    method: 'GET',
  });

  return response.data;
};
