import { FC, ReactNode } from 'react';
import { Root, Title, Content } from './styled';

export interface InfoCardProps {
  title: ReactNode;
  content: ReactNode;
  action?: ReactNode;
}

export const InfoCard: FC<InfoCardProps> = (props) => {
  const {
    title,
    content,
    action,
  } = props;
  return (
    <Root>
      <Title>
        {title}
      </Title>
      <Content>
        {content}
      </Content>
      {action}
    </Root>
  );
};
