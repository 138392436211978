import { FC, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import {
  InputWrapper, Label, Root, Row,
} from './styled';
import { Button, FormTextInput, useBreakpoint } from '../../../ui';
import { useUpdateSupplier, useUserContext } from '../../../auth';

const schemaCompany = yup
  .object({
    organization_name: yup.string().required('Required'),
    registration_number: yup.string().optional().nullable(),
    vat_number: yup.string().optional().nullable(),
  })
  .required();

const schemaIndividual = yup
  .object({
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
    registration_number: yup.string().optional().nullable(),
    vat_number: yup.string().optional().nullable(),
  })
  .required();

export const SupplierSettingsMainScreen: FC = () => {
  const { currentUser, refresh } = useUserContext();
  const { result, isLoading, request } = useUpdateSupplier();
  const isDesktop = useBreakpoint('up', 'md');

  const currentSchema = currentUser?.supplier_info?.type === 'individual'
    ? schemaIndividual
    : schemaCompany;

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(currentSchema as any),
  });

  const onSubmit = useCallback(
    (data) => {
      request(data);
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      refresh();
      toast('Information successfully updated', { type: 'success' });
    }
  }, [result]);

  useEffect(() => {
    if (currentUser) {
      setValue('first_name', currentUser.first_name || '');
      setValue('last_name', currentUser.last_name || '');
      if (currentUser.supplier_info) {
        setValue(
          'organization_name',
          currentUser.supplier_info.organization_name || '',
        );
        setValue(
          'registration_number',
          currentUser.supplier_info.registration_number || '',
        );
        setValue('vat_number', currentUser.supplier_info.vat_number || '');
      }
    }
  }, [currentUser]);

  return (
    <ProfileSettingsLayout>
      <ShapeCard>
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row />
          <Row />
          {currentUser?.supplier_info?.type === 'individual' ? (
            <>
              {' '}
              <Row>
                <Label>First name</Label>
                <InputWrapper>
                  <FormTextInput
                    color="primary"
                    size={isDesktop ? 'md' : 'sm'}
                    reserveSpaceForError // ={!isDesktop}
                    name="first_name"
                    fullWidth
                    control={control}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <Label>Last name</Label>
                <InputWrapper>
                  <FormTextInput
                    color="primary"
                    size={isDesktop ? 'md' : 'sm'}
                    reserveSpaceForError // ={!isDesktop}
                    name="last_name"
                    fullWidth
                    control={control}
                  />
                </InputWrapper>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Label>Company name</Label>
                <InputWrapper>
                  <FormTextInput
                    color="primary"
                    size={isDesktop ? 'md' : 'sm'}
                    reserveSpaceForError // ={!isDesktop}
                    name="organization_name"
                    fullWidth
                    control={control}
                    hint="this is your username"
                  />
                </InputWrapper>
              </Row>
              <Row>
                <Label>Registration number</Label>
                <InputWrapper>
                  <FormTextInput
                    color="primary"
                    size={isDesktop ? 'md' : 'sm'}
                    reserveSpaceForError // ={!isDesktop}
                    name="registration_number"
                    fullWidth
                    control={control}
                    hint="if applicable"
                  />
                </InputWrapper>
              </Row>
              <Row>
                <Label>VAT number</Label>
                <InputWrapper>
                  <FormTextInput
                    color="primary"
                    size={isDesktop ? 'md' : 'sm'}
                    reserveSpaceForError // ={!isDesktop}
                    name="vat_number"
                    fullWidth
                    control={control}
                    hint="if applicable"
                  />
                </InputWrapper>
              </Row>
            </>
          )}

          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save changes
          </Button>
          <Row />
          <Row />
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
