import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { UserCourseStep } from '../types';

export interface LoadUserCourseStepsFilters {
}

export interface LoadUserCourseStepsData extends RequestListParams<LoadUserCourseStepsFilters> {
  userCourseId: string | number;
}

export const loadUserCourseSteps = async (
  data: LoadUserCourseStepsData,
): Promise<RequestPagingResponse<UserCourseStep>> => {
  const { userCourseId, ...rest } = data;
  const params = apiRequestListParamsMapper(rest);
  const response = await apiClient.request({
    path: `/api/v1/user-course/${userCourseId}/step/`,
    method: 'GET',
    params,
  });

  return apiResponsePagingMapper<UserCourseStep>(response.data);
};
