import { css, styled } from 'styled-components';
import backgroundHeader from '../../assets/images/background-header.png';

export const Root = styled('div')<{ position?: 'static' | 'absolute' | 'sticky', background?:boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background: ${({ background }) => background && `url(${backgroundHeader}) center / auto  repeat`};

  ${(p) => p.theme.breakpoints.up('md')} {
    min-height: 114px;
    padding: 0 36px;
  }

  ${({ position = 'static' }) => {
    if (position === 'sticky' || position === 'absolute') {
      return css`
        position: ${position};
        top: 0;
        right: 0;
        left: 0;
      `;
    }
    return null;
  }}
`;

export const LogoWrapper = styled.div`
  display: block;
  height: 54px;
  aspect-ratio: ${1701 / 425};
  transform: translateX(-4%);

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 84px;
  }
 ${(p) => p.theme.breakpoints.down('mobile')} {
    height:auto;
     aspect-ratio: 0;
  }
`;
