import { apiClient } from '../../api';
import { UserCourseStep } from '../types';

export const checkUserCourseAnswers = async (
  userCourseId: number | string,
): Promise<UserCourseStep[]> => {
  const response = await apiClient.request({
    path: `/api/v1/user-course/${userCourseId}/check-answers/`,
    method: 'POST',
  });

  return response.data;
};
