import { RequestOptions, apiClient } from '../../api';
import { AuthData } from '../types';

export const signinGoogleRedirect = async (
  data: string,
  meta: Partial<RequestOptions> = {},
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: `/api/v1/accounts/login/google_sdk/${data}`,
    method: 'GET',
    withAuth: false,
    ...meta,
  });

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh || null,
  };
};
