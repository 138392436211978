import { RequestOptions, apiClient } from '../../api';
import { AuthData } from '../types';

export interface SigninData {
  email: string;
  password: string;
}

export const signin = async (
  data: SigninData,
  meta: Partial<RequestOptions> = {},
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: '/api/v1/token/',
    method: 'POST',
    body: data,
    withAuth: false,
    ...meta,
  });

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh || null,
  };
};
