import styled, { css } from 'styled-components';

export const List = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
  grid-template-rows: repeat(auto-fill);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  padding-bottom: 40px;

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  grid-row-gap: 40px;
  grid-column-gap: 40px;
  }
`;

export const ListItem = styled.div<{ selected?: boolean; src?: string }>`
  position: relative;
  aspect-ratio: 1;
  /* border: 4px solid #03FBFD; */
  border-radius: 3px;
  /* background: #D9D9D9; */
  box-shadow: 4px 8px 8px 14px rgba(0, 0, 0, 0.25);
  transition: transform .3s ease-in-out;
  overflow: hidden;
  cursor: pointer;

  ${({ src }) => src && css`
    background-image: url(${src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}

  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    border: 4px solid #03FBFD;
    border-radius: 3px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: -10px;
    border: 20px solid #03FBFD;
    border-radius: 3px;
    transform: scale(1.1);
    transition: transform .3s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
  }

  ${({ selected }) => selected && css`
    &::after {
      transform: scale(1);
    }
  `}
`;
