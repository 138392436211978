import {
  ChangeEvent,
  FC, useCallback, useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { useUpdateSupplierLogo, useUserContext } from '../../../auth';
import { Button } from '../../../ui';
import { SupplierLogoBoard } from '../../components';
import { ContentWrapper } from './styles';
import { config } from '../../../../config';

export const SupplierSettingsLogoScreen: FC = () => {
  const { currentUser, refresh } = useUserContext();
  const { result, request } = useUpdateSupplierLogo();

  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files?.length > 0) {
      const file = event.currentTarget.files[0];
      if (file.size > config.apiFileSizeLimit) {
        toast(`The file size should not exceed ${config.apiFileSizeLimitText}`, {
          type: 'error',
        });
        return;
      }
      request({
        file,
      });
    }
  }, []);

  useEffect(() => {
    if (result) {
      refresh();
    }
  }, [result, refresh]);

  return (
    <ProfileSettingsLayout>
      <ContentWrapper>
        <SupplierLogoBoard
          image={currentUser?.avatar?.image}
          title="Your current image"
        />
        <Button as="label" htmlFor="uploadSupplierLogo">Upload new</Button>
      </ContentWrapper>
      <div style={{ display: 'none' }}>
        <input id="uploadSupplierLogo" type="file" accept="image/*" onChange={onChangeFile} />
      </div>
    </ProfileSettingsLayout>
  );
};
