import { styled } from 'styled-components';

const defaultBackground = 'linear-gradient(180deg, #9747FF 0%, #CF47FF 33.33%, #9747FF 66.67%, rgba(255, 13, 255, 0.00) 100%)';

export const Root = styled.div < { background?: string | null } >`
  width: 100%;
  height: 100%;
  padding: 48px 24px 68px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  background: ${(p) => p.background || defaultBackground};
  transform: scale(1);
  transition: transform .3s ease-in-out;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.05);
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 80px 24px;
    &:hover {
      transform: scale(1.02);
    }
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 104px;
  object-fit: contain;
  object-position: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    height: 240px;
  }
`;

export const Name = styled.div`
  ${(p) => p.theme.typography.base};
  text-align: center;
  font-size: 12px;
  color: #03FBFD;
  letter-spacing: -0.18px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    line-height: 64px;
    font-size: 20px;
  }
`;
