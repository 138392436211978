import { styled } from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const StyledCarousel = styled(Slider)`
  .slick-slide > div {
      margin: 0 ${(p) => p.theme.spacing.get(2)};
  }
  .slick-list {
      margin: 0 ${(p) => p.theme.spacing.get(-2)};
  }
  .slick-next {
    right: 5px;
  }
  .slick-prev {
    left: 5px;
    z-index: 1;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    .slick-next {
      right: -25px;
    }
    .slick-prev {
      left: -25px;
    }
  }
`;
