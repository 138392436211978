import React, { FC, useMemo } from 'react';
import { Item, Root } from './styled';
import { StarFilledIcon } from '../icons';

export interface RatingViewProps {
  value?: number;
  totalItems?: number;
}

export const RatingView: FC<RatingViewProps> = ({
  value = 0,
  totalItems = 5,
}) => {
  const rate = value ? value / totalItems : 0;

  const fixedValue = useMemo(() => {
    let [integer, fraction] = (rate * totalItems).toFixed(1).split('.');
    fraction = +fraction > 5 ? '5' : '0';
    return parseFloat(`${integer}.${fraction}`);
  }, [rate, totalItems]);

  const items = useMemo(
    () => Array(totalItems)
      .fill(0)
      .map((_, i) => {
        const starGradient = fixedValue - i;
        if (starGradient < 0) {
          return 0;
        }
        if (starGradient >= 1) {
          return 100;
        }
        return starGradient * 100;
      }),
    [totalItems, fixedValue],
  );

  return (
    <Root>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Item key={index} isActive={item === 100}>
          <StarFilledIcon stopOffSet={item} />
        </Item>
      ))}
    </Root>
  );
};
