import {
  FC,
} from 'react';
import { ProfileLayout } from '../../layouts';
import { Container, FadeIn } from '../../../shared';
import { GlitchWrapper } from '../../../ui';
import {
  ComingSoon,
  Content,
} from './styles';

export const SupplierStatsScreen: FC = () => {
  return (
    <ProfileLayout bodyBg="gradient">
      <FadeIn>
        <Container>
          <FadeIn>
            <Content>
              <ComingSoon>
                <GlitchWrapper playMode="always" shake>
                  <div>Coming soon</div>
                </GlitchWrapper>
              </ComingSoon>
            </Content>
          </FadeIn>
        </Container>
      </FadeIn>
    </ProfileLayout>
  );
};
