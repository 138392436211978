import React, { FC } from 'react';
import {
  ProgressBarWrapper,
  ProgressBarIndex,
  ProgressBarLine,
  Serif,
} from './styled';

interface ProgressBarProps {
  arrIndex: number[];
  currentIndex: number;
  isFinal?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { arrIndex, currentIndex, isFinal } = props;

  return (
    <ProgressBarWrapper size={arrIndex.length + 1}>
      {arrIndex.map((item, index) => {
        return arrIndex?.length > 0 && index + 1 === arrIndex?.length ? (
          <ProgressBarIndex isLast size={arrIndex.length + 1} key={item}>
            <Serif isActive={isFinal || item === currentIndex} />
            {item}
          </ProgressBarIndex>
        ) : (
          <ProgressBarLine
            isActive={isFinal || item < currentIndex}
            key={item}
          >
            <ProgressBarIndex>
              <Serif isActive={item === currentIndex} />
              {item}
            </ProgressBarIndex>
          </ProgressBarLine>
        );
      })}
    </ProgressBarWrapper>
  );
};
