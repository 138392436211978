import * as React from 'react';
import type { SVGProps } from 'react';

const SvgEyeNo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.707 1.293 1.293 2.707l4.065 4.065c-.379.314-.754.65-1.124 1.01-.83.801-1.58 1.657-2.24 2.514-.399.517-.682.923-.836 1.165L.813 12l.345.539c.154.242.437.649.836 1.165.66.857 1.41 1.713 2.24 2.515C6.664 18.569 9.272 20 12 20c1.72 0 3.394-.57 5.006-1.58l4.287 4.287 1.414-1.414-20-20Zm12.838 15.666-1.513-1.513a4 4 0 0 1-5.478-5.478L6.78 8.194c-.388.312-.773.655-1.156 1.025A21.325 21.325 0 0 0 3.216 12a21.329 21.329 0 0 0 2.408 2.781C7.717 16.806 9.894 18 12 18c1.168 0 2.359-.368 3.545-1.041Zm-5.477-5.477a2 2 0 0 0 2.45 2.45l-2.45-2.45Zm10.03 4.409-1.414-1.414a21.565 21.565 0 0 0 2.1-2.477 21.32 21.32 0 0 0-2.408-2.781C16.282 7.194 14.106 6 12 6c-.522 0-1.05.074-1.578.215L8.84 4.633C9.873 4.221 10.927 4 12 4c2.727 0 5.336 1.43 7.766 3.781a23.31 23.31 0 0 1 2.24 2.515c.399.517.681.923.836 1.165l.345.539-.345.539c-.154.242-.437.649-.836 1.165-.571.741-1.21 1.482-1.908 2.187Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEyeNo;
