import { RequestOptions, apiClient } from '../../api';

export interface CreateTicketMessageData {
  ticketId: string | number;
  message: string;
  attachments?: File[]
}

export const createTicketMessage = async (
  data: CreateTicketMessageData,
  meta: Partial<RequestOptions> = {},
): Promise<object> => {
  const { ticketId, attachments, ...rest } = data;

  const formData = new FormData();
  if (attachments?.length) {
    attachments.forEach((file) => {
      formData.append('attachments', file);
    });
  }

  Object.keys(rest).forEach((key) => {
    formData.append(key, rest[key]);
  });

  const response = await apiClient.request({
    path: `/api/v1/communication/ticket/${ticketId}/post/`,
    method: 'POST',
    contentType: 'formData',
    withAuth: true,
    body: formData,
    ...meta,
  });

  return response.data || {};
};
