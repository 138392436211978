import { FC } from 'react';
import { Item, Root } from './styled';

interface YesNoInputProps {
  value?: boolean | null;
  onChange?: (value: boolean) => void;
}

export const YesNoInput: FC<YesNoInputProps> = (props) => {
  const { value, onChange } = props;

  return (
    <Root>
      <Item checked={value === true} onClick={() => onChange?.(true)}>Yes</Item>
      <Item checked={value === false} onClick={() => onChange?.(false)}>No</Item>
    </Root>
  );
};
