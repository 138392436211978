import { styled } from 'styled-components';
import { CornerCard } from '../../../shared';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  ${(p) => p.theme.breakpoints.up('md')} {
    align-items: center;
  }
`;

export const ContentCard = styled(CornerCard)`
  width: 100%;
  padding: 20px 0;
  max-width: 800px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 56px;
    line-height: 62.4px;
    letter-spacing: -0.44px;
    min-height: 250px;
  }
`;
