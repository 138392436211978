import { FC } from 'react';
import {
  Content, ContentCard,
} from './styled';
import { AuthPartnerLayout } from '../../../auth/layouts';

export const SupplierVerificationPendingScreen: FC = () => {
  return (
    <AuthPartnerLayout>
      <Content>
        <ContentCard>
          Verification pending
          <br />
          please wait
        </ContentCard>
      </Content>
    </AuthPartnerLayout>
  );
};
