import { styled } from 'styled-components';
import shape from './shape.svg';

export const Root = styled.div`
`;

export const Title = styled.div`
  padding: ${(p) => p.theme.spacing.get(0, 8)};
  ${(p) => p.theme.typography.base};
  font-size: 26px;
  letter-spacing: -0.39px;
  text-transform: uppercase;
  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;

export const Items = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: ${1506 / 163};
  display: grid;
  grid-template-rows: 50% 50%;
  align-content: center;
  justify-items: start;
  align-items: center;
  grid-auto-flow: column dense;
  padding: ${(p) => p.theme.spacing.get(0, 8)};

  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 -7% -8% 0;
    background-image: url(${shape});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
  }
`;

export const Item = styled.div`
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  text-transform: uppercase;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.48px;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 24px;
  }
`;
