import { styled } from 'styled-components';

export const Root = styled.div`
  width: 100%;
`;

export const MainScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.get(4)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const Content = styled.div``;

export const SliderWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(4)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(15)};
  }
`;

export const TabsWrapper = styled.div`
  display: none;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: flex;
    margin-bottom: ${(p) => p.theme.spacing.get(15)};
  }
`;

export const FiltersWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(10)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(15)};
  }
`;

export const ItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.get(10)};

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: ${(p) => p.theme.spacing.get(10)};
  }
`;

export const Items = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill);
  grid-row-gap: ${(p) => p.theme.spacing.get(4)};
  grid-column-gap: ${(p) => p.theme.spacing.get(4)};
  padding-bottom: 40px;

  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: ${(p) => p.theme.spacing.get(7)};
    grid-column-gap: ${(p) => p.theme.spacing.get(6)};
  }
  ${(p) => p.theme.breakpoints.down('mobile')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.div`
  text-decoration: none;
  position: relative;

  /* ${(p) => p.theme.breakpoints.up('md')} {
    width: 400px;
    height: 500px;
  } */
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 300px;
  color: #fff;
`;

export const FooterWrapper = styled.div``;

export const Price = styled.div`
  display: none;
  padding: 5px 30px;
  border-radius: 3px;
  border: 1px solid #03fbfd;
  color: #fff;
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LikeActionText = styled.span`
  display: none;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: inline;
  }
`;
