/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from 'react';
import { QuestionBtn, QuestionBtnBlock } from './styled';
import { TQuestionsTest } from '../../../catalog';

interface QuestionButtonsBlockProps {
  questions: TQuestionsTest[];
  onSetAnswers?: (id: number[]) => void;
  answers?: number[] | string[];
  isResultPage?:boolean
}

export const QuestionButtonsBlock: FC<QuestionButtonsBlockProps> = (props) => {
  const {
    questions, onSetAnswers, answers, isResultPage,
  } = props;

  return (
    <QuestionBtnBlock>
      {questions?.length > 0
        ? questions.map(({ id, text, is_correct }) => {
          return (
            <QuestionBtn
              key={id}
              isCorrect={is_correct}
                // @ts-ignore
              isSelected={answers?.includes(Number(id))}
                // @ts-ignore
              onClick={() => {
                if (isResultPage) {
                  return;
                }
                if (onSetAnswers) {
                  onSetAnswers([Number(id)]);
                }
              }}
            >
              {text}
            </QuestionBtn>
          );
        })
        : null}
    </QuestionBtnBlock>
  );
};
