import { useCallback, useState } from 'react';
import {
  SignupData, signin, signup, updateSupplier,
} from '../network';
import { User } from '../types';
import { RequestError } from '../../api';
import { authService } from '../services';
import { useAuthContext } from '../containers';

export const useSignupSupplier = (): {
  result: User | null,
  isLoading: boolean,
  error: RequestError | Error | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: (data: SignupData, supplierData: any) => any,
} => {
  const { setAuthorizedRedirect } = useAuthContext();
  const [result, setResult] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | Error | null>(null);

  const request = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: SignupData, supplierData: any) => {
      setIsLoading(true);
      setError(null);

      try {
        await signup(data);
        const signinRes = await signin({
          email: data.email || '',
          password: data.password || '',
        });
        const response = await updateSupplier(supplierData, {
          withAuth: false,
          headers: {
            Authorization: `Bearer ${signinRes.accessToken}`,
          },
        });

        // #TODO: вынести наружу
        setAuthorizedRedirect('/account/logo');

        await authService.setAuthData(signinRes);

        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [],
  );

  return {
    result,
    isLoading,
    error,
    request,
  };
};
