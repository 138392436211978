import { RequestOptions, apiClient } from '../../api';

export interface LoadKycUrlData {
  refresh_url: string;
  return_url: string;
}

export interface LoadKycUrlResponse {
  url: string;
}

export const loadKycLink = async (
  data: LoadKycUrlData,
  meta: Partial<RequestOptions> = {},
): Promise<LoadKycUrlResponse> => {
  const response = await apiClient.request({
    path: '/api/v1/payment/stripe/onboarding/',
    method: 'POST',
    body: data,
    withAuth: true,
    ...meta,
  });

  return response.data;
};
