import { css, keyframes, styled } from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../../shared/assets/images/background-grid-dark.jpg';
import { animationDelays, slideLeftAnimation, slideRightAnimation } from '../../../ui';
import { Container } from '../../../shared';

export const Root = styled.div`
  width: 100%;
`;

export const MainScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('${bg}');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  ${(p) => p.theme.breakpoints.up('md')} {
    position: absolute;
  }
`;

export const Content = styled(Container)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 5vh;

  ${(p) => p.theme.breakpoints.up('md')} {
    min-height: 100vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  }
`;

export const Menu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface MenuItemProps {
  small?: boolean;
  wide?: boolean;
}

export const MenuItem = styled(Link)<MenuItemProps>`
  font-size: ${(p) => (p.small ? '20px' : '40px')};
  line-height: ${(p) => (p.small ? '72px' : '82px')};
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  animation: ${slideLeftAnimation} .3s ease-out backwards;
  text-shadow: 0 0 31px transparent;
  transition: all .3s ease-in-out;
  transform-origin: left center;

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 26px;
    line-height: 60px;
  }

  &:hover {
    text-shadow: 0 0 31px #03FBFD;
    transform: scale(1.15);
  }

  ${animationDelays(6, 0.3)};

  ${(p) => p.theme.breakpoints.up('md')} {
    ${animationDelays(6, 0.3, 1.2)};
  }

  ${(p) => p.wide && css`
    white-space: nowrap;
    font-size: 20px;
    line-height: 72px;

    ${p.theme.breakpoints.up('md')} {
      position: absolute;
      top: 100%;
      font-size: 40px;
      line-height: 82px;
    }
  `}
`;

const logoAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(6);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

export const LogoWrapper = styled.div`
  animation: ${logoAnimation} 1s 1.2s ease-in backwards;

  ${(p) => p.theme.breakpoints.up('md')} {
    animation-delay: 0s;
  }
`;

export const FooterWrapper = styled.div`
`;

export const SpecialOfferWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  animation: ${slideRightAnimation} .3s 2s ease-out backwards;

  ${(p) => p.theme.breakpoints.down('sm')} {
    margin-top: 60px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 400px;
    animation-delay: 2.8s;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 600px;
  }
`;
