import { FC, ReactNode } from 'react';
import { HiddenContainer, IconWrapper, Root } from './styled';

interface SocialButtonProps {
  text: ReactNode;
  icon?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

export const SocialButton: FC<SocialButtonProps> = (props) => {
  const {
    text, icon, loading, disabled, children, onClick,
  } = props;

  return (
    <Root onClick={onClick} loading={loading} disabled={disabled}>
      {text}
      {icon && (
        <IconWrapper>{icon}</IconWrapper>
      )}
      {children && (
        <HiddenContainer>
          <div>
            {children}
          </div>
        </HiddenContainer>
      )}
    </Root>
  );
};
