import { createContext } from 'react';
import { User } from '../../types';
import { RequestError } from '../../../api';

export interface UserContextValue {
  currentUser: User | null;
  isLoading: boolean;
  error: Error | RequestError | null;
  refresh: () => void;
  update: (data: Partial<User>) => void;
  updated: boolean;
  updating: boolean;
  updateError: Error | RequestError | null;
}

export const UserContext = createContext<UserContextValue>(
  {} as UserContextValue,
);
