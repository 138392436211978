import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { initFB } from './fbInitScript';
import { SocialButton } from '../SocialButton';
import { FacebookIcon } from '../../../ui';
import { useSigninFacebook } from '../../hooks';

const buttonCode = {
  __html: `
<fb:login-button scope="public_profile,email" onlogin="checkLoginState();">
</fb:login-button>
`,
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFacebookAuthResponse?: (data: any) => void;
  }
}

export const FacebookAuthButtton: FC<{isLogin?: boolean}> = ({ isLogin }) => {
  const { state } = useLocation();

  const [isReady, setIsReady] = useState(false);

  // eslint-disable-next-line no-nested-ternary
  const redirect = state?.itemId
    ? `../courses/${state.itemId}`
    : isLogin
      ? '/account'
      : '/account/select-avatar';

  const { request, isLoading } = useSigninFacebook({
    redirect,
  });

  useEffect(() => {
    window.handleFacebookAuthResponse = (data) => {
      if (data?.authResponse?.accessToken) {
        request({
          access_token: data?.authResponse?.accessToken,
        });
      }
    };
    initFB(setIsReady);

    return () => {
      window.handleFacebookAuthResponse = undefined;
    };
  }, [request]);

  return (
    <SocialButton
      text="Facebook"
      icon={<FacebookIcon />}
      loading={!isReady}
      disabled={isLoading}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={buttonCode}
      />
    </SocialButton>
  );
};
