import { useEffect, useState } from 'react';

export const useDuration = (seconds: string | number | null): string | null => {
  const [duration, setDuration] = useState<string | null>(null);

  useEffect(() => {
    let fixedSeconds: number | null = null;
    if (typeof seconds === 'number') {
      fixedSeconds = seconds;
    } else if (seconds && typeof seconds === 'string') {
      fixedSeconds = parseFloat(seconds);
    }

    if (!fixedSeconds) {
      setDuration(null);
      return;
    }

    const units = [
      // { label: 'months', seconds: 2592000 }, // 30 days
      // { label: 'days', seconds: 86400 }, // 24 hours
      { label: 'hours', seconds: 3600 }, // 60 minutes
      { label: 'mins', seconds: 60 },
      { label: 'seconds', seconds: 1 },
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const unit of units) {
      if (fixedSeconds >= unit.seconds) {
        const value = Math.floor(fixedSeconds / unit.seconds);
        setDuration(`${value} ${unit.label}`);
        break;
      }
    }
  }, [seconds]);

  return duration;
};
