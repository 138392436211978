import { RequestOptions, apiClient } from '../../api';
import { User } from '../types';

export interface SignupData {
  email: string;
  password: string;
  password_confirm?: string;
  username: string;
  first_name: string;
  last_name?: string;
  is_supplier?: boolean;
}

export const signup = async (
  data: SignupData,
  meta: Partial<RequestOptions> = {},
): Promise<User> => {
  const body = {
    ...data,
    password_confirm: data.password_confirm || data.password,
  };
  const response = await apiClient.request({
    path: '/api/v1/accounts/register/',
    method: 'POST',
    body,
    withAuth: false,
    ...meta,
  });

  return response.data;
};
