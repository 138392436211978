import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { authService } from '../../services';
import { AuthLocalStorage, AuthStorage } from '../../storage';
import { AuthContext } from './AuthContext';

export interface AuthProviderProps {
  autoInitialize?: boolean;
  authStorage?: AuthStorage;
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { autoInitialize = true, authStorage, children } = props;

  const [initialized, setInitialized] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [authorizedRedirect, setAuthorizedRedirect] = useState<string | null>(null);

  const contextValue = useMemo(() => {
    return {
      initialized,
      authorized,
      authorizedRedirect,
      setAuthorizedRedirect,
      setAuthData: authService.setAuthData,
      logout: authService.logout,
    };
  }, [initialized, authorized, authorizedRedirect]);

  useEffect(() => {
    if (authStorage) {
      authService.authStorage = authStorage;
    } else if (localStorage) {
      authService.authStorage = new AuthLocalStorage();
    }
    if (autoInitialize) {
      authService.initialize();
    }
  }, [autoInitialize, authStorage]);

  useEffect(() => {
    setAuthorized(authService.authorized);
    setInitialized(authService.initialized);
    return authService.on('change', (data) => {
      setAuthorized(data.authorized);
      setInitialized(data.initialized);
    });
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
