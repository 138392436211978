import { css, styled } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { Swiper } from 'swiper/react';

export const RRSwiper = styled(Swiper)`
  width: 100%; 

  & .swiper-wrapper {
    display: flex;
    width: 100%;
  }
`;

export const TabItem = styled.div`
  padding: ${(p) => p.theme.spacing.get(2, 3)};
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  color: #03fbfd;
  background: #000;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  text-decoration: none;

  &.active {
    color: #000;
    background: #03fbfd;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing.get(3, 5)};
    font-size: 16px;
    letter-spacing: 0.32px;
  }
`;

export interface RootProps {
  direction?: 'row' | 'column';
  glow?: boolean;
  topLine?: boolean;
  fullWidth?: boolean;
}

export const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  background: #000;

  ${(p) => p.glow
    && css`
      box-shadow: 5px 5px 7px 0 rgba(3, 251, 253, 0.5);
    `}

  ${(p) => p.topLine
    && css`
      border-top: 2px solid #03fbfd;
    `}

  ${(p) => p.direction === 'row'
    && css`
      flex-direction: row;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    `}

  ${(p) => p.fullWidth
    && css`
      width: 100%;
    `}
`;
