import { styled } from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.get(2)};
`;

export const Item = styled.div<{ isActive?: boolean }>`
  font-size: 19px;
  line-height: 0;
  color: ${({ isActive }) => (isActive ? '#FAFF00' : '#777777')};

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 24px;
  }
`;
