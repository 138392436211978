import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../Container';
import { config } from '../../../../config';
import SvgFacebookIcon from '../../../ui/components/icons/IconComponents/FacebookIcon';
import SvgTwitterIcon from '../../../ui/components/icons/IconComponents/TwitterIcon';
import SvgLinkdinIcon from '../../../ui/components/icons/IconComponents/LinkdinIcon';
import {
  Content,
  FooterContent,
  FooterLink,
  FooterObject1,
  FooterObject2,
  FooterObject3,
  FooterObjectSocial, IconLink,
  List, Root, Title,
} from './styled';

interface IFooterProps{
  isHomeScreen?:boolean
}

export const Footer: FC<IFooterProps> = ({ isHomeScreen = false }) => {
  return (
    <Root isHomeScreen={isHomeScreen}>
      <Container>
        <Content>
          <FooterObject1>
            <FooterContent>
              <Title>Services</Title>
              <List>
                <FooterLink as={Link} to="/categories">Online learning</FooterLink>
                <FooterLink as="a" href="mailto:supporrt@rroaded.co.uk?subject=Reporting a bug">Reporting a bug</FooterLink>
                <FooterLink as="a" target="_blank" href="https://www.rroaded.co.uk/#comp-lh7ipn1h">Contact us</FooterLink>
                <FooterLink as={Link} to="/for-partners">For partners</FooterLink>
              </List>
            </FooterContent>
          </FooterObject1>
          <FooterObject2>
            <FooterContent>
              <Title>For customers</Title>
              <List>
                <FooterLink as={Link} to="/identify">Sign-up\Sign-in</FooterLink>
                <FooterLink as={Link} to="/account/support">Customer support</FooterLink>
                <FooterLink as={Link} to="/faq">FAQ</FooterLink>
                <FooterLink as={Link} to="/terms">Terms and Conditions</FooterLink>
                <FooterLink as="a" target="_blank" href="https://stripe.com/docs/refunds?locale=en-GB">Policy returns and payment methods</FooterLink>
              </List>
            </FooterContent>
          </FooterObject2>
          <FooterObject3>
            <FooterContent>
              <Title>Company information</Title>
              <List>
                <FooterLink as={Link} to="/about">About us</FooterLink>
                <FooterLink as="a" target="_blank" href="https://www.rroaded.co.uk/">Company website</FooterLink>
                <FooterLink as="a" target="_blank" href={`${config.apiBaseUrl}/media/Legal_hub_set.zip`}>Legal Hub (Set)</FooterLink>
                <FooterLink as={Link} to="/privacy-policy">Privacy policy</FooterLink>
                <FooterLink as={Link} to="/copyright">Copyright</FooterLink>
              </List>
            </FooterContent>
          </FooterObject3>
          <FooterObjectSocial>
            <IconLink href="http://facebook.com/rightroaded" target="_blank" rel="noreferrer"><SvgFacebookIcon /></IconLink>
            <IconLink href="https://twitter.com/rightroaded" target="_blank" rel="noreferrer"><SvgTwitterIcon /></IconLink>
            <IconLink href="http://linkedin.com/company/rightroad" target="_blank" rel="noreferrer"><SvgLinkdinIcon /></IconLink>
          </FooterObjectSocial>
        </Content>
      </Container>
    </Root>
  );
};
