import { useMemo } from 'react';
import { SelectInputOption } from '../../ui';
import { useLoadCountries } from './useLoadCountries';

export const useCountriesSelectOptions = (): Array<SelectInputOption> => {
  const { result } = useLoadCountries();

  return useMemo(() => {
    if (result) {
      const fetchCountries = result.map((x) => ({
        label: x.name,
        value: x.code,
      }));
      const otherChoice = { label: 'Other', value: 'Other' };
      return [...fetchCountries, otherChoice];
    }

    return [];
  }, [result]);
};
