import React, { FC, ReactNode } from 'react';
import {
  Clear,
  ErrorMessage,
  HintMessage,
  IconHolder,
  InputWrapper,
  Label,
  Root,
} from './styled';
// import { ArrowRightIcon, CheckIcon, CloseIcon } from '../../Icons';

export interface BaseInputProps {
  fullWidth?: boolean;
  label?: ReactNode;
  required?: boolean;
  focused?: boolean;
  allowClear?: boolean;
  error?: boolean | ReactNode;
  reserveSpaceForError?: boolean;
  success?: boolean | ReactNode;
  hint?: ReactNode;
  empty?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children?: ReactNode;
  color?: 'base' | 'primary';
  size?: 'sm' | 'md';
  onClear?: () => void;
}

export const BaseInput: FC<BaseInputProps> = (props) => {
  const {
    fullWidth,
    label,
    required,
    focused,
    allowClear,
    error = false,
    reserveSpaceForError = false,
    success = false,
    empty,
    hint,
    color = 'base',
    size = 'md',
    disabled,
    startIcon,
    endIcon,
    children,
    onClear,
  } = props;

  let errorView = (error && typeof error !== 'boolean') ? (
    <ErrorMessage>{error}</ErrorMessage>
  ) : null;

  if (!errorView && hint) {
    errorView = <HintMessage>{hint}</HintMessage>;
  } else if (!errorView && reserveSpaceForError) {
    errorView = <ErrorMessage>&nbsp;</ErrorMessage>;
  }

  return (
    <Root
      fullWidth={fullWidth}
      error={Boolean(error)}
      success={Boolean(success)}
      focused={focused}
      empty={empty}
      disabled={disabled}
      color={color}
      size={size}
    >
      {label && (
        <Label>
          {label}
          {required && ' *'}
        </Label>
      )}
      <InputWrapper>
        {startIcon && <IconHolder position="start">{startIcon}</IconHolder>}
        {children}
        {allowClear && !empty && (
          <Clear onClick={onClear} position="end">
            {/* <CloseIcon /> */}
          </Clear>
        )}
        {endIcon && <IconHolder position="end">{endIcon}</IconHolder>}
      </InputWrapper>
      {errorView}
    </Root>
  );
};
