import { useCallback, useState } from 'react';
import { PowerGlitch, PowerGlitchOptions, RecursivePartial } from 'powerglitch';
import { useMemoize } from '../../common';

export interface UseGlitchResult {
    ref: (node: HTMLElement | null) => void;
    startGlitch: () => void;
    stopGlitch: () => void;
}

export function useGlitch(userOptions?: RecursivePartial<PowerGlitchOptions>): UseGlitchResult {
  const options = useMemoize(userOptions);

  const [startGlitch, setStartGlitch] = useState<(() => void)>(() => () => {});
  const [stopGlitch, setStopGlitch] = useState<(() => void)>(() => () => {});

  const ref = useCallback((node: HTMLElement | null) => {
    if (!node) {
      return;
    }
    const result = PowerGlitch.glitch(node, options);
    setStartGlitch(() => result.startGlitch);
    setStopGlitch(() => result.stopGlitch);
  }, [options]);

  return {
    ref,
    startGlitch,
    stopGlitch,
  };
}
