import { FC } from 'react';

export const Line: FC = () => {
  return (
    <svg
      width="1502"
      height="26"
      viewBox="0 0 1502 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3787 0.5H24H98.0979H98.7239L99.1643 0.945068L107.626 9.5H184H184.621L185.061 9.93945L197.621 22.5H243.379L255.939 9.93945L256.379 9.5H257H332.379L340.939 0.939453L341.379 0.5H342H570.057H570.679L571.119 0.940674L592.623 22.5H671.379L683.939 9.93945L684.379 9.5H685H808.379L816.939 0.939453L817.379 0.5H818H892H892.621L893.061 0.939453L901.621 9.5H978H978.621L979.061 9.93945L991.621 22.5H1037.38L1049.94 9.93945L1050.38 9.5H1051H1126.38L1134.94 0.939453L1135.38 0.5H1136H1363H1363.62L1364.06 0.939453L1385.62 22.5H1465.38L1477.94 9.93945L1478.38 9.5H1479H1502V12.5H1479.62L1467.06 25.0605L1466.62 25.5H1466H1385H1384.38L1383.94 25.0605L1362.38 3.5H1136.62L1128.06 12.0605L1127.62 12.5H1127H1051.62L1039.06 25.0605L1038.62 25.5H1038H991H990.379L989.939 25.0605L977.379 12.5H901H900.379L899.939 12.0605L891.379 3.5H818.621L810.061 12.0605L809.621 12.5H809H685.621L673.061 25.0605L672.621 25.5H672H592H591.377L590.938 25.0593L569.435 3.5H342.621L334.061 12.0605L333.621 12.5H333H257.621L245.061 25.0605L244.621 25.5H244H197H196.379L195.939 25.0605L183.379 12.5H107H106.374L105.934 12.0549L97.4717 3.5H24.6213L16.0605 12.0605L15.6213 12.5H15H0V9.5H14.3787L22.9395 0.939453L23.3787 0.5ZM1344 9H1359.02L1366 16H1350.81L1344 9ZM975.024 18H960L966.814 25H982L975.024 18ZM550 9H565.024L572 16H556.814L550 9ZM181.024 18H166L172.814 25H188L181.024 18ZM838 7H820.927L813 14H830.256L838 7ZM26.9268 7H44L36.2566 14H19L26.9268 7Z" fill="currentColor" />
    </svg>
  );
};
