import { FC, ReactNode } from 'react';
import { ContentWrapper, Root } from './styles';

export interface UnauthorizedLayoutProps {
  children: ReactNode;
}

export const UnauthorizedLayout: FC<UnauthorizedLayoutProps> = ({
  children,
}) => {
  return (
    <Root>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Root>
  );
};
