import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Ticket, TicketStatus } from '../types';

export interface LoadTicketsFilters {
  status?: TicketStatus;
}

export interface LoadTicketsData
  extends RequestListParams<LoadTicketsFilters> {}

export const loadTickets = async (
  data: LoadTicketsData = {},
): Promise<RequestPagingResponse<Ticket>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/communication/ticket/',
    method: 'GET',
    params,
  });

  return apiResponsePagingMapper<Ticket>(response.data);
};
