import { styled } from 'styled-components';

export const Title = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.get(4)};
  ${(p) => p.theme.typography.base};
  font-size: 26px;
  letter-spacing: -0.39px;
  text-transform: uppercase;
  color: #fff;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 48px;
    letter-spacing: -0.72px;
  }
`;
