import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    width: 100vw;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    ${({ theme }) => css`
      ${theme.typography.base};
      color: ${theme.palette.neutral5};
      background-color: ${theme.palette.background.main};
    `};
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  .Toastify__toast-body {
    font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  }
  :root {
    --toastify-color-info: #9747FF;
    --toastify-color-success: #03FBFD;
    --toastify-color-error: #e74c3c;
  }

  #chat-widget-minimized {
    display: none !important;
  }
`;

export const HiddenContent = styled.div`
  position: absolute;
  top: -1000%;
  left: -1000%;
`;
