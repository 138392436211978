import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import courseDefaultImage from '../../../shared/assets/images/course-default.png';
import { ItemImage, Name, Root } from './styled';
import { Course } from '../../../catalog';

export interface MyCourseCardProps {
  item: Course;
}

export const MyCourseCard: FC<MyCourseCardProps> = (props) => {
  const { item } = props;

  const path = useMemo(() => {
    if (item.is_started) {
      return `/courses/${item.id}/study`;
    }
    return `/courses/${item.id}`;
  }, [item]);

  const cssConfig = useMemo(() => {
    if (item?.categories && item?.categories?.length > 0) {
      return (
        item.categories[0]?.css_config?.gradient
        || item?.categories[0]?.css_config?.color
      );
    }
    return undefined;
  }, [item]);

  return (
    <Root as={Link} to={path} background={cssConfig}>
      <ItemImage src={item.image || courseDefaultImage} />
      <Name>{item.name}</Name>
    </Root>
  );
};
