import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { DocumentItem } from '../types';

export interface LoadDocumentsFilters {}

export interface LoadDocumentsData
  extends RequestListParams<LoadDocumentsFilters> {}

export const loadDocuments = async (
  data: LoadDocumentsData = {},
): Promise<RequestPagingResponse<DocumentItem>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/document/',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<DocumentItem>(response.data);
};
