import styled, { css } from 'styled-components';

interface RootProps {
  loading?: boolean
  disabled?: boolean
}

export const Root = styled.div<RootProps>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px 16px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #03fbfd;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  background: rgba(3, 251, 253, 0.23);

  cursor: pointer;
  color: #fff;

  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-transform: uppercase;

  ${({ loading, disabled }) => (loading || disabled)
    && css` 
      opacity: 0.5;
      pointer-events: none;
    `}
  ${(p) => p.theme.breakpoints.down('md')} {
    padding: 3px 8px;
    font-size: 12px;
    width: 100%;
  }
`;

export const IconWrapper = styled.div`
  font-size: 24px;
  line-height: 0;
  color: #03FBFD;
`;

export const HiddenContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0.002;

  & > div {
    transform: scale(10);
  }
`;
