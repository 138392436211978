import { RequestOptions, apiClient } from '../../api';

export interface SendResetPasswordLinkData {
  email: string;
}

export const sendResetPasswordLink = async (
  data: SendResetPasswordLinkData,
  meta: Partial<RequestOptions> = {},
): Promise<unknown> => {
  await apiClient.request({
    path: '/api/v1/accounts/reset-password/',
    method: 'POST',
    body: data,
    withAuth: false,
    ...meta,
  });

  return {};
};
