import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Body, FooterWrapper, Root, TabsWrapper, Wrapper,
} from './styled';
import { Footer, Header } from '../../../shared';
import { TabItem, Tabs, useBreakpoint } from '../../../ui';

export interface ProfileLayoutProps {
  bodyBg?: 'gradient' | 'fill' | null;
  showTabs?: boolean;
  children?: ReactNode;
}

const tabs = [
  { label: 'Profile', path: '/account/profile/skills' },
  { label: 'Avatar', path: '/account/profile/avatar' },
  { label: 'My courses', path: '/account/profile/courses' },
  { label: 'Settings', path: '/account/profile/settings' },
  { label: 'Tickets', path: '/account/profile/tickets' },
];

export const ProfileLayout: FC<ProfileLayoutProps> = (props) => {
  const { bodyBg, showTabs = true, children } = props;
  const isDesktop = useBreakpoint('up', 'md');
  return (
    <Root>
      <Wrapper>
        <Header />
        {showTabs && (
          <TabsWrapper>
            <Tabs
              topLine
              fullWidth={false}
            >
              {tabs.map((x) => (
                <TabItem
                  key={x.path}
                  as={NavLink}
                  to={x.path}
                >
                  {x.label}
                </TabItem>
              ))}
            </Tabs>
          </TabsWrapper>
        )}
        <Body bg={bodyBg}>
          {children}
          {!isDesktop && (
            <FooterWrapper>
              <Footer />
            </FooterWrapper>
          )}
        </Body>
      </Wrapper>
      {isDesktop && (
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      )}
    </Root>
  );
};
