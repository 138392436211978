import { FC, ReactNode } from 'react';
import { useLoadReviews } from '../../hooks';
import { Carousel } from '../../../ui';
import { ReviewCard } from '../ReviewCard';
import { Title } from './styled';

export interface ReviewsCarouselProps {
  title?: ReactNode;
  courseId: number | string;
}

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
    },
  },
];

export const ReviewsCarousel: FC<ReviewsCarouselProps> = (props) => {
  const { title, courseId } = props;
  const { result: items } = useLoadReviews(courseId);
  if (!items || items.data.length === 0) {
    return null;
  }
  return (
    <div>
      {title && (
        <Title>{title}</Title>
      )}
      <Carousel slidesToShow={3} responsive={responsive} infinite={items.data.length > 3}>
        {items.data.map((item, index) => (
          <ReviewCard key={item.id || index} item={item} />
        ))}
      </Carousel>
    </div>
  );
};
