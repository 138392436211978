import {
  FC, useCallback,
} from 'react';
import { ProfileLayout } from '../../layouts';
import { Container, FadeIn } from '../../../shared';
import { Button, GlitchWrapper } from '../../../ui';
import {
  ComingSoon,
  Content, Item, ItemButton, ItemName,
} from './styles';
import { useLoadDocuments } from '../../hooks';
import { useDownloadFileByUrl } from '../../../common';

export const SupplierDocumentsScreen: FC = () => {
  const {
    result,
    isLoading,
  } = useLoadDocuments();
  const { isLoading: isDownloading, download } = useDownloadFileByUrl();

  const items = result?.data || [];
  return (
    <ProfileLayout bodyBg="gradient">
      <FadeIn>
        <Container>
          <FadeIn>
            <Content>
              {items.length > 0 && items.map((item) => (
                <Item>
                  <ItemName>{item.title}</ItemName>
                  <ItemButton>
                    <Button
                      size="md"
                      disabled={isDownloading || !item.file}
                      onClick={() => item.file && download(item.file)}
                    >
                      Download
                    </Button>
                  </ItemButton>
                </Item>
              ))}
              {!isLoading && items.length === 0 && (
                <ComingSoon>
                  <GlitchWrapper playMode="always" shake>
                    <div>Coming soon</div>
                  </GlitchWrapper>
                </ComingSoon>
              )}
            </Content>
          </FadeIn>
        </Container>
      </FadeIn>
    </ProfileLayout>
  );
};
