import {
  FC, useCallback, useEffect, useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ProfileSettingsLayout } from '../../layouts';
import { ShapeCard } from '../../../shared';
import { Root, Title } from './styled';
import {
  Button,
  EyeIcon,
  EyeNoIcon,
  FormTextInput,
  useBreakpoint,
} from '../../../ui';
import { useChangePassword } from '../../hooks';

const schema = yup
  .object({
    old_password: yup.string().required(''),
    password: yup
      .string()
      .min(8, 'Min 8 symbols')
      .matches(
        /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#${}~`\][()|><^/\\.,-_+=:;'"!%*?&])/,
        'At least one uppercase letter, one lowercase letter, one number and one special character',
      )
      .required(''),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const ProfileSettingsPasswordScreen: FC = () => {
  const isDesktop = useBreakpoint('up', 'md');
  const { result, isLoading, request } = useChangePassword();
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((x) => !x);
  }, []);

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data);
    },
    [request],
  );

  useEffect(() => {
    if (result) {
      toast('Password successfully changed', { type: 'success' });
      resetForm();
    }
  }, [result, resetForm]);

  const ToggleIcon = showPassword ? EyeNoIcon : EyeIcon;

  return (
    <ProfileSettingsLayout>
      <ShapeCard>
        <Root as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Title>Change password</Title>
          <FormTextInput
            color="primary"
            size={isDesktop ? 'md' : 'sm'}
            reserveSpaceForError
            name="old_password"
            label="Old password"
            // placeholder="********"
            type={showPassword ? 'string' : 'password'}
            fullWidth
            control={control}
            endIcon={<ToggleIcon onClick={toggleShowPassword} />}
          />
          <FormTextInput
            color="primary"
            size={isDesktop ? 'md' : 'sm'}
            reserveSpaceForError
            name="password"
            label="New password"
            // placeholder="********"
            type={showPassword ? 'string' : 'password'}
            fullWidth
            control={control}
            endIcon={<ToggleIcon onClick={toggleShowPassword} />}
          />
          <Button
            size={isDesktop ? 'lg' : 'md'}
            type="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </Root>
      </ShapeCard>
    </ProfileSettingsLayout>
  );
};
