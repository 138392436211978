import { FC } from 'react';
import {
  Navigate, Route, Routes, useParams,
} from 'react-router-dom';
import { StudyStructScreen } from '../StudyStructScreen';
import { StudyLessonScreen } from '../StudyLessonScreen';
import { StudyProvider } from '../../containers';
import { StudyFinishScreen } from '../StudyFinishScreen';
import { useAuthContext } from '../../../auth';

export const StudyScreen: FC = () => {
  const { courseId } = useParams();
  const { authorized } = useAuthContext();

  if (!courseId || !authorized) {
    return <Navigate replace to="/" />;
  }

  return (
    <StudyProvider courseId={courseId}>
      <Routes>
        <Route path="/" element={<StudyStructScreen />} />
        <Route path="/finish" element={<StudyFinishScreen />} />
        <Route path="/:lessonId" element={<StudyLessonScreen />} />
      </Routes>
    </StudyProvider>
  );
};
