import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { ShapeCard } from '../../../shared';
import {
  FooterWrapper,
  CardWrapper,
} from './styled';
import { AuthPartnerLayout } from '../AuthPartnerLayout';

export interface AuthLayoutProps {
  children?: ReactNode;
}

export const AuthLayout: FC<AuthLayoutProps> = (props) => {
  const { children } = props;

  // return (
  //   <Root>
  //     <MainScreen>
  //       <Header position="absolute" userMenu={false} />
  //       <BottomLines />
  //       <Content>
  //         {children}
  //       </Content>
  //     </MainScreen>
  //     <FooterWrapper>
  //       <Footer />
  //     </FooterWrapper>
  //   </Root>
  // );

  return (
    <AuthPartnerLayout>
      <CardWrapper>
        <ShapeCard>
          {children}
        </ShapeCard>
      </CardWrapper>
      <FooterWrapper id="auth-layout-footer" />
    </AuthPartnerLayout>
  );
};

export const AuthLayoutFooterPortal: FC<{ children?: ReactNode }> = (props) => {
  const { children } = props;

  const node = document.getElementById('auth-layout-footer');

  if (!node) {
    return null;
  }

  return (
    <>
      {createPortal(children, node)}
    </>
  );
};
