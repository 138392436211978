import styled from 'styled-components';
import shape from '../../../shared/assets/shapes/angle-line.svg';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1096px;
  margin: 0 auto;
  padding: 160px 45px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #010046 100%);
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  color: #fff;
  ${(p) => p.theme.breakpoints.down('mobile')} {
  padding:0;}
`;

export const Title = styled.div`
  margin-bottom: 12px;

  & * {
    font-size: 26px;
    font-weight: 400;
    line-height: 31.4px; /* 120.769% */
    letter-spacing: -0.39px;
    text-transform: uppercase;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-bottom: 24px;

    & * {
      font-weight: normal;
      font-size: 48px;
      line-height: 40px; /* 83.333% */
      letter-spacing: 0.96px;
    }
  }
`;

export const Paragrapth = styled.div``;

export const Separator = styled.div`
  position: relative;
  width: 100%;
  margin-left: -10%;
  height: 1px;
  background-color: #00FCFC;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 99%;
    background-image: url(${shape});
    background-size: cover;
    width: 80px;
    aspect-ratio: ${235 / 140};
    transform: rotate(180deg);
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 105%;
    margin-left: -5%;
    &::after {
      width: 80px;
    }
  }
`;
