/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStudyContext } from '../../containers';
import {
  LessonTextContent,
  LessonVideoContent,
  ProgressWrapper,
} from '../../components';
import { LessonResultsContent } from '../../components/LessonResultsContent';
import { QuestionButtonsBlock } from '../../components/QuestionButtonsBlock';
import { Container, InfoPageLayout } from '../../../shared';
import { useUpdateStepAnswers, useFinishUserCourseStep } from '../../hooks';
import {
  ActionsPanel,
  ButtonsBlock,
  Content,
  StyledButton,
  Title,
} from '../styled';
import { useBreakpoint } from '../../../ui';

interface StudyLessonScreenContentProps {
  lessonId: number;
}

export const StudyLessonScreenContent: FC<StudyLessonScreenContentProps> = (
  props,
) => {
  const { lessonId } = props;

  const isDesktop = useBreakpoint('up', 'md');

  const navigate = useNavigate();

  const { userCourse, reload } = useStudyContext();
  const { result, isLoading, request: finish } = useFinishUserCourseStep();
  const { request: updateStepAnswers } = useUpdateStepAnswers();

  const waitNextRef = useRef(false);

  const [answers, setAnswers] = useState<number[] | string[]>([]);

  const [step, stepIndex] = useMemo(() => {
    if (!userCourse || !lessonId) {
      return [null, null];
    }
    const index = userCourse.user_steps.findIndex(
      (x) => x.id === Number(lessonId),
    );
    if (index === -1) {
      return [null, null];
    }
    return [userCourse.user_steps[index], index];
  }, [userCourse, lessonId]);

  const isDisabledNextBtn = useMemo(() => {
    return (
      (step?.step.questions?.length && !step?.answers?.length
        ? !answers?.length
        : false)
      || isLoading
      || !!result
    );
  }, [result, isLoading, step?.step.questions?.length, answers]);

  useEffect(() => {
    if (step) {
      setAnswers(step.answers);
    }
  }, [step, lessonId]);

  const handleSetAnswers = useCallback((id: number[]) => {
    setAnswers(id);
  }, []);

  const goToNext = useCallback(() => {
    if (!userCourse || !lessonId) {
      return;
    }
    const currentIndex = userCourse.user_steps.findIndex(
      (x) => x.id === Number(lessonId),
    );
    const nextStep = userCourse.user_steps[currentIndex + 1] || null;
    if (nextStep) {
      navigate(`../${nextStep.id}`);
    } else {
      navigate('../finish');
    }
  }, [lessonId, userCourse, navigate]);

  const handlePrev = useCallback(() => {
    if (!stepIndex || !userCourse) {
      return;
    }
    const prevStep = userCourse.user_steps[stepIndex - 1];
    if (prevStep) {
      navigate(`../${prevStep.id}`);
    }
  }, [stepIndex, userCourse, navigate]);

  const handleNext = useCallback(() => {
    if (!step || !userCourse) {
      return;
    }
    if (step.step.questions?.length) {
      updateStepAnswers(userCourse.id, step.id, { answers }).then(() => {
        reload();
        if (step.is_finished) {
          goToNext();
        } else {
          finish(userCourse.id, step.id);
        }
      });
    } else if (step.is_finished) {
      goToNext();
    } else {
      finish(userCourse.id, step.id);
    }
  }, [step, userCourse, finish, goToNext, answers]);

  useEffect(() => {
    if (result && waitNextRef.current) {
      goToNext();
    } else if (result) {
      reload();
      waitNextRef.current = true;
    }
  }, [result, reload, goToNext]);

  if (!step) {
    return null;
  }
  return (
    <InfoPageLayout bodyBg="fillGradient">
      <Container>
        <ProgressWrapper userCourse={userCourse} lessonId={lessonId}>
          <Content>
            <Title>{step?.step.name}</Title>
            {step.step.type === 'text' && (
              <LessonTextContent
                content={step.step.content_text || 'Empty content'}
              />
            )}
            {step.step.type === 'video' && (
              <LessonVideoContent videoUrl={step.step.video_url || ''} />
            )}
            {step.step.type === 'results' && (
              <LessonResultsContent userCourseId={userCourse?.id} step={step} />
            )}

            {step.step.questions?.length ? (
              <QuestionButtonsBlock
                questions={step.step.questions}
                onSetAnswers={handleSetAnswers}
                answers={answers}
              />
            ) : null}
            <ActionsPanel>
              <ButtonsBlock>
                {stepIndex > 0 && (
                  <StyledButton
                    // @ts-ignore
                    onClick={handlePrev}
                    size={isDesktop ? 'md' : 'sm'}
                  >
                    Previous
                  </StyledButton>
                )}
                <StyledButton
                  // @ts-ignore
                  onClick={handleNext}
                  disabled={isDisabledNextBtn}
                  size={isDesktop ? 'lg' : 'lg'}
                >
                  Next
                </StyledButton>
              </ButtonsBlock>
            </ActionsPanel>
          </Content>
        </ProgressWrapper>
      </Container>
    </InfoPageLayout>
  );
};

export const StudyLessonScreen: FC = () => {
  const { lessonId } = useParams();

  return (
    <StudyLessonScreenContent key={lessonId} lessonId={Number(lessonId)} />
  );
};
