import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { ProfileLayout } from '../../layouts';
import { CourseCard } from '../../../catalog';
import { ShapeButton, useBreakpoint } from '../../../ui';
import { Container, FadeIn } from '../../../shared';
import {
  Item,
  Items,
  ItemsWrapper,
} from '../../../catalog/screens/CategoriesScreen/styled';
import { ActionFormBtn, ItemsBlock } from './styles';
import { useLoadSupplierCourses } from '../../hooks/useLoadSupplierCourses';

export const SupplierCoursesScreen: FC = () => {
  const coursesData = useLoadSupplierCourses();
  const isDesktop = useBreakpoint('up', 'md');

  const handleOpenAdmin = useCallback(() => {
    window.open('https://api.rroaded.co.uk/admin/course/course/');
  }, []);

  return (
    <ProfileLayout bodyBg="gradient">
      <Container>
        <FadeIn>
          <ActionFormBtn>
            <ShapeButton
              glow
              color="gradient"
              size={isDesktop ? 'lg' : 'sm'}
              onClick={handleOpenAdmin}
            >
              Upload course
            </ShapeButton>
          </ActionFormBtn>
        </FadeIn>
      </Container>
      <FadeIn>
        <ItemsBlock>
          <ItemsWrapper>
            <Items>
              {coursesData?.result?.data?.length
                ? coursesData?.result?.data?.map((item) => (
                  <Item key={item.id} as={Link} to={`/courses/${item.id}`}>
                    <CourseCard item={item} />
                  </Item>
                ))
                : null}
            </Items>
          </ItemsWrapper>
        </ItemsBlock>
      </FadeIn>
    </ProfileLayout>
  );
};
