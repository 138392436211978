import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="m16.618 13.165.551-3.592h-3.447V7.242c0-.983.482-1.941 2.026-1.941h1.567V2.243S15.892 2 14.533 2c-2.839 0-4.694 1.72-4.694 4.835v2.738H6.684v3.592h3.155v8.684a12.516 12.516 0 0 0 3.883 0v-8.684h2.896Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M2 2h20v20H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFacebook;
