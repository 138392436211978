import styled from 'styled-components';
import corner from '../../assets/shapes/corner-shape.svg';

export const CornerCard = styled.div`
  position: relative;

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    width: 70%;
    aspect-ratio: ${764 / 221};
    background-image: url(${corner});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    bottom: 0;
  }

  &::after {
    top: 0;
    right: 0;
    transform: scale(-1);
  }
`;
