import { FC, useEffect } from 'react';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import { AuthorizedContainer } from './AuthorizedContainer';
import { UnauthorizedContainer } from './UnauthorizedContainer';
import { NotFoundScreen } from '../screens';
import {
  AccountIndexScreen,
  AuthGoogleScreen,
  ForgotPasswordScreen,
  IdentifyScreen,
  ImpersonateScreen,
  SelectPartnerTypeScreen,
  SigninScreen,
  SignupScreen,
  useAuthContext,
} from '../../auth';
import { HomeScreen } from '../../home';
import {
  CategoriesScreen,
  CoursePaymentResultScreen,
  CourseScreen,
  CoursesScreen,
  CoursePreviewScreen,
} from '../../catalog';
import {
  ProfileAvatarScreen,
  ProfileCoursesScreen,
  ProfileSettingsMainScreen,
  ProfileSettingsPasswordScreen,
  ProfileSkillsScreen,
  SelectAvatarScreen,
  WelcomeScreen,
} from '../../profile';
import { SignupPartnerScreen } from '../../auth/screens/SignupPartnerScreen';
import { StudyScreen } from '../../study';
import {
  AboutScreen,
  CopyRightScreen,
  FaqScreen,
  ForPartnersScreen,
  PrivacyPolicyScreen,
  TermsAndConditionsScreen,
} from '../../info';
import {
  SupplierCoursesScreen,
  SupplierDocumentsScreen,
  SupplierLogoScreen,
  SupplierSettingsContactScreen,
  SupplierSettingsLogoScreen,
  SupplierSettingsMainScreen,
  SupplierSettingsPasswordScreen,
  SupplierStatsScreen,
  SupplierStatusContainer,
  SupplierVerificationPendingScreen,
  SupplierVerificationScreen,
  SupplierSettingsAboutScreen,
} from '../../supplier';
import { SupplierSettingsAddressScreen } from '../../supplier/screens/SupplierSettingsAddressScreen';
import {
  NewTicketScreen,
  RequestCourseScreen,
  SupportScreen,
  TicketScreen,
} from '../../support';
import { ResetPasswordScreen } from '../../auth/screens/ResetPasswordScreen';
import { StudyPreviewScreen } from '../../study/screens/StudyPreviewScreen';

export const RootNavigator: FC = () => {
  const { pathname } = useLocation();
  const { initialized } = useAuthContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!initialized) {
    // #TODO add loader
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/about" element={<AboutScreen />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
      <Route path="/terms" element={<TermsAndConditionsScreen />} />
      <Route path="/for-partners" element={<ForPartnersScreen />} />
      <Route path="/copyright" element={<CopyRightScreen />} />
      <Route path="/faq" element={<FaqScreen />} />
      <Route path="/impersonate" element={<ImpersonateScreen />} />
      <Route path="/categories" element={<CategoriesScreen />} />
      <Route path="/courses" element={<CoursesScreen />} />
      <Route path="/courses/:id" element={<CourseScreen />} />
      <Route path="/courses-preview/:id" element={<CoursePreviewScreen />} />
      <Route
        path="/courses/:id/payment-result"
        element={<CoursePaymentResultScreen />}
      />
      <Route path="/courses/:courseId/study/*" element={<StudyScreen />} />
      <Route
        path="/courses/:courseId/preview/*"
        element={<StudyPreviewScreen />}
      />
      <Route path="/identify" element={<IdentifyScreen />} />
      <Route path="/verify" element={<SupplierVerificationScreen />} />
      <Route
        path="/verification-pending"
        element={<SupplierVerificationPendingScreen />}
      />
      <Route path="/account" element={<AuthorizedContainer />}>
        <Route index element={<AccountIndexScreen />} />
        <Route path="request-course" element={<RequestCourseScreen />} />
        <Route path="support" element={<SupportScreen />} />
        <Route path="support/new" element={<NewTicketScreen />} />
        <Route path="support/:id" element={<TicketScreen />} />
        <Route path="welcome" element={<WelcomeScreen />} />
        <Route path="select-avatar" element={<SelectAvatarScreen />} />
        <Route path="logo" element={<SupplierLogoScreen />} />
        <Route path="verify" element={<SupplierVerificationScreen />} />
        <Route
          path="verification-pending"
          element={<SupplierVerificationPendingScreen />}
        />
        <Route path="guidance-test" element={<NotFoundScreen />} />
        <Route path="profile">
          <Route index element={<Navigate replace to="skills" />} />
          <Route path="skills" element={<ProfileSkillsScreen />} />
          <Route path="avatar" element={<ProfileAvatarScreen />} />
          <Route path="courses" element={<ProfileCoursesScreen />} />
          <Route path="tickets" element={<SupportScreen layout="account" />} />
          <Route path="settings">
            <Route index element={<Navigate replace to="general" />} />
            <Route path="general" element={<ProfileSettingsMainScreen />} />
            <Route
              path="password"
              element={<ProfileSettingsPasswordScreen />}
            />
          </Route>
        </Route>
        <Route path="supplier" element={<SupplierStatusContainer />}>
          <Route index element={<Navigate replace to="courses" />} />
          <Route path="stats" element={<SupplierStatsScreen />} />
          <Route path="docs" element={<SupplierDocumentsScreen key="docs" />} />
          <Route path="courses" element={<SupplierCoursesScreen />} />
          <Route path="tickets" element={<SupportScreen layout="supplier" />} />
          <Route
            path="communications"
            element={<SupplierDocumentsScreen key="communications" />}
          />
          <Route path="settings">
            <Route index element={<Navigate replace to="general" />} />
            <Route path="general" element={<SupplierSettingsMainScreen />} />
            <Route path="about" element={<SupplierSettingsAboutScreen />} />
            <Route path="address" element={<SupplierSettingsAddressScreen />} />
            <Route path="contact" element={<SupplierSettingsContactScreen />} />
            <Route path="logo" element={<SupplierSettingsLogoScreen />} />
            <Route
              path="password"
              element={<SupplierSettingsPasswordScreen />}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundScreen />} />
      </Route>
      <Route path="/auth" element={<UnauthorizedContainer />}>
        <Route index element={<Navigate replace to="signup" />} />
        <Route path="social/google" element={<AuthGoogleScreen />} />
        <Route path="signup" element={<SignupScreen />} />
        <Route path="signin" element={<SigninScreen />} />
        <Route path="forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="reset" element={<ResetPasswordScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Route>
      <Route path="/partner">
        <Route index element={<SelectPartnerTypeScreen />} />
        <Route path="auth" element={<UnauthorizedContainer />}>
          <Route path=":type" element={<SignupPartnerScreen />} />
        </Route>
        <Route path="*" element={<NotFoundScreen />} />
      </Route>
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};
