import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthLayout } from '../../layouts';
import { useResetPassword } from '../../hooks';
import { Button, FormTextInput } from '../../../ui';
import { Content, ContentTop, Title } from './styled';

const schema = yup
  .object({
    password: yup
      .string()
      .min(8, 'Min 8 symbols')
      .matches(
        /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#${}~`\][()|><^/\\.,-_+=:;'"!%*?&])/,
        'At least one uppercase letter, one lowercase letter, one number and one special character',
      )
      .notOneOf([yup.ref('email')], 'Should not look like an email')
      .required('Required'),
    confirm_password: yup.string().required('Required').oneOf([yup.ref('password')], 'Passwords must match'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const ResetPasswordScreen: FC = () => {
  const [params] = useSearchParams();
  const token = params.get('token');
  const uid = params.get('uid');
  const navigate = useNavigate();
  const {
    result,
    isLoading,
    request,
  } = useResetPassword();

  const {
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback((data: FormData) => {
    if (!token || !uid) {
      return;
    }
    request({
      token,
      uid,
      ...data,
    });
  }, [token, uid, request]);

  useEffect(() => {
    if (result) {
      toast('Password successfully changed', { type: 'success' });
      navigate('/auth/signin');
    }
  }, [result]);

  if (!token || !uid) {
    return (
      <Navigate to="/" replace />
    );
  }

  return (
    <AuthLayout>
      <Content as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <ContentTop>
          <Title>
            Reset password
          </Title>
          <FormTextInput
            name="password"
            label="Password"
            type="password"
            fullWidth
            control={control}
            // hint={(
            //   <div>
            //     <div>- min 8 symbols</div>
            //     <div>- one uppercase letter</div>
            //     <div>- one lowercase letter</div>
            //     <div>- one number</div>
            //     <div>- one special character</div>
            //     <div>- should not look like an email</div>
            //   </div>
            // )}
          />
          <FormTextInput
            name="confirm_password"
            label="Confirm password"
            type="password"
            fullWidth
            control={control}
          />
        </ContentTop>
        <Button size="lg" type="submit" disabled={isLoading}>Submit</Button>
      </Content>
    </AuthLayout>
  );
};
