import styled, { css } from 'styled-components';

interface ItemProps {
  selected?: boolean;
  disabled?: boolean;
}

export const Item = styled.div<ItemProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  border: 1px solid #AAA;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  
  text-align: center;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 12px;
  font-weight: 600;

  background-color: #000;
  color:#AAA;
  
  ${(p) => p.selected && css`
    border-color: #03FBFD;
    color:#03FBFD;
    
  `}

  ${(p) => p.disabled && css`
    opacity: 0.6;
    pointer-events: none;
  `}

  svg {
    font-size: 20px;
  }
`;
