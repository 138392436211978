/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import { FC } from 'react';
import { Container, InfoPageLayout } from '../../../shared';
import { InfoPageContent } from '../../components';

export const CopyRightScreen: FC = () => {
  return (
    <InfoPageLayout bg="noise" bodyBg="fillGradient">
      <Container>
        <InfoPageContent>
          <h1 id="copyright">Copyright</h1>
          <h2 id="-domain-name-sale-agreement-"><strong>Domain name sale agreement</strong></h2>
          <p>
            THIS AGREEMENT is made on the
            {' '}
            <em>[insert day]</em>
            {' '}
            day of
            {' '}
            <em>[insert month] [insert year]</em>
            {' '}
            (&quot;Effective Date&quot;)
          </p>
          <p>BETWEEN:</p>
          <p>
            <em>[Insert the name and address of the organisation selling the domain name],</em>
            {' '}
            (&quot;the Seller)
          </p>
          <p>AND</p>
          <p>
            <em>[Insert the name and address of the organisation purchasing the domain name],</em>
            {' '}
            (&quot;the Buyer&quot;)
          </p>
          <p>WHEREAS:</p>
          <p>
            The Seller is the current owner of the Internet Domain Name Address,
            {' '}
            <em>[Insert the domain name]</em>
            , &quot;the Domain Name&quot;, and now wishes to sell and transfer the said Domain Name.
          </p>
          <p>The parties therefore agree as follows:</p>
          <p>
            <strong>1.</strong>
            {' '}
            <strong>Domain Name Sale and Transfer Fee</strong>
          </p>
          <p>
            In consideration of the one-time payment of
            {' '}
            <em>[Insert agreed fee in both numbers and words, e.g., £10,000, Ten Thousand Pounds],</em>
            {' '}
            &quot;Sale and Transfer Fee&quot; by Buyer, the Seller agrees to transfer ownership of and all rights, title and interest in the Domain Name to the Buyer.
          </p>
          <p>
            <strong>2.</strong>
            {' '}
            <strong>Domain Name Transfer</strong>
          </p>
          <p>The Seller also agrees to the following:</p>
          <p>
            <strong>2.1</strong>
            {' '}
            To comply without delay to any reasonable request made by the Buyer once the Sale and Transfer Fee has been paid to transfer the Domain Name to any domain name system server, (&quot;DNS Server&quot;) nominated by the Buyer.
          </p>
          <p>
            <strong>2.2</strong>
            {' '}
            To complete all forms, meet all requirements and to offer all reasonable assistance and co-operation to enable the Domain Name to be transferred in accordance with the terms of this Agreement.
          </p>
          <p>
            <strong>3.</strong>
            {' '}
            <strong>Restrictions on Seller</strong>
          </p>
          <p>In recognition that the actions listed below in this clause 3 would impact upon the value and goodwill associated with the Domain Name, the Seller further agrees not to do the following without the express prior written approval of the Buyer:</p>
          <p>
            <strong>3.1</strong>
            {' '}
            Register, resell or rent any similar sounding or similarly spelt Internet Domain Name Address or Addresses: or
          </p>
          <p>
            <strong>3.2</strong>
            {' '}
            Use the Domain Name in keywords contained within the Meta Tags of any web page or referred to or repeated in the coding of any web page; or
          </p>
          <p>
            <strong>3.3</strong>
            {' '}
            Register the Domain Name or any similar sounding or similarly spelt Web Domain Address or any combination of the Domain Name as keywords with any search engine or directory; or
          </p>
          <p>
            <strong>3.4</strong>
            {' '}
            Attempt to register the Domain Name as a trademark, service mark or trade name, or assign any intellectual property rights associated with the Domain Name in this jurisdiction or any other to any individual or company other than the Buyer: or
          </p>
          <p>
            <strong>3.5</strong>
            {' '}
            Refer to the Domain Name in any promotional material or on any site owned by or in which The Seller has an interest.
          </p>
          <p>
            <strong>4.</strong>
            {' '}
            <strong>Confidentiality</strong>
          </p>
          <p>Both parties shall keep confidential the specific terms of this Agreement and not disclose them save to such employees or contractors as need to know the relevant information for the purposes of performing this Agreement. The parties agree that all information marked &quot;Confidential&quot;, or where not marked it is reasonable to judge such information as confidential, shall not be disclosed for a period of 2 years after the expiry of this Agreement, except where such disclosure is required by law or by order of a court in the jurisdiction of England. The parties further agree that all information marked as a &quot;Trade Secret&quot; and reasonably judged to constitute a trade secret shall not be disclosed at any time during or after the expiry of this Agreement, except where such disclosure is required by law or by order of a court in the jurisdiction of England. Confidential information and Trade Secrets shall consist of, but not necessarily be limited to technical, commercial, financial, operational, marketing or promotional information or data.</p>
          <p><strong>5. Force Majeure</strong></p>
          <p>Neither party shall be liable for delay or failure to perform any obligation under this Agreement if the delay or failure is caused by any circumstances beyond their reasonable control, including but not limited to Acts of God, war, civil disorder or industrial disputes.  If such delay or failure continues for a period of at least Sixty (60) days, the party not subject to the force majeure shall be entitled to terminate this Agreement by written notice to the other.</p>
          <p><strong>6. General</strong></p>
          <p>
            <strong>6.1</strong>
            {' '}
            Failure by either party to enforce any accrued rights under this Agreement is not to be taken as or deemed to be a waiver of those rights unless the waiving party acknowledges the waiver in writing.
          </p>
          <p>
            <strong>6.2</strong>
            {' '}
            The paragraphs, sub-paragraphs and clauses of this Agreement shall be read and construed independently of each other. Should any part of this Agreement or paragraphs, sub-paragraphs or clauses be found invalid it shall not affect the remaining paragraphs, sub-paragraphs and clauses.
          </p>
          <p>
            <strong>6.3</strong>
            {' '}
            No addition to or modification of any clause in this Agreement shall be binding on the parties unless made in writing and signed by the signatories to this Agreement or their duly authorised representatives.
          </p>
          <p>
            <strong>6.4</strong>
            {' '}
            This Agreement sets out the entire Agreement and understanding of the parties and is in substitution of any previous written or oral agreements between the parties.
          </p>
          <p>
            <strong>7.</strong>
            {' '}
            <strong>Jurisdiction</strong>
          </p>
          <p>This Agreement shall be governed by and construed and interpreted in accordance with the law of England and Wales and the parties hereby submit to the jurisdiction of the English courts.</p>
          <p>IN WITNESS of which the parties have signed this Agreement the day, month and year first above written.</p>
          <p><em>[Insert Seller&#39;s Name]</em></p>
          <p><em>[Insert name of person signing on behalf of the Seller]</em></p>
          <p><em>[Insert their position]</em></p>
          <p>Signature:</p>
          <p><em>[Insert Buyer&#39;s Name]</em></p>
          <p><em>[Insert name of person signing on behalf of the Buyer]</em></p>
          <p><em>[Insert their position]</em></p>
          <p>Signature:</p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 14.0</p>
          <h2 id="-domain-name-rental-agreement-"><strong>Domain name rental agreement</strong></h2>
          <p>
            THIS AGREEMENT is made on the
            {' '}
            <em>[insert day]</em>
            {' '}
            day of
            {' '}
            <em>[insert month]</em>
            {' '}
            <em>[insert year]</em>
            {' '}
            (&quot;Effective Date&quot;)
          </p>
          <p>BETWEEN:</p>
          <p>
            <em>[Insert the name and address of the organisation renting out the domain name]</em>
            , (&quot;the Owner)
          </p>
          <p>AND</p>
          <p>
            <em>[Insert the name and address of the organisation renting the domain name]</em>
            , (&quot;the Renter&quot;)
          </p>
          <p>WHEREAS:</p>
          <p>
            The owner of the Internet Domain Name Address,
            {' '}
            <em>[Insert the domain name]</em>
            , &quot;the Domain Name&quot;, now wishes to allow the Renter to rent the said Domain Name.
          </p>
          <p>The parties therefore agree as follows:</p>
          <p><strong>1. Rental Term and Fees</strong></p>
          <p>
            In consideration of a fee of
            {' '}
            <em>[Insert agreed fee in both numbers and words, e.g. £10,000, Ten Thousand Pounds]</em>
            , &quot;Rental Fee&quot; payable
            {' '}
            <em>[monthly/ yearly]</em>
            {' '}
            by the Renter, the Owner agrees to allow the Renter to rent the Domain Name, subject to a minimum rental period of
            {' '}
            <em>[Insert minimum rental period, e.g. 6 months]</em>
            {' '}
            and shall continue thereafter unless or until terminated by either party giving not less than
            {' '}
            <em>[Insert notice period for termination, e.g. 1-6 months]</em>
            {' '}
            notice in writing, such notice not to expire before the end of the initial rental period. Notwithstanding this the parties further agree that this Agreement may be terminated before the expiry of the initial rental period in accordance with clause 7 of this Agreement.
          </p>
          <p>
            <strong>2.</strong>
            {' '}
            <strong>Payment Terms</strong>
          </p>
          <p>Payment of the Rental Fee shall be in accordance with the standard payment terms of the Owner, which may be altered by the Owner at any time on giving the Renter reasonable notice.</p>
          <p>
            <strong>3.</strong>
            {' '}
            <strong>Ownership</strong>
          </p>
          <p>At no time during the term of this Agreement shall any rights of title or ownership in the Domain Name pass from the Owner to the Renter. The domain name registration (WHOIS) details shall remain those of the Owner at all times and the Renter shall not seek or attempt to interfere with or change these registration details. Nor shall the Renter attempt at any time to register the Domain Name as a trademark, service mark or trade name in this jurisdiction or any other.</p>
          <p>
            <strong>4.</strong>
            {' '}
            <strong>Domain Name Transfer/Hosting</strong>
          </p>
          <p><em>[Please select one of the following two options -</em></p>
          <p>The Owner agrees to comply without delay to any reasonable request made by the Renter once the Rental Fee has been paid to transfer the Domain Name to any domain name system server, DNS Server nominated by the Renter for the duration of this Agreement. To complete all forms, meet all requirements and to offer such reasonable assistance to enable the Domain Name to be transferred in accordance with the terms of this Agreement.</p>
          <p><em>OR</em></p>
          <p>The parties agree that the Domain Name will be hosted by the Owner on behalf of the Renter for the duration of this Agreement, such reasonable hosting fees and other fees to be agreed between the parties to be included in the Rental Fee.</p>
          <p>
            <strong>5.</strong>
            {' '}
            <strong>Further Domain Name Use</strong>
          </p>
          <p>The Owner further agrees to allow the Renter for the duration of this Agreement to do the following:</p>
          <p>
            <strong>5.1</strong>
            {' '}
            Use the Domain Name in keywords contained within the Meta Tags of any web page or referred to or repeated in the coding of any web page.
          </p>
          <p>
            <strong>5.2</strong>
            {' '}
            Register the Domain Name or any similar sounding or similarly spelt Web Domain Addresses or any combination of the Domain Name as keywords with any search engine or directory, provided such similar sounding Web Domain Addresses are not already owned by The Owner or a third-party.
          </p>
          <p>
            <strong>5.3</strong>
            {' '}
            Refer to the Domain Name in any promotional material or on any site owned by or in which the Renter has an interest.
          </p>
          <p>
            <strong>6.</strong>
            {' '}
            <strong>Rights on Termination</strong>
          </p>
          <p>On the termination of this Agreement all rights and interests in the Domain Name automatically and immediately revert to The Owner. The Renter further agrees to immediately cease all use of the Domain Name, including such usage as detailed above in Clause 5 of this Agreement.</p>
          <p><strong>7. Termination</strong></p>
          <p>
            <strong>7.1</strong>
            {' '}
            The Owner of the Domain Name may terminate this Agreement immediately in the event that:
          </p>
          <p>
            <strong>7.1.1</strong>
            {' '}
            The Renter commits a serious, grave or material breach or persistent breaches of this Agreement including non-performance, default or neglect of his duties, responsibilities and obligations under this Agreement, and
          </p>
          <p>
            <strong>7.1.2</strong>
            {' '}
            Such breach remains unremedied for a period of 30 days from written notice given by the other party specifying the breach and requiring it is remedied.
          </p>
          <p>
            <strong>7.2</strong>
            {' '}
            Furthermore this Agreement may be terminated in the event that:
          </p>
          <p>
            <strong>(a)</strong>
            {' '}
            The Renter is unable to pay or has no reasonable prospects of paying their debts the amount or aggregate amount of which equals or exceeds the bankruptcy level within the meaning of the Insolvency Act 1986, or
          </p>
          <p>
            <strong>(b)</strong>
            {' '}
            Being a company becomes subject to an administration order or goes into liquidation, (other than for the purpose of amalgamation or reconstruction), or
          </p>
          <p>
            <strong>(c)</strong>
            {' '}
            Has a receiver appointed to administer any of their property or assets, or
          </p>
          <p>
            <strong>(d)</strong>
            {' '}
            Ceases or threatens to cease to carry on business, or
          </p>
          <p>
            <strong>(e)</strong>
            {' '}
            Makes any voluntary agreement or enters into a compromise for the benefit of their creditors, or
          </p>
          <p>
            <strong>(f)</strong>
            {' '}
            There is a change of control (and for these purposes &quot;control&quot; means the right to direct the affairs of the company either by ownership of shares, membership of the board, or otherwise).
          </p>
          <p>
            <strong>7.3</strong>
            {' '}
            Any termination of this Agreement shall be without prejudice to any rights accrued in favour of either party in respect of any breach committed prior to the date of (or giving rise to) such termination and to those provisions of this Agreement which are by their construction intended to survive such termination (including, without limitation, clauses 1, 3, 10 and this clause 7).
          </p>
          <p>
            <strong>7.4</strong>
            {' '}
            Neither party shall be liable for or be in breach of this Agreement by reason of any delay in performance or failure to perform this Agreement (except with respect to payment obligations) which results from matters which are beyond either party&#39;s reasonable control and which such party is unable to overcome by the exercise of reasonable diligence.
          </p>
          <p><strong>8. Option to Purchase</strong></p>
          <p>
            <strong>8.1</strong>
            {' '}
            The Renter may request to purchase the Domain Name and all rights to the Domain Name at any time, strictly subject to the agreement of the Owner to sell the Domain Name to the Renter.
          </p>
          <p>
            <strong>8.2</strong>
            {' '}
            All outstanding Rental Fees shall remain due in addition to the purchase price negotiated for the Domain Name by the parties.
          </p>
          <p>
            <strong>8.3</strong>
            {' '}
            The Owner of the Domain Name reserves the right to withdraw from any sale of the Domain Name at any time, without the need to provide a reason or any compensation to the Renter. Where the Owner withdraws from any sale the rental of the Domain Name and all Rental Fees and obligations under this Agreement continue.
          </p>
          <p><strong>9. Notice</strong></p>
          <p>
            <strong>9.1</strong>
            {' '}
            Any notice given by either of the parties under this Agreement shall be served on the other party and addressed to that party&#39;s signatory by email, personal delivery, pre-paid recorded delivery, first-class post, or fax transmission to the receiving party as set out in this clause 9:
          </p>
          <p><em>[Insert contact details for the Domain Name Owner who will receive any notice]</em></p>
          <p>The Domain Name Owner</p>
          <p>Name:</p>
          <p>Position:</p>
          <p>Email:</p>
          <p>Tel:</p>
          <p>Fax:</p>
          <p>Address:</p>
          <p><em>[Insert contact details of the Domain Name Renter who will receive any notice]</em></p>
          <p>The Domain Name Renter</p>
          <p>Name:</p>
          <p>Position:</p>
          <p>Email:</p>
          <p>Tel:</p>
          <p>Fax:</p>
          <p>Address:</p>
          <p>or such subsequent addresses as may be notified by the parties to each other.</p>
          <p>
            <strong>9.2</strong>
            {' '}
            Any such notice shall be deemed to be effectively served as follows:
          </p>
          <p>
            <strong>9.2.1</strong>
            {' '}
            In the case of service by pre-paid recorded delivery or first-class post 48 hours after posting.
          </p>
          <p>
            <strong>9.2.2</strong>
            {' '}
            In the case of service by email or facsimile transmission on the next working day.
          </p>
          <p><strong>10. Confidentiality</strong></p>
          <p>Both parties shall keep confidential the specific terms of this Agreement and not disclose them save to such employees or contractors as need to know the relevant information for the purposes of performing this Agreement. The parties agree that all information marked &quot;Confidential&quot;, or where not marked it is reasonable to judge such information as confidential, shall not be disclosed for a period of 2 years after the expiry of this Agreement, except where such disclosure is required by law or by order of a court in the jurisdiction of England. The parties further agree that all information marked as a &quot;Trade Secret&quot; and reasonably judged to constitute a trade secret shall not be disclosed at any time during or after the expiry of this Agreement, except where such disclosure is required by law or by order of a court in the jurisdiction of England. Confidential information and Trade Secrets shall consist of, but not necessarily be limited to technical, commercial, financial, operational, marketing or promotional information or data.</p>
          <p><strong>11. Assignment</strong></p>
          <p>Neither party may assign or otherwise transfer this Agreement or any rights, duties and obligations hereunder without the prior consent in writing of the other party.</p>
          <p><strong>12. Force Majeure</strong></p>
          <p>Neither party shall be liable for delay or failure to perform any obligation under this Agreement if the delay or failure is caused by any circumstances beyond their reasonable control, including but not limited to Acts of God, war, civil disorder or industrial disputes.  If such delay or failure continues for a period of at least Sixty (60) days, the party not subject to the force majeure shall be entitled to terminate this Agreement by written notice to the other.</p>
          <p><strong>13. Joint Venture or Partnership</strong></p>
          <p>Nothing in this Agreement shall be construed as creating a partnership, joint venture or an agency relationship between the parties and neither party shall have the authority or power to bind the other party or to contract in the name of or create a liability against the other party.</p>
          <p><strong>14. General</strong></p>
          <p>
            <strong>14.1</strong>
            {' '}
            Failure by either party to enforce any accrued rights under this Agreement is not to be taken as or deemed to be a waiver of those rights unless the waiving party acknowledges the waiver in writing.
          </p>
          <p>
            <strong>14.2</strong>
            {' '}
            The paragraphs, sub-paragraphs and clauses of this Agreement shall be read and construed independently of each other. Should any part of this Agreement or paragraphs, sub-paragraphs or clauses be found invalid it shall not affect the remaining paragraphs, sub-paragraphs and clauses.
          </p>
          <p>
            <strong>14.3</strong>
            {' '}
            No addition to or modification of any clause in this Agreement shall be binding on the parties unless made in writing and signed by the signatories to this Agreement or their duly authorised representatives.
          </p>
          <p>
            <strong>14.4</strong>
            {' '}
            This Agreement sets out the entire Agreement and understanding of the parties and is in substitution of any previous written or oral agreements between the parties.
          </p>
          <p>
            <strong>15.</strong>
            {' '}
            <strong>Jurisdiction</strong>
          </p>
          <p>This Agreement shall be governed by and construed and interpreted in accordance with the law of England and Wales and the parties hereby submit to the jurisdiction of the English courts.</p>
          <p>IN WITNESS of which the parties have signed this Agreement the day, month and year first above written.</p>
          <p><em>[Insert Owner&#39;s Name]</em></p>
          <p><em>[Insert name of person signing on behalf of the Owner]</em></p>
          <p><em>[Insert their position]</em></p>
          <p>Signature:</p>
          <p><em>[Insert Renter&#39;s Name]</em></p>
          <p><em>[Insert name of person signing on behalf of the Renter]</em></p>
          <p><em>[Insert their position]</em></p>
          <p>Signature:</p>
          <p>(c) www.compactlaw.co.uk / all rights reserved / version 16.0</p>

        </InfoPageContent>
      </Container>
    </InfoPageLayout>
  );
};
