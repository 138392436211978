import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StudyContext, StudyContextValue } from './StudyContext';
import { useRequestState } from '../../../common';
import { loadUserCourses } from '../../network';
import { UserCourse } from '../../types';

export interface StudyProviderProps {
  courseId: string | number;
  children: ReactNode;
}

export const StudyProvider: FC<StudyProviderProps> = (props) => {
  const { courseId, children } = props;

  const {
    result: userCourse,
    error,
    isLoading,
    setResult: setUserCourse,
    setError,
    setIsLoading,
  } = useRequestState<UserCourse>();

  const [stepsOpen, setStepsOpen] = useState<number | null | undefined>(null);

  const handleOpen = useCallback(
    (id: number) => {
      if (id && stepsOpen === id) {
        setStepsOpen(null);
      } else {
        setStepsOpen(id);
      }
    },
    [stepsOpen],
  );

  const loadUserCourse = useCallback(async () => {
    if (!courseId) {
      setError(new Error('Course ID is not provided'));
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await loadUserCourses({ paging: { pageSize: 100 } });
      const result = response.data.find((x) => x.course.id === Number(courseId)) || null;
      if (!result) {
        setUserCourse(null);
        setError(new Error('Course not found'));
      } else {
        setUserCourse(result);
      }
    } catch (e) {
      setUserCourse(null);
      setError(e);
    }
    setIsLoading(false);
  }, [courseId]);

  const handleOpenActiveSection = useCallback((id?: number) => {
    if (id) {
      setStepsOpen(id);
    }
  }, []);

  useEffect(() => {
    loadUserCourse();
  }, [loadUserCourse]);

  const contextValue: StudyContextValue = useMemo(() => {
    return {
      stepsOpen,
      handleOpenActiveSection,
      handleOpen,
      userCourse,
      isLoading,
      error,
      reload: loadUserCourse,
    };
  }, [userCourse, isLoading, error, loadUserCourse, stepsOpen, handleOpen]);

  return (
    <StudyContext.Provider value={contextValue}>
      {children}
    </StudyContext.Provider>
  );
};
