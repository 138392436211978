import { FC, useMemo } from 'react';
import { Skill } from '../../types';
import {
  Name, ProgressBar, ProgressText, Root,
} from './styled';

export interface SkillProgressBarProps {
  item: Skill;
}

export const SkillProgressBar: FC<SkillProgressBarProps> = (props) => {
  const { item } = props;

  const [progress, spentHours] = useMemo(() => {
    if (item.time_spent && item.total_time) {
      const spent = parseFloat(item.time_spent);
      if (spent > 0) {
        const total = parseFloat(item.total_time) || spent;

        return [
          Math.round((spent / total) * 100) / 100,
          Math.round((spent / 60 / 60) * 100) / 100,
        ];
      }
    }
    return [0, 0];
  }, [item]);

  return (
    <Root>
      <ProgressBar
        color={item.css_config?.gradient || item.css_config?.color || '#ccc'}
        progress={progress}
      />
      <Name>{item.name}</Name>
      <ProgressText>{`${spentHours}hrs (${progress * 100}%)`}</ProgressText>
    </Root>
  );
};
