import {
  apiClient,
} from '../../api';
import { Course } from '../types';

export const loadCoursePreview = async (
  id: number,
): Promise<Course> => {
  const response = await apiClient.request({
    path: `/api/v1/course/${id}/preview/`,
    method: 'GET',
  });

  return response.data;
};
