import styled from 'styled-components';
import { FC, ReactNode } from 'react';
import cardBgMobile from '../../assets/images/form-card-mobile.png';
import cardBgDesktop from '../../assets/images/form-card-desk.png';

const Content = styled.div`
  position: absolute;
  inset: 0;
`;

const Title = styled.div`
  display: none;
  color: #03FBFD;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-transform: uppercase;
`;

const Root = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 390px;
  aspect-ratio: ${383 / 569};
  /* padding: 60px 58px 86px 48px; */
  background-image: url(${cardBgMobile});
  background-size: cover;

  ${Content} {
    inset: 10% 15% 15% 12.5%;
  }

  ${(p) => p.theme.breakpoints.down('mobile')} {
    background: none;
    aspect-ratio: ${559 / 995};

    ${Content} {
      inset:0;
    }
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 980px;
    aspect-ratio: ${659 / 445};
    /* padding: 52px 72px 60px 80px; */
    background-image: url(${cardBgDesktop});

    ${Content} {
      inset: 11.5% 11% 12% 11%;
    }

    ${Title} {
      display: block;
      position: absolute;
      left: 4.4%;
      top: 0;
    }
  }
`;

export interface ShapeCardProps {
  title?: ReactNode;
  children: ReactNode;
}

export const ShapeCard: FC<ShapeCardProps> = (props) => {
  const { title, children } = props;
  return (
    <Root>
      {title && (
        <Title>{title}</Title>
      )}
      <Content>
        {children}
      </Content>
    </Root>
  );
};
