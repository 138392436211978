import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 50px;
`;

export const ComingSoon = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.typography.base};
  font-size: 45px;
  text-transform: uppercase;
  color: #fff;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #03FBFD;
`;

export const ItemName = styled.div`
  padding: 30px;
  flex-grow: 1;
  color: #FFF;
  font-family: ${(p) => p.theme.typography.fontFamilySecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-transform: uppercase;
`;

export const ItemButton = styled.div`
  padding: 23px 30px;
  border-left: 1px solid #03FBFD;
`;
